import { Box, Center, Text } from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import useInterviewerParticipant from '../hooks/useInterviewerParticipant';
import useParticipantCameraTrack from '../hooks/useParticipantCameraTrack';
import useParticipantMicrophoneTrack from '../hooks/useParticipantMicrophoneTrack';
import useParticipants from '../hooks/useParticipants';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Audio from './Audio';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';
import LocalVideoPlaceholder from './LocalVideoPlaceholder';
import { useRoom } from './RoomProvider';
import Video from './Video';

const InterviewerParticipantMediaMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: interviewerSnap } = useFirestoreDoc(interview.interviewerRef);

  if (!interviewerSnap.exists()) {
    throw new SnapNotFoundError(interviewerSnap);
  }

  const interviewer = useMemo(() => interviewerSnap.data(), [interviewerSnap]);

  const { room } = useRoom();

  const participants = useParticipants(room);

  const interviewerParticipant = useInterviewerParticipant(participants);

  const {
    track: interviewerCameraTrack,
    enabled: interviewerCameraTrackEnabled,
  } = useParticipantCameraTrack(interviewerParticipant);

  const {
    track: interviewerMicrophoneTrack,
  } = useParticipantMicrophoneTrack(interviewerParticipant);

  return (
    <Box
      h={180}
      w={320}
      borderRadius="md"
      overflow="hidden"
      bg="cf.black"
    >
      {interviewerCameraTrack && interviewerCameraTrackEnabled ? (
        <Box h="100%" w="100%">
          <Video videoTrack={interviewerCameraTrack} />
        </Box>
      ) : (
        <Center h="100%" w="100%">
          <LocalVideoPlaceholder userRef={interview.interviewerRef} />
        </Center>
      )}

      <Box
        position="relative"
        left={3}
        bottom={8}
        display="inline-block"
        bg="rgba(2, 23, 41, 0.6)"
        borderRadius="sm"
        px={1}
        py="2px"
      >
        <Text fontSize="xs" lineHeight="shorter" color="cf.cntOnColor" userSelect="none">
          {interviewer.firstName}
        </Text>
      </Box>

      {interviewerMicrophoneTrack ? (
        <Audio audioTrack={interviewerMicrophoneTrack} />
      ) : null}
    </Box>
  );
};

const InterviewerParticipantMediaCatchFallback: React.FC = () => null;
const InterviewerParticipantMediaSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const InterviewerParticipantMedia: React.FC = () => (
  <Catch fallback={<InterviewerParticipantMediaCatchFallback />}>
    <Suspense fallback={<InterviewerParticipantMediaSuspenseFallback />}>
      <InterviewerParticipantMediaMain />
    </Suspense>
  </Catch>
);

export default InterviewerParticipantMedia;
