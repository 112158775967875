import { Box } from '@chakra-ui/react';
import { Excalidraw, MainMenu } from '@excalidraw/excalidraw';
import { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  Collaborator,
  ExcalidrawAPIRefValue,
} from '@excalidraw/excalidraw/types/types';
import { doc, getDoc, runTransaction } from 'firebase/firestore';
import { ref } from 'firebase/storage';
import _ from 'lodash';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useFirestore,
  useFirestoreCollection,
  useFirestoreDoc,
  useStorage,
} from 'reactfire';
import { RemoteDataTrack, RemoteParticipant } from 'twilio-video';
import useIntervieweeParticipant from '../hooks/useIntervieweeParticipant';
import useInterviewerParticipant from '../hooks/useInterviewerParticipant';
import useInterviewRole, { InterviewRole } from '../hooks/useInterviewRole';
import useParticipantDataTrack from '../hooks/useParticipantDataTrack';
import useParticipants from '../hooks/useParticipants';
import CheckIcon from '../icons/CheckIcon';
import Spinner from '../icons/Spinner';
import UploadIcon from '../icons/UploadIcon';
import { getInterviewWhiteboardElementsCollectionRef } from '../types/InterviewWhiteboardElement';
import { getInterviewWhiteboardFilesCollectionRef } from '../types/InterviewWhiteboardFile';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';
import { useLocalData } from './LocalDataProvider';
import { useRoom } from './RoomProvider';

enum MessageType {
  WHITEBOARD_ELEMENT_UPDATE = 'WHITEBOARD_ELEMENT_UPDATE',
  WHITEBOARD_FILE_UPDATE = 'WHITEBOARD_FILE_UPDATE',
  WHITEBOARD_POINTER_UPDATE = 'WHITEBOARD_POINTER_UPDATE',
  WHITEBOARD_SELECTION_UPDATE = 'WHITEBOARD_SELECTION_UPDATE',
}

type WhiteboardElementUpdateMessage = {
  type: MessageType.WHITEBOARD_ELEMENT_UPDATE;
  element: ExcalidrawElement;
};

type WhiteboardFileUpdateMessage = {
  type: MessageType.WHITEBOARD_FILE_UPDATE;
  file: BinaryFileData;
};

type WhiteboardPointerUpdateMessage = {
  type: MessageType.WHITEBOARD_POINTER_UPDATE;
  pointer: {
    x: number;
    y: number;
  };
  button: 'down' | 'up';
};

type WhiteboardSelectionUpdateMessage = {
  type: MessageType.WHITEBOARD_SELECTION_UPDATE;
  selectedElementIds: {
    [id: string]: boolean;
  }
};

type DataMessage =
  | WhiteboardPointerUpdateMessage
  | WhiteboardElementUpdateMessage
  | WhiteboardFileUpdateMessage
  | WhiteboardSelectionUpdateMessage;

const useIntervieweeCollaborator = (): Collaborator => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: intervieweeSnap } = useFirestoreDoc(interview.intervieweeRef);

  if (!intervieweeSnap.exists()) {
    throw new SnapNotFoundError(intervieweeSnap);
  }

  const interviewee = useMemo(() => intervieweeSnap.data(), [intervieweeSnap]);

  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();

  const storage = useStorage();

  useEffect(
    () => {
      if (interviewee.avatarRef) {
        getDoc(interviewee.avatarRef)
          .then((avatarSnap) => {
            if (avatarSnap.exists()) {
              const avatar = avatarSnap.data();

              if (avatar['2xl']?.['3x']) {
                const reference = ref(storage, avatar['2xl']['3x']);
                setAvatarUrl(`https://storage.googleapis.com/${reference.bucket}/${reference.fullPath}`);
              }
            }
          });
      }
    },
    [interviewee.avatarRef, storage],
  );

  return {
    username: interviewee.firstName,
    avatarUrl,
  };
};

const useInterviewerCollaborator = (): Collaborator => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: interviewerSnap } = useFirestoreDoc(interview.interviewerRef);

  if (!interviewerSnap.exists()) {
    throw new SnapNotFoundError(interviewerSnap);
  }

  const interviewer = useMemo(() => interviewerSnap.data(), [interviewerSnap]);

  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();

  const storage = useStorage();

  useEffect(
    () => {
      if (interviewer.avatarRef) {
        getDoc(interviewer.avatarRef)
          .then((avatarSnap) => {
            if (avatarSnap.exists()) {
              const avatar = avatarSnap.data();

              if (avatar['2xl']?.['3x']) {
                const reference = ref(storage, avatar['2xl']['3x']);
                setAvatarUrl(`https://storage.googleapis.com/${reference.bucket}/${reference.fullPath}`);
              }
            }
          });
      }
    },
    [interviewer.avatarRef, storage],
  );

  return {
    username: interviewer.firstName,
    avatarUrl,
  };
};

const CallWhiteboardMain: React.FC = () => {
  const { track: localWhiteboardTrack } = useLocalData();

  const { room } = useRoom();
  const participants = useParticipants(room);
  const intervieweeParticipant = useIntervieweeParticipant(participants);
  const interviewerParticipant = useInterviewerParticipant(participants);
  const {
    track: intervieweeParticipantDataTrack,
  } = useParticipantDataTrack(intervieweeParticipant);
  const {
    track: interviewerParticipantDataTrack,
  } = useParticipantDataTrack(interviewerParticipant);

  const [excalidraw, setExcalidraw] = useState<ExcalidrawAPIRefValue | null>(null);
  const onRefChange = useCallback((newExcalidraw: ExcalidrawAPIRefValue) => {
    setExcalidraw(newExcalidraw);
  }, []);

  const [filesSent, setFilesSent] = useState<string[]>([]);
  const [selectionSent, setSelectionSent] = useState<Record<string, boolean>>({});
  const [elementsSent, setElementsSent] = useState<Record<string, number>>({});
  const [changed, setChanged] = useState(false);
  const [change, setChange] = useState('');

  const handleChange = useCallback(
    (
      elements: readonly ExcalidrawElement[],
      appState: AppState,
      files: BinaryFiles,
    ) => {
      elements.forEach((element) => {
        if (!_.has(elementsSent, element.id) || elementsSent[element.id] < element.updated) {
          setElementsSent({ ...elementsSent, [element.id]: element.updated });
          setChanged(true);
          setChange(`element:${element.id}:${element.updated}`);
          localWhiteboardTrack?.send(JSON.stringify({
            type: MessageType.WHITEBOARD_ELEMENT_UPDATE,
            element,
          } as DataMessage));
        }
      });

      _.values(files).forEach((file) => {
        if (!_.includes(filesSent, file.id)) {
          setFilesSent([...filesSent, file.id]);
          setChanged(true);
          setChange(`file:${file.id}`);
          localWhiteboardTrack?.send(JSON.stringify({
            type: MessageType.WHITEBOARD_FILE_UPDATE,
            file,
          } as DataMessage));
        }
      });

      if (!_.isEqual(appState.selectedElementIds, selectionSent)) {
        setSelectionSent(appState.selectedElementIds);
        localWhiteboardTrack?.send(JSON.stringify({
          type: MessageType.WHITEBOARD_SELECTION_UPDATE,
          selectedElementIds: appState.selectedElementIds,
        } as DataMessage));
      }
    },
    [
      elementsSent,
      filesSent,
      localWhiteboardTrack,
      selectionSent,
    ],
  );

  const handlePointerUpdate = useCallback(
    ({ pointer, button }: {
      pointer: {
        x: number;
        y: number;
      };
      button: 'down' | 'up';
    }) => {
      localWhiteboardTrack?.send(JSON.stringify({
        type: MessageType.WHITEBOARD_POINTER_UPDATE,
        pointer,
        button,
      } as DataMessage));
    },
    [localWhiteboardTrack],
  );

  const handleElementIncomingUpdate = useCallback(
    ({ element: incomingElement }: WhiteboardElementUpdateMessage) => {
      if (excalidraw?.ready) {
        const canvasElements = excalidraw.getSceneElementsIncludingDeleted();

        const canvasExisting = _.find(
          canvasElements,
          (canvasElement) => canvasElement.id === incomingElement.id,
        );

        if (
          !canvasExisting || canvasExisting.updated < incomingElement.updated
        ) {
          // console.log('old', canvasExisting?.updated, incomingElement.updated);
          excalidraw.updateScene({
            elements: [
              ..._.filter(canvasElements, (element) => element.id !== incomingElement.id),
              incomingElement,
            ],
          });
        }
      }
    },
    [excalidraw],
  );

  const handleFileIncomingUpdate = useCallback(
    ({ file }: WhiteboardFileUpdateMessage) => {
      if (excalidraw?.ready) {
        excalidraw.addFiles([file]);
      }
    },
    [excalidraw],
  );

  const intervieweeCollaborator = useIntervieweeCollaborator();
  const interviewerCollaborator = useInterviewerCollaborator();

  const handlePointerIncomingUpdate = useCallback(
    ({ pointer, button }: WhiteboardPointerUpdateMessage, track: RemoteDataTrack) => {
      if (excalidraw?.ready) {
        const { collaborators } = excalidraw.getAppState();

        switch (track.name) {
          case 'interviewee-data': {
            const collaborator = collaborators.get('interviewee');

            const nextCollaborator: Collaborator = {
              ...(collaborator || {}),
              pointer,
              button,
            };

            if (!collaborator || !_.isEqual(collaborator, nextCollaborator)) {
              collaborators.set('interviewee', nextCollaborator);
              excalidraw.updateScene({ collaborators });
            }
            break;
          }
          case 'interviewer-data': {
            const collaborator = collaborators.get('interviewer');

            const nextCollaborator: Collaborator = {
              ...(collaborator || {}),
              pointer,
              button,
            };

            if (!collaborator || !_.isEqual(collaborator, nextCollaborator)) {
              collaborators.set('interviewer', nextCollaborator);
              excalidraw.updateScene({ collaborators });
            }
            break;
          }
        }
      }
    },
    [excalidraw],
  );

  const handleSelectionIncomingUpdate = useCallback(
    ({ selectedElementIds }: WhiteboardSelectionUpdateMessage, track: RemoteDataTrack) => {
      if (excalidraw?.ready) {
        const { collaborators } = excalidraw.getAppState();

        switch (track.name) {
          case 'interviewee-data': {
            const collaborator = collaborators.get('interviewee');

            const nextCollaborator: Collaborator = {
              ...(collaborator || {}),
              selectedElementIds,
            };

            if (!collaborator || !_.isEqual(collaborator, nextCollaborator)) {
              collaborators.set('interviewee', nextCollaborator);
              excalidraw.updateScene({ collaborators });
            }
            break;
          }
          case 'interviewer-data': {
            const collaborator = collaborators.get('interviewer');

            const nextCollaborator: Collaborator = {
              ...(collaborator || {}),
              selectedElementIds,
            };

            if (!collaborator || !_.isEqual(collaborator, nextCollaborator)) {
              collaborators.set('interviewer', nextCollaborator);
              excalidraw.updateScene({ collaborators });
            }
            break;
          }
        }
      }
    },
    [excalidraw],
  );

  useEffect(
    () => {
      if (intervieweeParticipantDataTrack) {
        if (excalidraw?.ready) {
          const { collaborators } = excalidraw.getAppState();

          const collaborator = collaborators.get('interviewee');

          const nextCollaborator: Collaborator = {
            ...(collaborator || {}),
            ...intervieweeCollaborator,
            color: {
              background: '#E0942D',
              stroke: '#E0942D',
            },
            // userState: UserIdleState.ACTIVE,
          };

          if (!collaborator || !_.isEqual(collaborator, nextCollaborator)) {
            collaborators.set('interviewee', nextCollaborator);
            excalidraw.updateScene({ collaborators });
          }
        }
      }
    },
    [excalidraw, intervieweeCollaborator, intervieweeParticipantDataTrack],
  );

  useEffect(
    () => {
      if (interviewerParticipantDataTrack) {
        if (excalidraw?.ready) {
          const { collaborators } = excalidraw.getAppState();

          const collaborator = collaborators.get('interviewer');

          const nextCollaborator: Collaborator = {
            ...(collaborator || {}),
            ...interviewerCollaborator,
            color: {
              background: '#8B3799',
              stroke: '#8B3799',
            },
            // userState: UserIdleState.ACTIVE,
          };

          if (!collaborator || !_.isEqual(collaborator, nextCollaborator)) {
            collaborators.set('interviewer', nextCollaborator);
            excalidraw.updateScene({ collaborators });
          }
        }
      }
    },
    [excalidraw, interviewerCollaborator, interviewerParticipantDataTrack],
  );

  const processMessage = useCallback(
    (e: DataMessage, track: RemoteDataTrack, participant: RemoteParticipant) => {
      switch (e.type) {
        case MessageType.WHITEBOARD_ELEMENT_UPDATE: {
          handleElementIncomingUpdate(e);
          break;
        }
        case MessageType.WHITEBOARD_FILE_UPDATE: {
          handleFileIncomingUpdate(e);
          break;
        }
        case MessageType.WHITEBOARD_POINTER_UPDATE: {
          handlePointerIncomingUpdate(e, track);
          break;
        }
        case MessageType.WHITEBOARD_SELECTION_UPDATE: {
          handleSelectionIncomingUpdate(e, track);
          break;
        }
      }
    },
    [
      handleElementIncomingUpdate,
      handleFileIncomingUpdate,
      handlePointerIncomingUpdate,
      handleSelectionIncomingUpdate,
    ],
  );

  useEffect(
    () => {
      if (intervieweeParticipant && intervieweeParticipantDataTrack) {
        const message = (v: string | ArrayBuffer) => {
          if (typeof v === 'string') {
            const e = JSON.parse(v) as DataMessage;

            processMessage(e, intervieweeParticipantDataTrack, intervieweeParticipant);
          }
        };

        // console.log('add interviewee track');
        intervieweeParticipantDataTrack.on('message', message);

        return () => {
          // console.log('remove interviewee track');
          intervieweeParticipantDataTrack.off('message', message);
        };
      }

      return () => { };
    },
    [
      intervieweeParticipant,
      intervieweeParticipantDataTrack,
      processMessage,
    ],
  );

  useEffect(
    () => {
      if (interviewerParticipant && interviewerParticipantDataTrack) {
        const message = (v: string | ArrayBuffer) => {
          if (typeof v === 'string') {
            const e = JSON.parse(v) as DataMessage;

            processMessage(e, interviewerParticipantDataTrack, interviewerParticipant);
          }
        };

        // console.log('add interviewer track');
        interviewerParticipantDataTrack.on('message', message);

        return () => {
          // console.log('remove interviewer track');
          interviewerParticipantDataTrack.off('message', message);
        };
      }

      return () => { };
    },
    [
      interviewerParticipant,
      interviewerParticipantDataTrack,
      processMessage,
    ],
  );

  const firestore = useFirestore();
  const interviewRef = useInterviewRef();
  const [saving, setSaving] = useState(false);
  useEffect(
    () => {
      const t = setTimeout(
        async () => {
          if (excalidraw?.ready) {
            const elements = excalidraw.getSceneElementsIncludingDeleted();
            const files = _.toPairs(excalidraw.getFiles());

            await Promise.all([
              ...elements.map(
                (element) => runTransaction(firestore, async (tr) => {
                  const snap = await tr.get(
                    doc(getInterviewWhiteboardElementsCollectionRef(interviewRef), element.id),
                  );

                  if (!snap.exists() || snap.data().updated < element.updated) {
                    setSaving(true);
                    tr.set(snap.ref, {
                      updated: element.updated,
                      data: JSON.stringify(element),
                    });
                  }
                }, { maxAttempts: 10 }),
              ),
              ...files.map(
                ([id, file]) => runTransaction(firestore, async (tr) => {
                  const snap = await tr.get(
                    doc(getInterviewWhiteboardFilesCollectionRef(interviewRef), id),
                  );

                  if (!snap.exists() || snap.data().id < file.id) {
                    setSaving(true);
                    tr.set(snap.ref, {
                      id: file.id,
                      data: JSON.stringify(file),
                    });
                  }
                }, { maxAttempts: 10 }),
              ),
            ]);

            setSaving(false);
            setChanged(false);
          }
        },
        1000,
      );

      return () => {
        clearTimeout(t);
      };
    },
    [change, excalidraw, firestore, interviewRef],
  );

  const { data: elementsSnap } = useFirestoreCollection(
    getInterviewWhiteboardElementsCollectionRef(interviewRef),
  );

  const initialElements = useMemo<ExcalidrawElement[]>(
    () => elementsSnap.docs.map((snap) => JSON.parse(snap.data().data)),
    [elementsSnap.docs],
  );

  const { data: filesSnap } = useFirestoreCollection(
    getInterviewWhiteboardFilesCollectionRef(interviewRef),
  );

  const initialFiles = useMemo<BinaryFiles>(
    () => filesSnap.docs.reduce(
      (res, snap) => ({ ...res, [snap.id]: JSON.parse(snap.data().data) }),
      {},
    ),
    [filesSnap.docs],
  );

  useEffect(
    () => {
      if (excalidraw?.ready) {
        const canvasElements = excalidraw.getSceneElementsIncludingDeleted();

        elementsSnap.docs.forEach((elementSnap) => {
          const canvasExisting = _.find(
            canvasElements,
            (canvasElement) => canvasElement.id === elementSnap.id,
          );

          if (
            !canvasExisting || canvasExisting.updated < elementSnap.data().updated
          ) {
            excalidraw.updateScene({
              elements: [
                ..._.filter(canvasElements, (element) => element.id !== elementSnap.id),
                JSON.parse(elementSnap.data().data),
              ],
            });
          }
        });
      }
    },
    [elementsSnap, excalidraw],
  );

  useEffect(
    () => {
      if (excalidraw?.ready) {
        const canvasFiles = excalidraw.getFiles();

        filesSnap.docs.forEach((fileSnap) => {
          const canvasExisting = _.find(
            canvasFiles,
            (canvasElement) => canvasElement.id === fileSnap.id,
          );

          if (
            !canvasExisting || canvasExisting.id !== fileSnap.data().id
          ) {
            excalidraw.addFiles([
              JSON.parse(fileSnap.data().data),
            ]);
          }
        });
      }
    },
    [filesSnap, excalidraw],
  );

  const role = useInterviewRole();

  return (
    <Box h="100%" w="100%" borderColor="cf.brdBlackAlpha12" borderWidth={1}>
      <Excalidraw
        onChange={handleChange}
        onPointerUpdate={handlePointerUpdate}
        ref={onRefChange}
        isCollaborating
        viewModeEnabled={role !== InterviewRole.INTERVIEWEE && role !== InterviewRole.INTERVIEWER}
        gridModeEnabled
        initialData={{
          elements: initialElements,
          files: initialFiles,
        }}
        renderTopRightUI={() => (
          <Box py={2} px={1} h={9}>
            {
              // eslint-disable-next-line no-nested-ternary
              saving
                ? (<Spinner h={5} w={5} display="block" color="cf.cntPrimary" />)
                : (
                  changed
                    ? (<UploadIcon h={5} w={5} display="block" color="cf.cntPrimary" />)
                    : (<CheckIcon h={5} w={5} display="block" color="cf.cntPrimary" />)
                )
            }
          </Box>
        )}
      >
        <MainMenu>
          <MainMenu.DefaultItems.ClearCanvas />
          <MainMenu.DefaultItems.SaveAsImage />
          <MainMenu.DefaultItems.Export />
        </MainMenu>
      </Excalidraw>
    </Box>
  );
};

const CallWhiteboardCatchFallback: React.FC = () => null;
const CallWhiteboardSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallWhiteboard: React.FC = () => (
  <Catch fallback={<CallWhiteboardCatchFallback />}>
    <Suspense fallback={<CallWhiteboardSuspenseFallback />}>
      <CallWhiteboardMain />
    </Suspense>
  </Catch>
);

export default CallWhiteboard;
