import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
} from 'firebase/firestore';
import { InterviewDoc, isInterviewRef } from './Interview';

export type InterviewWhiteboardFileDoc = {
  id: string;
  data: string;
};

export const isInterviewWhiteboardFileDoc = (
  doc?: DocumentData,
): doc is InterviewWhiteboardFileDoc => true;

export const isInterviewWhiteboardFileRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewWhiteboardFileDoc> => ref.parent.id === 'whiteboardFiles' && ref.parent.parent !== null && isInterviewRef(ref.parent.parent);

export const isInterviewWhiteboardFileSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
InterviewWhiteboardFileDoc
> => isInterviewWhiteboardFileRef(snap.ref);

export const getInterviewWhiteboardFilesCollectionRef = (interviewRef: DocumentReference<InterviewDoc>): CollectionReference<InterviewWhiteboardFileDoc> => collection(interviewRef, 'whiteboardFiles') as CollectionReference<InterviewWhiteboardFileDoc>;
