import {
  Box,
  BoxProps,
  Center,
  Grid,
  GridItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import useCurrentTime from '../hooks/useCurrentTime';
import {
  InterviewScheduleItemDoc,
  getInterviewScheduleItemsCollectionRef,
} from '../types/InterviewScheduleItem';
import { SkillDoc } from '../types/Skill';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import CallScheduleItem from './CallScheduleItem';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';
import Loader from './Loader';

export type Props = BoxProps;

const CallScheduleMain: React.FC<Props> = (props) => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: scheduleItemsSnap } = useFirestoreCollection(
    query(
      getInterviewScheduleItemsCollectionRef(interviewRef),
      orderBy('startsAt', 'desc'),
    ),
  );

  const currentScheduleItemIndex = useMemo(
    () => _.findIndex(
      scheduleItemsSnap.docs,
      (scheduleItemSnap) => {
        const scheduleItem = scheduleItemSnap.data();
        return !!scheduleItem.startedAt && !scheduleItem.endedAt;
      },
    ),
    [scheduleItemsSnap.docs],
  );

  const skillBlocks = useMemo(
    () => _.reduce<
    QueryDocumentSnapshot<InterviewScheduleItemDoc>,
    {
      skillRef: DocumentReference<SkillDoc>,
      colSpan: number,
      duration: number,
      complete: number,
    }[]
    >(
      scheduleItemsSnap.docs,
      (res, snap, i) => {
        const { skillRef, startsAt, endsAt } = snap.data();

        const duration = (endsAt.toMillis() - startsAt.toMillis()) / (1000 * 60);

        if (!res.length) {
          return [{
            skillRef,
            colSpan: 1,
            duration,
            complete: 0,
          }];
        }

        const last = res[res.length - 1];

        if (last.skillRef.id !== skillRef.id) {
          return [
            ...res,
            {
              skillRef,
              colSpan: 1,
              duration,
              complete: 0,
            },
          ];
        }

        last.colSpan += 1;
        last.duration += duration;

        return res;
      },
      [],
    ),
    [scheduleItemsSnap.docs],
  );

  const currentTime = useCurrentTime(1000);

  const timePlanned = useMemo<number>(() => {
    if (currentScheduleItemIndex < 0) {
      return 0;
    }

    return _.take(scheduleItemsSnap.docs, currentScheduleItemIndex + 1)
      .reduce((sum, snap) => {
        const duration = (
          snap.data().endsAt.toMillis() - snap.data().startsAt.toMillis()
        ) / (1000 * 60);

        return sum + (duration * 60 * 1000);
      }, 0);
  }, [currentScheduleItemIndex, scheduleItemsSnap.docs]);

  const timeLeft = useMemo<number>(() => Math.round(
    (
      (timePlanned + interview.startsAt.toDate().getTime()) - currentTime.getTime()
    ) / 1000,
  ) * 1000, [currentTime, interview, timePlanned]);

  const [minutesLeft, secondsLeft] = useMemo<[string, string]>(() => {
    const d = moment.duration(timeLeft);
    return [
      String(Math.abs(d.minutes())).padStart(2, '0'),
      String(Math.abs(d.seconds())).padStart(2, '0'),
    ];
  }, [timeLeft]);

  const gridColumns = useMemo(
    () => (scheduleItemsSnap.docs).map((snap) => {
      const duration = (
        snap.data().endsAt.toMillis() - snap.data().startsAt.toMillis()
      ) / (1000 * 60);

      return `${duration}fr`;
    }).join(' '),
    [scheduleItemsSnap.docs],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <VStack {...props} spacing={1} userSelect="none" alignItems="stretch">
      <Grid
        templateColumns={gridColumns}
        templateRows="36px 4px"
        columnGap={1}
        rowGap={1}
      >
        {skillBlocks.map(({
          skillRef,
          colSpan,
          duration,
          complete,
        }) => (
          <GridItem key={skillRef.id} colSpan={colSpan}>
            <CallScheduleItem
              skillRef={skillRef}
              duration={duration}
              total={colSpan}
              complete={complete}
            />
          </GridItem>
        ))}

        {scheduleItemsSnap.docs.map((scheduleItemSnap, i) => (
          <GridItem key={scheduleItemSnap.id}>
            {currentScheduleItemIndex > i ? (
              <Box
                h="100%"
                w="100%"
                bg="cf.status.ended"
                position="relative"
                borderLeftRadius={i === 0 ? 'md' : undefined}
                borderRightRadius={i === scheduleItemsSnap.docs.length - 1 ? 'md' : undefined}
              />
            ) : null}

            {currentScheduleItemIndex === i ? (
              <Box
                h="100%"
                w="100%"
                bg="cf.status.started"
                position="relative"
                borderLeftRadius={i === 0 ? 'md' : undefined}
                borderRightRadius={i === scheduleItemsSnap.docs.length - 1 ? 'md' : undefined}
              />
            ) : null}

            {currentScheduleItemIndex < i ? (
              <Box
                h="100%"
                w="100%"
                bg="cf.status.created"
                position="relative"
                borderLeftRadius={i === 0 ? 'md' : undefined}
                borderRightRadius={i === scheduleItemsSnap.docs.length - 1 ? 'md' : undefined}
              />
            ) : null}
          </GridItem>
        ))}
      </Grid>

      <Center width="100%">
        {timeLeft <= 0 ? (
          <Text
            fontSize="xs"
            lineHeight="shorter"
            color="cf.red"
          >
            {minutesLeft}
            :
            {secondsLeft}
          </Text>
        ) : null}

        {timeLeft > 0 ? (
          <Text
            fontSize="xs"
            lineHeight="shorter"
            color="cf.bgAccent"
          >
            {minutesLeft}
            :
            {secondsLeft}
          </Text>
        ) : null}
      </Center>
    </VStack>
  );
};

const CallScheduleCatchFallback: React.FC = () => null;
const CallScheduleSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CallSchedule: React.FC<Props> = (props) => (
  <Catch fallback={<CallScheduleCatchFallback />}>
    <Suspense fallback={<CallScheduleSuspenseFallback />}>
      <CallScheduleMain {...props} />
    </Suspense>
  </Catch>
);

export default CallSchedule;
