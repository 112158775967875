import { VStack } from '@chakra-ui/react';
import { DocumentReference, query, where } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { MicrosoftCredentialsDoc } from '../../types/MicrosoftAuthCredentials';
import { getMicrosoftCalendarsCollectionRef } from '../../types/MicrosoftCalendar';
import Catch from '../Catch';
import { useUserRef } from '../UserRefContext';
import MicrosoftCalendar from './MicrosoftCalendar';

export interface Props {
  credentialsRef: DocumentReference<MicrosoftCredentialsDoc>;
}

const MicrosoftCalendarsMain: React.FC<Props> = ({ credentialsRef }) => {
  const userRef = useUserRef();
  const firestore = useFirestore();
  const { data: microsoftCalendarsSnap } = useFirestoreCollection(
    query(
      getMicrosoftCalendarsCollectionRef(firestore),
      where('userRef', '==', userRef),
      where('credentialsRef', '==', credentialsRef),
    ),
  );

  if (!microsoftCalendarsSnap.docs.length) {
    return null;
  }

  return (
    <VStack alignItems="stretch" spacing={2}>
      {microsoftCalendarsSnap.docs.map((microsoftCalendarSnap) => (
        <MicrosoftCalendar
          key={microsoftCalendarSnap.id}
          microsoftCalendarSnap={microsoftCalendarSnap}
        />
      ))}
    </VStack>
  );
};

export const MicrosoftCalendarsCatchFallback: React.FC = () => null;
export const MicrosoftCalendarsSuspenseFallback: React.FC = () => (<>Loading</>);

/* eslint-disable react/jsx-props-no-spreading */
const MicrosoftCalendars: React.FC<Props> = (props) => (
  <Catch fallback={<MicrosoftCalendarsCatchFallback />}>
    <Suspense fallback={<MicrosoftCalendarsSuspenseFallback />}>
      <MicrosoftCalendarsMain {...props} />
    </Suspense>
  </Catch>
);

export default MicrosoftCalendars;
