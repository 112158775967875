import { Text, VStack } from '@chakra-ui/react';
import {
  DocumentReference,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import { useFirestore, useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import BlockList from '../../../../components/BlockList';
import { useCandidateRef } from '../../../../components/CandidateRefProvider';
import Catch from '../../../../components/Catch';
import { InterviewStatus, getInterviewsCollectionRef } from '../../../../types/Interview';
import { SkillDoc } from '../../../../types/Skill';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';
import SkillItem, { SkillItemSuspenseFallback } from './SkillItem';

const SkillsMain: React.FC = () => {
  const candidateRef = useCandidateRef();

  const firestore = useFirestore();

  const { data: candidateSnap } = useFirestoreDoc(candidateRef);

  if (!candidateSnap.exists()) {
    throw new SnapNotFoundError(candidateSnap);
  }

  const candidate = useMemo(() => candidateSnap.data(), [candidateSnap]);

  const { data: interviewsSnap } = useFirestoreCollection(
    query(
      getInterviewsCollectionRef(firestore),
      where('candidateRef', '==', candidateRef),
      where('status', 'in', [InterviewStatus.CREATED, InterviewStatus.STARTED, InterviewStatus.ENDED]),
      orderBy('startsAt', 'asc'),
    ),
  );

  const skillRefs = useMemo(
    () => _.unionBy(
      interviewsSnap.docs.reduce(
        (refs, interviewSnap) => _.unionBy(
          refs,
          interviewSnap.data().skillRefs,
          (ref) => ref.id,
        ),
        [] as DocumentReference<SkillDoc>[],
      ),
      candidate.skillRefs,
      (ref) => ref.id,
    ),
    [candidate.skillRefs, interviewsSnap.docs],
  );

  return (
    <VStack spacing={1} alignItems="stretch" data-intercom-target="Skills">
      <Text
        pt="1px"
        pb="3px"
        color="cf.cntTertiary"
        fontSize="sm"
        lineHeight="short"
        fontWeight="medium"
      >
        Skills
        {' '}
        &middot;
        {' '}
        {skillRefs.length}
      </Text>

      <BlockList variant="outline">
        {skillRefs.map((skillRef) => (
          <SkillItem key={skillRef.id} skillRef={skillRef} />
        ))}
      </BlockList>
    </VStack>
  );
};

export const SkillsSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Text
      pt="1px"
      pb="3px"
      color="cf.cntTertiary"
      fontSize="sm"
      lineHeight="short"
      fontWeight="medium"
    >
      Skills
    </Text>

    <BlockList variant="outline">
      <SkillItemSuspenseFallback />
      <SkillItemSuspenseFallback />
      <SkillItemSuspenseFallback />
    </BlockList>
  </VStack>
);

const SkillsCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Skills: React.FC = () => (
  <Catch fallback={<SkillsCatchFallback />}>
    <Suspense fallback={<SkillsSuspenseFallback />}>
      <SkillsMain />
    </Suspense>
  </Catch>
);

export default Skills;
