import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
} from 'firebase/firestore';
import { ExpertDoc } from './Expert';
import { SendGridEventsParentDoc } from './SendGridEvent';

export type ExpertInvitationDoc = {
  accepted: boolean;
  email: string;
  firstName: string;
  lastName: string;
} & SendGridEventsParentDoc;

export const isExpertInvitationDoc = (
  doc?: DocumentData,
): doc is ExpertInvitationDoc => true;

export const isExpertInvitationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ExpertInvitationDoc> => ref.parent.id === 'invitations';

export const isExpertInvitationSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ExpertInvitationDoc> => isExpertInvitationRef(snap.ref);

export const getExpertInvitationsCollectionRef = (expertRef: DocumentReference<ExpertDoc>): CollectionReference<ExpertInvitationDoc> => collection(expertRef, 'invitations') as CollectionReference<ExpertInvitationDoc>;
