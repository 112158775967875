import {
  Box,
  BoxProps,
  HStack,
  Skeleton,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  QueryDocumentSnapshot,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { getCandidatesCollectionRef } from '../types/Candidate';
import { StageDoc } from '../types/Stage';
import CandidateAvatar, { CandidateAvatarSuspenseFallback } from './CandidateAvatar';
import Catch from './Catch';
import { useOrganizationRef } from './OrganizationRefContext';

export type Props = {
  stageSnap: QueryDocumentSnapshot<StageDoc>;
} & BoxProps;

const StageInfoMain: React.FC<Props> = ({ stageSnap, ...boxProps }: Props) => {
  const organizationRef = useOrganizationRef();
  const stage = useMemo(() => stageSnap.data(), [stageSnap]);

  const firestore = useFirestore();

  const { data: candidatesSnap } = useFirestoreCollection(
    query(
      getCandidatesCollectionRef(firestore),
      where('stageRef', '==', stageSnap.ref),
      where('organizationRef', '==', organizationRef),
      orderBy('createdAt', 'desc'),
      limit(10),
    ),
  );

  return (
    <HStack spacing={3} flexGrow={1}>
      <VStack alignItems="stretch" flexGrow={1} spacing={1} minW={0}>
        <HStack spacing={1} flexGrow={1} minW={0}>
          <Text
            fontWeight="semibold"
            lineHeight="short"
            flexGrow={1}
            flexShrink={1}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {stage.name}
          </Text>
        </HStack>

        <Box
          backgroundImage="linear-gradient(45deg, rgb(139,55,153,0.1) 25%, transparent 25%, transparent 50%, rgb(139,55,153,0.1) 50%, rgb(139,55,153,0.1) 75%, transparent 75%, transparent 100%)"
          backgroundSize="8px 8px"
          overflow="hidden"
          h={5}
        >
          <Wrap
            spacing={1}
            h={5}
          >
            {candidatesSnap.docs.map((candidateSnap) => (
              <WrapItem key={candidateSnap.id}>
                <CandidateAvatar size="xs" boxSize={5} candidateSnap={candidateSnap} />
              </WrapItem>
            ))}
          </Wrap>
        </Box>
      </VStack>
    </HStack>
  );
};

export const StageInfoSuspenseFallback: React.FC = () => (
  <HStack spacing={3} flexGrow={1}>
    <VStack alignItems="stretch" flexGrow={1} spacing={1} minW={0}>
      <Box flexGrow={1}>
        <Skeleton h={5} w="140px" />
      </Box>

      <Box
        backgroundImage="linear-gradient(45deg, rgb(139,55,153,0.1) 25%, transparent 25%, transparent 50%, rgb(139,55,153,0.1) 50%, rgb(139,55,153,0.1) 75%, transparent 75%, transparent 100%)"
        backgroundSize="8px 8px"
        overflow="hidden"
        h={5}
      >
        <Wrap
          spacing={1}
          h={5}
        >
          <WrapItem>
            <CandidateAvatarSuspenseFallback />
            <CandidateAvatarSuspenseFallback />
            <CandidateAvatarSuspenseFallback />
          </WrapItem>
        </Wrap>
      </Box>
    </VStack>
  </HStack>
);

export const StageInfoCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const StageInfo: React.FC<Props> = (props) => (
  <Catch fallback={<StageInfoCatchFallback />}>
    <Suspense fallback={<StageInfoSuspenseFallback />}>
      <StageInfoMain {...props} />
    </Suspense>
  </Catch>
);

export default StageInfo;
