import { ChakraProvider } from '@chakra-ui/react';
import { initializeApp } from 'firebase/app';
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import cfChakraTheme from './cfChakraTheme';
import Loader from './components/Loader';
import reportWebVitals from './reportWebVitals';
import 'moment-timezone';

const prodDomains = [
  'my.clarwis.com',
  'app.clarwis.com',
  'app.crewfilter.io',
  'crewfilter-production.firebaseapp.com',
  'crewfilter-production.web.app',
];

const firebaseApp = initializeApp(_.includes(prodDomains, window.location.hostname) ? {
  apiKey: 'AIzaSyD0q3P8h-B7b4guwpLB22QQJwer9EYBIcQ',
  appId: '1:390006361311:web:f3c895669bbe6b3a926d6a',
  authDomain: 'crewfilter-production.web.app',
  measurementId: 'G-TGP8C9E4PB',
  messagingSenderId: '390006361311',
  projectId: 'crewfilter-production',
  storageBucket: 'crewfilter-production.appspot.com',
} : {
  apiKey: 'AIzaSyB4LtMGA9X9fBo1MuFnWccCxviKqS6G9_8',
  appId: '1:129211865605:web:4aa1301480bb04ff58da6c',
  authDomain: 'crewfilter-development.web.app',
  measurementId: 'G-HTV511Q8Z1',
  messagingSenderId: '129211865605',
  projectId: 'crewfilter-development',
  storageBucket: 'crewfilter-development.appspot.com',
});

if (_.includes(prodDomains, window.location.hostname)) {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6Lei--0mAAAAAFoD9ylbeJYHbPN5GasocLu7lZBJ'),
    isTokenAutoRefreshEnabled: true,
  });
} else {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6Lep9O0mAAAAAHfhIwDdkAM6BqCdS6RCOc97C988'),
    isTokenAutoRefreshEnabled: true,
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ChakraProvider theme={cfChakraTheme}>
      <Suspense fallback={<Loader />}>
        <App firebaseApp={firebaseApp} />
      </Suspense>
    </ChakraProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare global {
  interface Window { Intercom: (e: string, o?: Record<string, unknown>) => void; }
}
