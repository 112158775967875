import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
} from 'firebase/firestore';
import {
  InterviewWhiteboardElementDoc,
  isInterviewWhiteboardElementRef,
} from './InterviewWhiteboardElement';

export type InterviewWhiteboardElementStateDoc = InterviewWhiteboardElementDoc & {
  timestamp: Timestamp;
};

export const isInterviewWhiteboardElementStateDoc = (
  doc?: DocumentData,
): doc is InterviewWhiteboardElementStateDoc => true;

export const isInterviewWhiteboardElementStateRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewWhiteboardElementStateDoc> => ref.parent.id === 'states' && ref.parent.parent !== null && isInterviewWhiteboardElementRef(ref.parent.parent);

export const isInterviewWhiteboardElementStateSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
InterviewWhiteboardElementStateDoc
> => isInterviewWhiteboardElementStateRef(snap.ref);

export const getInterviewWhiteboardElementStatesCollectionRef = (whiteboardElementRef: DocumentReference<InterviewWhiteboardElementDoc>): CollectionReference<InterviewWhiteboardElementStateDoc> => collection(whiteboardElementRef, 'states') as CollectionReference<InterviewWhiteboardElementStateDoc>;
