import { Text, VStack } from '@chakra-ui/react';
import {
  doc,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import React, { Suspense, useCallback, useState } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import PaddingBlock from '../../components/PaddingBlock';
import { useUserRef } from '../../components/UserRefContext';
import { CandidateStatus, getCandidatesCollectionRef } from '../../types/Candidate';
import CandidateForm, { FormFields } from './CandidateForm';

export type Props = {
  onPrevious: () => unknown;
  onNext: () => unknown;
};

const CandidatesScreenMain: React.FC<Props> = ({ onPrevious, onNext }) => {
  const organizationRef = useOrganizationRef();
  const userRef = useUserRef();
  const firestore = useFirestore();

  const { data: candidatesSnap } = useFirestoreCollection(
    query(
      getCandidatesCollectionRef(firestore),
      where('organizationRef', '==', organizationRef),
      where('status', 'in', [CandidateStatus.ACTIVE, CandidateStatus.PAUSED, CandidateStatus.FINALIZED]),
      orderBy('createdAt', 'desc'),
      limit(10),
    ),
  );

  const [candidateRef, setCandidateRef] = useState(doc(getCandidatesCollectionRef(firestore)));

  const onSubmit = useCallback(
    async ({
      email,
      firstName,
      lastName,
      skillRefs,
      internalExpertTiers,
      externalExpertTiers,
    }: FormFields) => {
      await setDoc(
        candidateRef,
        {
          email,
          firstName,
          lastName,
          organizationRef,
          skillRefs,
          expertRefs: [],
          createdAt: serverTimestamp(),
          assigneeRefs: [userRef],
          status: CandidateStatus.PAUSED,
          internalExpertTiers,
          externalExpertTiers,
          _v: 3,
        },
      );

      setCandidateRef(doc(getCandidatesCollectionRef(firestore)));
    },
    [candidateRef, firestore, organizationRef, userRef],
  );

  return (
    <VStack spacing={8} alignItems="stretch" textAlign="left">
      <PaddingBlock>
        <Text>
          Let&apos;s expedite the candidate integration process with lightning speed! In this step,
          we&apos;ll send personalized invitations to potential candidates on behalf of your
          organization, inviting them for interviews. Once they provide their availability, our
          platform leaps into action, immediately and automatically matching them with suitable
          technical experts. The magic happens in seconds, not hours, as we swiftly schedule
          interviews, eliminating the tedious and time-consuming coordination process. With this
          efficient approach, you can rapidly connect with and evaluate candidates, ensuring you
          make informed decisions in record time. Let&apos;s get started and make your interview
          scheduling effortless and instantaneous!
        </Text>
      </PaddingBlock>

      <CandidateForm
        key={candidateRef.id}
        onPrevious={onPrevious}
        onNext={onNext}
        onSubmit={onSubmit}
        candidatesSnap={candidatesSnap}
      />
    </VStack>
  );
};

export const CandidatesScreenCatchFallback: React.FC = () => null;
export const CandidatesScreenSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidatesScreen: React.FC<Props> = (props) => (
  <Catch fallback={<CandidatesScreenCatchFallback />}>
    <Suspense fallback={<CandidatesScreenSuspenseFallback />}>
      <CandidatesScreenMain {...props} />
    </Suspense>
  </Catch>
);

export default CandidatesScreen;
