import { doc } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirestore, useFirestoreDoc, useUser } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import UserRefProvider from '../../components/UserRefContext';
import AuthError from '../../types/AuthError';
import { getUsersCollectionRef } from '../../types/User';

export type CheckUserProps = {
  fallback: React.ReactElement;
};

const CheckUserLayoutMain: React.FC<CheckUserProps> = ({ fallback }) => {
  const { data: user } = useUser();

  if (!user) {
    throw new AuthError();
  }

  const firestore = useFirestore();
  const userRef = useMemo(
    () => doc(getUsersCollectionRef(firestore), user.uid),
    [firestore, user.uid],
  );
  const { data: userSnap } = useFirestoreDoc(userRef);

  if (!userSnap.exists()) {
    return fallback;
  }

  return (
    <UserRefProvider userRef={userRef}>
      <Outlet />
    </UserRefProvider>
  );
};

export const CheckUserLayoutCatchFallback: React.FC = () => null;
export const CheckUserLayoutSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CheckUserLayout: React.FC<CheckUserProps> = (props) => (
  <Catch fallback={<CheckUserLayoutCatchFallback />}>
    <Suspense fallback={<CheckUserLayoutSuspenseFallback />}>
      <CheckUserLayoutMain {...props} />
    </Suspense>
  </Catch>
);

export default CheckUserLayout;
