import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import CandidateRefProvider from '../../components/CandidateRefProvider';
import { getCandidatesCollectionRef } from '../../types/Candidate';
import CandidateDetailsScreen from './CandidateDetailsScreen';

export type Params = {
  candidateId: string;
  organizationId: string;
};

const OrganizationCandidateDetailsPage: React.FC = () => {
  const { candidateId } = useParams<Params>();
  const firestore = useFirestore();
  const candidateRef = useMemo(
    () => doc(getCandidatesCollectionRef(firestore), candidateId),
    [candidateId, firestore],
  );

  return (
    <CandidateRefProvider candidateRef={candidateRef} key={candidateRef.path}>
      <CandidateDetailsScreen />
    </CandidateRefProvider>
  );
};

export default OrganizationCandidateDetailsPage;
