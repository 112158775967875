import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import OrganizationRefProvider from '../../components/OrganizationRefContext';
import { getOrganizationsCollectionRef } from '../../types/Organization';

const OrganizationLayout: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const firestore = useFirestore();
  const organizationRef = useMemo(
    () => doc(getOrganizationsCollectionRef(firestore), organizationId),
    [firestore, organizationId],
  );

  return (
    <OrganizationRefProvider organizationRef={organizationRef}>
      <Outlet />
    </OrganizationRefProvider>
  );
};

export default OrganizationLayout;
