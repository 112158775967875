import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { DocumentReference, arrayRemove, setDoc } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { useCandidateRef } from '../../../components/CandidateRefProvider';
import Catch from '../../../components/Catch';
import UserAvatar from '../../../components/UserAvatar';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import { UserDoc } from '../../../types/User';

export type Props = {
  assigneeRef: DocumentReference<UserDoc>;
};

const AssigneeTagMain: React.FC<Props> = ({ assigneeRef }) => {
  const candidateRef = useCandidateRef();

  const { data: assigneeSnap } = useFirestoreDoc<UserDoc>(assigneeRef);

  if (!assigneeSnap.exists()) {
    throw new SnapNotFoundError(assigneeSnap);
  }

  const assignee = useMemo(() => assigneeSnap.data(), [assigneeSnap]);

  const onClick = useCallback(() => {
    setDoc(candidateRef, {
      assigneeRefs: arrayRemove(assigneeRef),
    }, { merge: true });
  }, [assigneeRef, candidateRef]);

  return (
    <Tag
      color="cf.cntPrimary"
      boxShadow="inset 0 0 0px 1px var(--chakra-colors-cf-brdBlackAlpha12)"
      variant="outline"
      size="lg"
      h={9}
    >
      <UserAvatar
        userRef={assigneeRef}
        size="xs"
        boxSize={6}
        name={`${assignee.firstName} ${assignee.lastName}`}
        ml={-1}
        mr={2}
      />

      <TagLabel>
        {assignee.firstName}
        {' '}
        {assignee.lastName}
      </TagLabel>

      <TagCloseButton onClick={onClick} />
    </Tag>
  );
};

export const AssigneeTagSuspenseFallback: React.FC = () => null;

const AssigneeTagCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const AssigneeTag: React.FC<Props> = (props) => (
  <Catch fallback={<AssigneeTagCatchFallback />}>
    <Suspense fallback={<AssigneeTagSuspenseFallback />}>
      <AssigneeTagMain {...props} />
    </Suspense>
  </Catch>
);

export default AssigneeTag;
