import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

export type RequestData = {
  amount: number;
  expertId: string;
  deelLegalEntityId: number;
  deelTeamId: number;
};

export type ResponseData = string;

const useDeelCreateContract = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'deel-createcontract'),
  [functions],
);

export default useDeelCreateContract;
