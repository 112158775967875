import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

export type RequestData = {
  organizationId: string;
  returnUrl: string;
  locale?: string;
  configuration?: string;
};

export type ResponseData = {
  url: string;
};

const useStripeCreatePortalLink = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'stripe-createportallink'),
  [functions],
);

export default useStripeCreatePortalLink;
