import { Box } from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import { useInterviewRef } from '../../components/InterviewRefContext';
import { getTwilioCompositionsCollectionRef } from '../../types/TwilioComposition';
import Composition from './Composition';

const CompositionsMain: React.FC = () => {
  const interviewRef = useInterviewRef();

  const firestore = useFirestore();

  const { data: compositionsSnap } = useFirestoreCollection(query(
    getTwilioCompositionsCollectionRef(firestore),
    where('interviewRef', '==', interviewRef),
    orderBy('start'),
  ));

  return (
    <Box
      h={180}
      w={320}
      borderRadius="md"
      overflow="hidden"
      bg="cf.black"
    >
      {compositionsSnap.docs.map((compositionSnap) => (
        <Composition
          key={compositionSnap.id}
          twilioCompositionSnap={compositionSnap}
        />
      ))}
    </Box>
  );
};

export const CompositionsCatchFallback: React.FC = () => null;
export const CompositionsSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Compositions: React.FC = () => (
  <Catch fallback={<CompositionsCatchFallback />}>
    <Suspense fallback={<CompositionsSuspenseFallback />}>
      <CompositionsMain />
    </Suspense>
  </Catch>
);

export default Compositions;
