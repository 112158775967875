import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  collection,
} from 'firebase/firestore';
import { GoogleCredentialsDoc } from './GoogleCredentials';
import { UserDoc } from './User';

export type GoogleCalendarDoc = {
  userRef: DocumentReference<UserDoc>;
  credentialsRef: DocumentReference<GoogleCredentialsDoc>;
  calendar: {
    backgroundColor?: string | null;
    summary?: string | null;
  };
  isEnabled: boolean;
  busy: string[];
};

export const isGoogleCalendarDoc = (
  doc: DocumentData,
): doc is GoogleCalendarDoc => true;

export const isGoogleCalendarRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<GoogleCalendarDoc> => ref.parent.id === 'googleCalendars';

export const isGoogleCalendarSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<GoogleCalendarDoc> => isGoogleCalendarRef(snap.ref);

export const getGoogleCalendarsCollectionRef = (firestore: Firestore): CollectionReference<GoogleCalendarDoc> => collection(firestore, 'googleCalendars') as CollectionReference<GoogleCalendarDoc>;
