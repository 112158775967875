import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { WebhookEventType } from '../types/Webhook';

export type RequestData = {
  organizationId: string;
  url: string;
  eventTypes: WebhookEventType[];
};

export type ResponseData = string;

const useApiCreateWebhook = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'api-createwebhook'),
  [functions],
);

export default useApiCreateWebhook;
