import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useFirestore } from 'reactfire';
import SkillRefProvider from '../../components/SkillRefContext';
import { getSkillsCollectionRef } from '../../types/Skill';
import EditSkillScreen from './EditSkillScreen';

export type Params = {
  skillId: string;
  organizationId: string;
};

const OrganizationSkillEditPage: React.FC = () => {
  const { skillId } = useParams<Params>();
  const firestore = useFirestore();
  const skillRef = useMemo(
    () => doc(getSkillsCollectionRef(firestore), skillId),
    [skillId, firestore],
  );

  return (
    <SkillRefProvider skillRef={skillRef} key={skillRef.path}>
      <EditSkillScreen />
    </SkillRefProvider>
  );
};

export default OrganizationSkillEditPage;
