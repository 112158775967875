import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot, orderBy, query } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection } from 'reactfire';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import MoonGradeIcon from '../../../icons/TierCIcon';
import { getOrganizationBalanceRef } from '../../../types/OrganizationBalance';
import {
  OrganizationBalanceTransactionDoc,
  getOrganizationBalanceTransactionsCollectionRef,
} from '../../../types/OrganizationBalanceTransaction';
import TransactionRow from './TransactionRow';

const OrganizationBalanceScreenMain: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const organizationBalanceRef = useMemo(
    () => getOrganizationBalanceRef(organizationRef),
    [organizationRef],
  );

  const balanceTransactionsRef = useMemo(
    () => getOrganizationBalanceTransactionsCollectionRef(organizationBalanceRef),
    [organizationBalanceRef],
  );

  const { data: balanceTransactionsSnap } = useFirestoreCollection(
    query(
      balanceTransactionsRef,
      orderBy('timestamp', 'asc'),
    ),
  );

  const grid = useMemo(
    () => {
      let totalTierCQuantity = 0;

      return balanceTransactionsSnap.docs
        .map<[QueryDocumentSnapshot<OrganizationBalanceTransactionDoc>, number]>((snap) => {
        const tierCQuantity = snap.data()?.tierCQuantity || 0;
        totalTierCQuantity += tierCQuantity;
        return [snap, totalTierCQuantity];
      }).reverse();
    },
    [balanceTransactionsSnap.docs],
  );

  return (
    <Box overflow="auto" flexGrow={0} flexShrink={1} maxH="100%" px={5} py={3}>
      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Time</Th>
              <Th>Action</Th>
              <Th isNumeric><MoonGradeIcon /></Th>
              <Th>Candidate</Th>
              <Th>Interview</Th>
            </Tr>
          </Thead>
          <Tbody>
            {grid.map(([balanceTransactionSnap, totalTierCQuantity]) => (
              <TransactionRow
                key={balanceTransactionSnap.id}
                transactionSnap={balanceTransactionSnap}
                totalTierCQuantity={totalTierCQuantity}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const OrganizationBalanceScreenCatchFallback: React.FC = () => null;
export const OrganizationBalanceScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const OrganizationBalanceScreen: React.FC = () => (
  <Catch fallback={<OrganizationBalanceScreenCatchFallback />}>
    <Suspense fallback={<OrganizationBalanceScreenSuspenseFallback />}>
      <OrganizationBalanceScreenMain />
    </Suspense>
  </Catch>
);

export default OrganizationBalanceScreen;
