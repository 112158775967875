import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { UserDoc } from './User';

export type FCMRegistrationTokenDoc = {
  userRef: DocumentReference<UserDoc>;
  token: string;
  timestamp: Timestamp;
};

export const isFCMRegistrationTokenDoc = (
  doc: DocumentData,
): doc is FCMRegistrationTokenDoc => true;

export const isFCMRegistrationTokenRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<FCMRegistrationTokenDoc> => ref.parent.id === 'fcmRegistrationTokens';

export const isFCMRegistrationTokenSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<FCMRegistrationTokenDoc> => isFCMRegistrationTokenRef(snap.ref);

export const getFCMRegistrationTokensRef = (firestore: Firestore): CollectionReference<FCMRegistrationTokenDoc> => collection(firestore, 'fcmRegistrationTokens') as CollectionReference<FCMRegistrationTokenDoc>;
