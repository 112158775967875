import { datadogLogs } from '@datadog/browser-logs';
import 'resize-observer-polyfill';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseApp } from 'firebase/app';
import { Auth, User, getAuth } from 'firebase/auth';
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';
import { getPerformance } from 'firebase/performance';
import {
  RemoteConfig,
  fetchAndActivate,
  getRemoteConfig,
  getString,
} from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';
import 'moment/locale/uk';
import i18next, { i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { IntercomProvider } from 'react-use-intercom';
import {
  AnalyticsProvider,
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  PerformanceProvider,
  RemoteConfigProvider,
  StorageProvider,
} from 'reactfire';
import {
  ReportHandler,
  getCLS,
  getFID,
  getLCP,
} from 'web-vitals';
import { AlgoliaPublicProvider } from './components/AlgoliaPublicProvider';
import AnalyticsSetUserId from './components/AnalyticsSetUserId';
import Catch from './components/Catch';
import CypressActions from './components/CypressActions';
import ErrorPage from './components/ErrorPage';
import IntercomBoot from './components/IntercomBoot';
import MessagingProvider from './components/MessagingProvider';
import { StripePromiseProvider } from './components/StripePromiseProvider';
import usePromise from './hooks/usePromise';
import { I18NextRemoteConfigBackend } from './I18NextRemoteConfigBackend';
import Navigation from './navigation';

const getI18nextInstancePromise = async (remoteConfig: RemoteConfig) => {
  const i18nextInstance = i18next
    .createInstance()
    .use(I18NextRemoteConfigBackend)
    .use(LanguageDetector)
    .use(initReactI18next);

  await i18nextInstance.init({
    backend: {
      remoteConfig,
    },
    detection: {
      order: ['navigator'],
    },
    fallbackLng: 'en',
    ns: ['app'],
    defaultNS: 'app',
    supportedLngs: ['en'],
    nonExplicitSupportedLngs: false,
  });

  return i18nextInstance;
};

const waitUserState = async (auth: Auth) => {
  let resolve: (user: User | null) => void;
  let reject: (error: Error) => void;

  const promise = new Promise<User | null>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  const stop = auth.onAuthStateChanged(
    (user: User | null) => {
      stop();
      resolve(user);
    },
    (error: Error) => {
      stop();
      reject(error);
    },
  );

  return promise;
};

const App: React.FC<{ firebaseApp: FirebaseApp }> = ({ firebaseApp }) => {
  const remoteConfigInstance = useMemo(() => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 5;
    return remoteConfig;
  }, [firebaseApp]);

  usePromise(fetchAndActivate, [remoteConfigInstance]);

  const datadogClientToken = useMemo(() => getString(remoteConfigInstance, 'datadog_clientToken'), [remoteConfigInstance]);
  const intercomAppId = useMemo(() => getString(remoteConfigInstance, 'intercom_appId'), [remoteConfigInstance]);
  const algoliaAppId = useMemo(() => getString(remoteConfigInstance, 'algolia_appId'), [remoteConfigInstance]);
  const stripePublishableKey = useMemo(() => getString(remoteConfigInstance, 'stripe_publishableKey'), [remoteConfigInstance]);

  const i18nInstance: i18n = usePromise(getI18nextInstancePromise, [remoteConfigInstance]);

  useEffect(() => {
    if (datadogClientToken) {
      datadogLogs.init({
        clientToken: datadogClientToken,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sampleRate: 100,
      });
    }
  }, [datadogClientToken]);

  moment.locale('en-US');

  useEffect(() => {
    moment.fn.zoneName = function zoneName(): string {
      // eslint-disable-next-line react/no-this-in-sfc
      const abbr: string = this.zoneAbbr();
      return i18nInstance.t(abbr, { ns: 'timezones' });
    };

    // const languageChangedHandler = (lng: string) => {
    //   moment.locale(lng);
    // };

    // i18nInstance.on('languageChanged', languageChangedHandler);

    // return () => {
    //   i18nInstance.off('languageChanged', languageChangedHandler);
    // };
  }, [i18nInstance]);

  const authInstance = useMemo(() => getAuth(firebaseApp), [firebaseApp]);
  usePromise(waitUserState, [authInstance]);

  const analyticsInstance = useMemo(() => getAnalytics(firebaseApp), [firebaseApp]);

  useEffect(() => {
    const webVitalsReportHandler: ReportHandler = ({
      name, delta, id,
    }) => {
      logEvent(analyticsInstance, name, {
        eventCategory: 'Web Vitals',
        eventAction: name,
        eventLabel: id,
        eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
        nonInteraction: true,
        transport: 'beacon',
      });
    };

    getCLS(webVitalsReportHandler);
    getFID(webVitalsReportHandler);
    getLCP(webVitalsReportHandler);
  }, [analyticsInstance]);

  const firestoreInstance = useMemo(
    () => initializeFirestore(
      firebaseApp,
      {
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
        }),
      },
    ),
    [firebaseApp],
  );
  const functionsInstance = useMemo(() => getFunctions(firebaseApp), [firebaseApp]);
  const storageInstance = useMemo(() => getStorage(firebaseApp), [firebaseApp]);
  const messagingInstance = useMemo(() => getMessaging(firebaseApp), [firebaseApp]);
  const performanceInstance = useMemo(() => getPerformance(firebaseApp), [firebaseApp]);

  return (
    <CookiesProvider>
      <I18nextProvider i18n={i18nInstance}>
        <IntercomProvider appId={intercomAppId} autoBoot>
          <FirebaseAppProvider firebaseApp={firebaseApp} suspense>
            <AuthProvider sdk={authInstance}>
              <AnalyticsProvider sdk={analyticsInstance}>
                <FirestoreProvider sdk={firestoreInstance}>
                  <FunctionsProvider sdk={functionsInstance}>
                    <StorageProvider sdk={storageInstance}>
                      <RemoteConfigProvider sdk={remoteConfigInstance}>
                        <MessagingProvider sdk={messagingInstance}>
                          <PerformanceProvider sdk={performanceInstance}>
                            <AlgoliaPublicProvider appId={algoliaAppId}>
                              <StripePromiseProvider publishableKey={stripePublishableKey}>
                                <Catch fallback={<ErrorPage message="Oops! Something went terribly wrong..." />}>
                                  <IntercomBoot />
                                  <AnalyticsSetUserId />
                                  <CypressActions>
                                    <Navigation />
                                  </CypressActions>
                                </Catch>
                              </StripePromiseProvider>
                            </AlgoliaPublicProvider>
                          </PerformanceProvider>
                        </MessagingProvider>
                      </RemoteConfigProvider>
                    </StorageProvider>
                  </FunctionsProvider>
                </FirestoreProvider>
              </AnalyticsProvider>
            </AuthProvider>
          </FirebaseAppProvider>
        </IntercomProvider>
      </I18nextProvider>
    </CookiesProvider>
  );
};

export default App;
