import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
// eslint-disable-next-line import/no-cycle
import { ExpertDoc, ExpertTier } from './Expert';
import { OrganizationDoc } from './Organization';
import { SkillDoc } from './Skill';
// eslint-disable-next-line import/no-cycle
import { StageDoc } from './Stage';
import { UserDoc } from './User';

export enum CandidateStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  FINALIZED = 'FINALIZED',
  ARCHIVED = 'ARCHIVED',
}

export enum CandidatePauseReason {
  INTERVIEW_MISSED_BY_CANDIDATE = 'INTERVIEW_MISSED_BY_CANDIDATE',
  INTERVIEW_MISSED_BY_EXPERT = 'INTERVIEW_MISSED_BY_EXPERT',
  NO_MATCHING_EXPERT = 'NO_MATCHING_EXPERT',
  NO_PAYMENT_METHOD = 'NO_PAYMENT_METHOD',
}

export type CandidateDoc = {
  internalExpertTiers: ExpertTier[];
  externalExpertTiers: ExpertTier[];
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  customerUrl?: string;
  createdAt: Timestamp;
  assigneeRefs?: DocumentReference<UserDoc>[];
  userRef?: DocumentReference<UserDoc>;
  organizationRef: DocumentReference<OrganizationDoc>;
  skillRefs: DocumentReference<SkillDoc>[];
  expertRefs: DocumentReference<ExpertDoc>[];
  status: CandidateStatus;
  pauseReason?: CandidatePauseReason;
  stageRef?: DocumentReference<StageDoc>;
  _v: 3;
};

export const isCandidateDoc = (
  doc: DocumentData,
): doc is CandidateDoc => true;

export const isCandidateRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<CandidateDoc> => ref.parent.id === 'candidates';

export const isCandidateSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<CandidateDoc> => isCandidateRef(snap.ref);

export const getCandidatesCollectionRef = (firestore: Firestore): CollectionReference<CandidateDoc> => collection(firestore, 'candidates') as CollectionReference<CandidateDoc>;
