import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
} from 'firebase/firestore';
import { InterviewDoc, isInterviewRef } from './Interview';

export type InterviewWhiteboardElementDoc = {
  updated: number;
  data: string;
};

export const isInterviewWhiteboardElementDoc = (
  doc?: DocumentData,
): doc is InterviewWhiteboardElementDoc => true;

export const isInterviewWhiteboardElementRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewWhiteboardElementDoc> => ref.parent.id === 'whiteboardElements' && ref.parent.parent !== null && isInterviewRef(ref.parent.parent);

export const isInterviewWhiteboardElementSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
InterviewWhiteboardElementDoc
> => isInterviewWhiteboardElementRef(snap.ref);

export const getInterviewWhiteboardElementsCollectionRef = (interviewRef: DocumentReference<InterviewDoc>): CollectionReference<InterviewWhiteboardElementDoc> => collection(interviewRef, 'whiteboardElements') as CollectionReference<InterviewWhiteboardElementDoc>;
