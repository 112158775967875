import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { OrganizationDoc } from './Organization';
import { UserDoc } from './User';

export type DeelAccessTokenDoc = {
  organizationRef: DocumentReference<OrganizationDoc>;
  userRef: DocumentReference<UserDoc>;
  createdAt: Timestamp;
  expiresAt: Timestamp;
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  scope: string[];
};

export const isDeelAccessTokenDoc = (
  doc?: DocumentData,
): doc is DeelAccessTokenDoc => true;

export const isDeelAccessTokenRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<DeelAccessTokenDoc> => ref.parent.id === 'deelAccessTokens';

export const isDeelAccessTokenSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<DeelAccessTokenDoc> => isDeelAccessTokenRef(snap.ref);

export const getDeelAccessTokensCollectionRef = (firestore: Firestore): CollectionReference<DeelAccessTokenDoc> => collection(firestore, 'deelAccessTokens') as CollectionReference<DeelAccessTokenDoc>;
