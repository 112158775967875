import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  RemoteParticipant,
  RemoteVideoTrack,
  RemoteVideoTrackPublication,
  Track,
} from 'twilio-video';

const getTrack = (
  publications: Map<Track.SID, RemoteVideoTrackPublication> | undefined,
): RemoteVideoTrack | null => {
  if (!publications) {
    return null;
  }

  let res: RemoteVideoTrack | null = null;

  publications.forEach((publication) => {
    if (_.endsWith(publication.track?.name, 'camera')) {
      res = publication.track;
    }
  });

  return res;
};

export interface Result {
  track: RemoteVideoTrack | null;
  enabled: boolean | null;
}

export default function useParticipantCameraTrack(
  participant: RemoteParticipant | null,
): Result {
  const [track, setTrack] = useState<RemoteVideoTrack | null>(
    getTrack(participant?.videoTracks),
  );

  useEffect(() => {
    const handler = () => {
      setTrack(getTrack(participant?.videoTracks));
    };

    handler();

    if (participant) {
      participant.on('trackDimensionsChanged', handler);
      participant.on('trackDisabled', handler);
      participant.on('trackEnabled', handler);
      participant.on('trackMessage', handler);
      participant.on('trackPublished', handler);
      participant.on('trackPublishPriorityChanged', handler);
      participant.on('trackStarted', handler);
      participant.on('trackSubscribed', handler);
      participant.on('trackSubscriptionFailed', handler);
      participant.on('trackSwitchedOff', handler);
      participant.on('trackSwitchedOn', handler);
      participant.on('trackUnpublished', handler);
      participant.on('trackUnsubscribed', handler);

      return () => {
        participant.off('trackDimensionsChanged', handler);
        participant.off('trackDisabled', handler);
        participant.off('trackEnabled', handler);
        participant.off('trackMessage', handler);
        participant.off('trackPublished', handler);
        participant.off('trackPublishPriorityChanged', handler);
        participant.off('trackStarted', handler);
        participant.off('trackSubscribed', handler);
        participant.off('trackSubscriptionFailed', handler);
        participant.off('trackSwitchedOff', handler);
        participant.off('trackSwitchedOn', handler);
        participant.off('trackUnpublished', handler);
        participant.off('trackUnsubscribed', handler);
      };
    }

    return () => { };
  }, [participant, setTrack]);

  const [enabled, setEnabled] = useState<boolean | null>(track ? track.isEnabled : null);

  useEffect(() => {
    if (!track) {
      setEnabled(null);
      return () => { };
    }

    setEnabled(track.isEnabled);

    const enable = () => { setEnabled(true); };
    const disable = () => { setEnabled(false); };

    track.on('enabled', enable);
    track.on('disabled', disable);

    return () => {
      track.off('enabled', enable);
      track.off('disabled', disable);
    };
  }, [track]);

  return {
    track,
    enabled,
  };
}
