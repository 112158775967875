import {
  Button,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import * as yup from 'yup';
import ConnectedCalendars from '../../components/ConnectedCalendars';
import PaddingBlock from '../../components/PaddingBlock';
import ScheduleField from '../../components/ScheduleField';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import Spinner from '../../icons/Spinner';
import { UserScheduleDoc, timeSlots } from '../../types/UserSchedule';

export type Props = {
  initialValues: FormFields;
  onSubmit: (values: FormFields) => Promise<unknown>;
  onPrevious: () => unknown;
};

export interface FormFields {
  schedule: UserScheduleDoc;
}

const weekDaySchema = yup.array(yup.string().oneOf([...timeSlots])).required();

const schema = yup.object().shape({
  schedule: yup
    .object()
    .shape({
      su: weekDaySchema,
      mo: weekDaySchema,
      tu: weekDaySchema,
      we: weekDaySchema,
      th: weekDaySchema,
      fr: weekDaySchema,
      sa: weekDaySchema,
    })
    .required()
    .test(
      'minAvailability',
      'You have to be available at least 10 hours a week',
      (value: UserScheduleDoc) => _.flatten(_.values(value)).length > 40,
    ),
});

const AvailabilityForm: React.FC<Props> = ({ initialValues, onSubmit, onPrevious }) => {
  const [validateAll, setValidateAll] = useState(false);

  return (
    <VStack spacing={8} alignItems="stretch" textAlign="left">
      <PaddingBlock>
        <Text>
          Now, let&apos;s make your technical interview process seamless and hassle-free with our
          automated scheduling feature. In this step, we&apos;ll request your working schedule and
          access to your calendar, allowing us to synchronize effortlessly with your availability.
          Our intelligent system takes into account timezone differences and your actual work hours,
          ensuring that interview scheduling becomes a breeze. No more back-and-forth emails or
          phone calls to coordinate interviews; we&apos;ve got it all covered. Trust us to
          efficiently manage your interview calendar, so you can focus on what truly matters -
          finding the best candidates for your team. Let&apos;s synchronize and simplify your
          interview process today!
        </Text>
      </PaddingBlock>

      <Formik<FormFields>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={validateAll}
        validateOnBlur={validateAll}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form
            noValidate
            onSubmit={(...props) => {
              setValidateAll(true);
              return handleSubmit(...props);
            }}
          >
            <VStack spacing={8} alignItems="stretch" textAlign="left">
              <ConnectedCalendars />

              <ScheduleField name="schedule" />

              <HStack justifyContent="right">
                <Button
                  variant="outline"
                  onClick={onPrevious}
                  leftIcon={<ArrowUpIcon />}
                >
                  Previous
                </Button>

                <Spacer />

                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  spinner={<Spinner />}
                  loadingText="Saving..."
                  leftIcon={<ArrowDownIcon />}
                >
                  Next
                </Button>
              </HStack>
            </VStack>
          </form>
        )}
      </Formik>
    </VStack>
  );
};

export default AvailabilityForm;
