import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { GeoPoint } from 'firebase/firestore';
import { useField } from 'formik';
import _ from 'lodash';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc } from 'reactfire';
import useDebounce from '../functions/useDebounce';
import useGoogleGetCity from '../functions/useGoogleGetCity';
import useGoogleSearchCities from '../functions/useGoogleSearchCities';
import BaseIcon from '../icons/BaseIcon';
import EditIcon from '../icons/EditIcon';
import SearchIcon from '../icons/SearchIcon';
import Spinner from '../icons/Spinner';
import { getUserLocationDocRef } from '../types/UserLocation';
import { getUserTimezoneDocRef } from '../types/UserTimezone';
import Catch from './Catch';
import PaddingBlock from './PaddingBlock';
import { useUserRef } from './UserRefContext';

export interface Props {
  name?: string;
  label?: string;
  placeholder?: string;
}

type CityResponse = { location: GeoPoint; name: string; };

const CityFieldMain: React.FC<Props> = ({
  name = 'location',
  label = 'City',
  placeholder = 'Please select...',
}) => {
  const [field, meta, helpers] = useField<GeoPoint>(name);

  const userRef = useUserRef();

  const userTimezoneRef = useMemo(() => getUserTimezoneDocRef(userRef), [userRef]);
  const { data: userTimezoneSnap } = useFirestoreDoc(userTimezoneRef);
  const userTimezone = useMemo(() => userTimezoneSnap.data(), [userTimezoneSnap]);

  const userLocationRef = useMemo(() => getUserLocationDocRef(userRef), [userRef]);
  const { data: userLocationSnap } = useFirestoreDoc(userLocationRef);
  const userLocation = useMemo(() => userLocationSnap.data(), [userLocationSnap]);

  const getCity = useGoogleGetCity();
  const [getResponse, setGetResponse] = useState<CityResponse | null>(null);
  useEffect(() => {
    if (!field.value) {
      return;
    }

    getCity(field.value)
      .then(async (data) => data)
      .then((res) => {
        setGetResponse(res);
        setQuery(res?.name || '');
      });
  }, [getCity, field.value]);

  const searchCities = useGoogleSearchCities();

  const [query, setQuery] = useState('');
  const [isSearching, setSearching] = useState(false);
  const search = useDebounce(query, 500);
  const [searchResponse, setSearchResponse] = useState<CityResponse[]>([]);

  useEffect(() => {
    setSearching(true);
    searchCities({ query: search })
      .then((data) => {
        setSearchResponse(data);
        setSearching(false);
      });
  }, [searchCities, search]);

  const handleChange = useCallback(async (v: string | string[]) => {
    const id = Array.isArray(v) ? v[0] : v;

    const city = _.find(searchResponse, ({ location }) => id === `${location.latitude},${location.longitude}`);

    if (city) {
      helpers.setValue(city.location);
    }
  }, [helpers, searchResponse]);

  const [isLocating, setLocating] = useState<boolean>(false);
  const toast = useToast();
  const getLocation = useCallback(async () => {
    try {
      setLocating(true);
      const position = await new Promise<GeolocationPosition>((res, rej) => {
        navigator.geolocation.getCurrentPosition(res, rej);
      });

      helpers.setValue(new GeoPoint(position.coords.latitude, position.coords.longitude));
    } catch (err) {
      toast({
        status: 'error',
        title: 'Failed to obtain your city',
      });
    } finally {
      setLocating(false);
    }
  }, [helpers, toast]);

  return (
    <FormControl isInvalid={!!meta.error} textAlign="left">
      <FormLabel>{label}</FormLabel>

      <PaddingBlock>
        <HStack alignItems="center">
          <Text
            flexGrow={1}
            flexShrink={1}
            whiteSpace="nowrap"
            minW={0}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {getResponse ? getResponse.name : placeholder}
          </Text>

          <Popover isLazy>
            <PopoverTrigger>
              <Button variant="outline" leftIcon={<EditIcon />}>
                Change
              </Button>
            </PopoverTrigger>

            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <Stack spacing={4}>
                    <InputGroup>
                      <Input
                        placeholder="Search for city"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        autoFocus
                      />
                      <InputRightElement color="cf.cntSecondary">
                        {isSearching ? (
                          <Spinner />
                        ) : (
                          <SearchIcon />
                        )}
                      </InputRightElement>
                    </InputGroup>

                    <RadioGroup value={field.value ? `${field.value.latitude},${field.value.longitude}` : undefined} onChange={handleChange}>
                      <Stack>
                        {searchResponse?.map((option) => (
                          <Radio key={`${option.location.latitude},${option.location.longitude}`} value={`${option.location.latitude},${option.location.longitude}`}>
                            {option.name}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>

          {navigator.geolocation ? (
            <Tooltip label="Get my current city">
              <IconButton
                onClick={getLocation}
                isLoading={isLocating}
                spinner={<Spinner />}
                icon={<BaseIcon />}
                aria-label="Get my current city"
              />
            </Tooltip>
          ) : null}
        </HStack>
      </PaddingBlock>

      <Text mt="1px" mb="3px" color="cf.cntTertiary" lineHeight="short" fontSize="sm">
        We use it to determine timezone
        {' '}
        {userTimezone?.timezone ? (
          <>
            (current:
            {' '}
            {userTimezone.timezone}
            )
          </>
        ) : null}
        {' '}
        and reduce video call latency
        {' '}
        {userLocation?.twilioMediaRegion ? (
          <>
            (current region:
            {' '}
            {userLocation.twilioMediaRegion}
            )
          </>
        ) : null}
      </Text>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

CityFieldMain.defaultProps = {
  name: undefined,
  label: undefined,
  placeholder: undefined,
};

export const CityFieldCatchFallback: React.FC = () => null;
export const CityFieldSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CityField: React.FC<Props> = (props) => (
  <Catch fallback={<CityFieldCatchFallback />}>
    <Suspense fallback={<CityFieldSuspenseFallback />}>
      <CityFieldMain {...props} />
    </Suspense>
  </Catch>
);

CityField.defaultProps = {
  name: undefined,
  label: undefined,
  placeholder: undefined,
};

export default CityField;
