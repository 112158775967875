import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { DeelContractDoc } from './DeelContract';
import { OrganizationDoc } from './Organization';
import { SkillDoc } from './Skill';
import { UserDoc } from './User';

export enum ExpertTier {
  LIEUTENANT = 'LIEUTENANT',
  COMMANDER = 'COMMANDER',
  CAPTAIN = 'CAPTAIN',
  ADMIRAL = 'ADMIRAL',
}

export enum ExpertStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  ARCHIVED = 'ARCHIVED',
}

export type ExpertDoc = {
  tier: ExpertTier;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  customerUrl?: string;
  createdAt: Timestamp;
  assigneeRefs: DocumentReference<UserDoc>[];
  userRef?: DocumentReference<UserDoc>;
  organizationRef: DocumentReference<OrganizationDoc>;
  deelContractRef?: DocumentReference<DeelContractDoc>;
  skillRefs: DocumentReference<SkillDoc>[];
  rating: number;
  status: ExpertStatus;
  _v: 3;
};

export const isExpertDoc = (
  data: DocumentData,
): data is ExpertDoc => true;

export const isExpertRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ExpertDoc> => ref.parent.id === 'experts';

export const isExpertSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
ExpertDoc
> => isExpertRef(snap.ref);

export const getExpertsCollectionRef = (firestore: Firestore): CollectionReference<ExpertDoc> => collection(firestore, 'experts') as CollectionReference<ExpertDoc>;
