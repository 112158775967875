import * as Automerge from '@automerge/automerge';
import {
  Box,
  Grid,
  HStack,
  Select,
} from '@chakra-ui/react';
import Editor, { Monaco } from '@monaco-editor/react';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  doc,
  runTransaction,
} from 'firebase/firestore';
import { editor as monacoEditor } from 'monaco-editor';
import React, {
  ChangeEvent,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestore } from 'reactfire';
import { LocalDataTrack, RemoteDataTrack, RemoteParticipant } from 'twilio-video';
import base64ToUint8Array from '../helpers/base64ToUint8Array';
import uint8ArrayToBase64 from '../helpers/uint8ArrayToBase64';
import useIntervieweeParticipant from '../hooks/useIntervieweeParticipant';
import useInterviewerParticipant from '../hooks/useInterviewerParticipant';
import useInterviewRole, { InterviewRole } from '../hooks/useInterviewRole';
import useParticipantDataTrack from '../hooks/useParticipantDataTrack';
import useParticipants from '../hooks/useParticipants';
import CheckIcon from '../icons/CheckIcon';
import Spinner from '../icons/Spinner';
import UploadIcon from '../icons/UploadIcon';
import { InterviewScheduleItemDoc } from '../types/InterviewScheduleItem';
import Catch from './Catch';
import { useLocalData } from './LocalDataProvider';
import { useRoom } from './RoomProvider';

enum MessageType {
  CODE_EDITOR_UPDATE = 'CODE_EDITOR_UPDATE',
  CODE_EDITOR_CURSOR_POSITION_UPDATE = 'CODE_EDITOR_CURSOR_POSITION_UPDATE',
  CODE_EDITOR_CURSOR_SELECTION_UPDATE = 'CODE_EDITOR_CURSOR_SELECTION_UPDATE',
}

type CodeEditorUpdateMessage = {
  type: MessageType.CODE_EDITOR_UPDATE;
  value: string | undefined;
  event: monacoEditor.IModelContentChangedEvent;
};

type CodeEditorCursorPositionUpdateMessage = {
  type: MessageType.CODE_EDITOR_CURSOR_POSITION_UPDATE;
  event: monacoEditor.ICursorPositionChangedEvent;
};

type CodeEditorCursorSelectionUpdateMessage = {
  type: MessageType.CODE_EDITOR_CURSOR_SELECTION_UPDATE;
  event: monacoEditor.ICursorSelectionChangedEvent;
};

type DataMessage =
  | CodeEditorUpdateMessage
  | CodeEditorCursorPositionUpdateMessage
  | CodeEditorCursorSelectionUpdateMessage;

export type Props = {
  scheduleItemSnap: QueryDocumentSnapshot<InterviewScheduleItemDoc>;
};

const CallCodeEditorMain: React.FC<Props> = ({ scheduleItemSnap }) => {
  const firestore = useFirestore();
  const scheduleItemRef = useMemo(
    () => doc(firestore, scheduleItemSnap.ref.path) as DocumentReference<InterviewScheduleItemDoc>,
    [firestore, scheduleItemSnap.ref.path],
  );

  const { track: localDataTrack } = useLocalData();

  const { room } = useRoom();
  const participants = useParticipants(room);
  const intervieweeParticipant = useIntervieweeParticipant(participants);
  const interviewerParticipant = useInterviewerParticipant(participants);
  const {
    track: intervieweeParticipantDataTrack,
  } = useParticipantDataTrack(intervieweeParticipant);
  const {
    track: interviewerParticipantDataTrack,
  } = useParticipantDataTrack(interviewerParticipant);

  const [editor, setEditor] = useState<monacoEditor.IStandaloneCodeEditor | null>(null);
  const [monaco, setMonaco] = useState<Monaco | null>(null);

  const role = useInterviewRole();

  const localCodeEditorTrack = useMemo(
    () => {
      let prefix = 'visitor';
      switch (role) {
        case InterviewRole.INTERVIEWEE: { prefix = 'interviewee'; break; }
        case InterviewRole.INTERVIEWER: { prefix = 'interviewer'; break; }
      }

      return new LocalDataTrack({
        name: `${prefix}-code-editor-${scheduleItemRef.id}`,
      });
    },
    [scheduleItemRef.id, role],
  );

  useEffect(
    () => {
      if (room) {
        room.localParticipant.publishTrack(localCodeEditorTrack);

        return () => {
          room.localParticipant.unpublishTrack(localCodeEditorTrack);
        };
      }

      return () => { };
    },
    [localCodeEditorTrack, room],
  );

  const [automergeDoc, setAutomergeDoc] = useState(
    () => {
      try {
        const codeEditorState = base64ToUint8Array(scheduleItemSnap.data()?.codeEditorState || '');
        return Automerge.load<{ value: Automerge.Text, language: string }>(codeEditorState);
      } catch (err) {
        return Automerge.change<{ value: Automerge.Text, language: string }>(
          Automerge.init('0000'),
          { time: 0 },
          (nextAutomergeDoc) => {
            // eslint-disable-next-line no-param-reassign
            nextAutomergeDoc.value = new Automerge.Text('');
            // eslint-disable-next-line no-param-reassign
            nextAutomergeDoc.language = 'plaintext';
          },
        );
      }
    },
  );

  const {
    track: intervieweeCodeEditorTrack,
  } = useParticipantDataTrack(intervieweeParticipant, `code-editor-${scheduleItemRef.id}`);
  const {
    track: interviewerCodeEditorTrack,
  } = useParticipantDataTrack(interviewerParticipant, `code-editor-${scheduleItemRef.id}`);

  const onCodeEditorIncomingMessage = useCallback(
    (v: Uint8Array) => {
      const incomingAutomergeDoc = Automerge.load<{ value: Automerge.Text, language: string }>(
        new Uint8Array(v),
      );

      const newAutomergeDoc = Automerge.merge(automergeDoc, incomingAutomergeDoc);

      setAutomergeDoc(newAutomergeDoc);
    },
    [automergeDoc],
  );

  useEffect(
    () => {
      if (intervieweeCodeEditorTrack) {
        intervieweeCodeEditorTrack.on('message', onCodeEditorIncomingMessage);

        return () => {
          intervieweeCodeEditorTrack.off('message', onCodeEditorIncomingMessage);
        };
      }

      return () => { };
    },
    [intervieweeCodeEditorTrack, onCodeEditorIncomingMessage],
  );

  useEffect(
    () => {
      if (interviewerCodeEditorTrack) {
        interviewerCodeEditorTrack.on('message', onCodeEditorIncomingMessage);

        return () => {
          interviewerCodeEditorTrack.off('message', onCodeEditorIncomingMessage);
        };
      }

      return () => { };
    },
    [interviewerCodeEditorTrack, onCodeEditorIncomingMessage],
  );

  const [changed, setChanged] = useState(false);

  const setValue = useCallback(
    (val: string | undefined, ev: monacoEditor.IModelContentChangedEvent) => {
      const newAutomergeDoc = Automerge.change(
        automergeDoc,
        { time: Date.now() },
        (nextAutomergeDoc) => {
          ev.changes.forEach(({ rangeOffset, rangeLength, text }) => {
            if (rangeLength) {
              nextAutomergeDoc.value.deleteAt(rangeOffset, rangeLength);
            }

            if (text.length) {
              nextAutomergeDoc.value.insertAt(rangeOffset, ...Array.from(text));
            }
          });
        },
      );

      setAutomergeDoc(newAutomergeDoc);

      localCodeEditorTrack.send(Automerge.save(newAutomergeDoc));
    },
    [automergeDoc, localCodeEditorTrack],
  );

  const value = useMemo(
    () => automergeDoc.value.toString(),
    [automergeDoc],
  );

  const setLanguage = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const newAutomergeDoc = Automerge.change(
        automergeDoc,
        (nextAutomergeDoc) => {
          // eslint-disable-next-line no-param-reassign
          nextAutomergeDoc.language = e.target.value;
        },
      );

      setAutomergeDoc(newAutomergeDoc);

      localCodeEditorTrack.send(Automerge.save(newAutomergeDoc));
    },
    [automergeDoc, localCodeEditorTrack],
  );

  const language = useMemo(
    () => automergeDoc.language,
    [automergeDoc],
  );

  const handleCodeEditorUpdate = useCallback(
    (e: CodeEditorUpdateMessage) => {
      // console.log('in', e.value, e.event);
      const model = editor?.getModel();
      if (model && model.getVersionId() < e.event.versionId) {
        editor?.getModel()?.applyEdits(e.event.changes);
      }
    },
    [editor],
  );

  const [
    intervieweeCursorPosition,
    setIntervieweeCursorPosition,
  ] = useState<monacoEditor.IEditorDecorationsCollection | null>(null);

  const [
    interviewerCursorPosition,
    setInterviewerCursorPosition,
  ] = useState<monacoEditor.IEditorDecorationsCollection | null>(null);

  const [
    intervieweeCursorSelection,
    setIntervieweeCursorSelection,
  ] = useState<monacoEditor.IEditorDecorationsCollection | null>(null);

  const [
    interviewerCursorSelection,
    setInterviewerCursorSelection,
  ] = useState<monacoEditor.IEditorDecorationsCollection | null>(null);

  const handleCodeEditorCursorPositionUpdate = useCallback(
    (e: CodeEditorCursorPositionUpdateMessage, track: RemoteDataTrack) => {
      // console.log('CODE_EDITOR_CURSOR_POSITION_UPDATE', e.event);

      if (editor && monaco) {
        switch (track.name) {
          case 'interviewee-data': {
            if (intervieweeCursorPosition) {
              intervieweeCursorPosition.set([
                {
                  range: new monaco.Range(
                    e.event.position.lineNumber,
                    e.event.position.column,
                    e.event.position.lineNumber,
                    e.event.position.column,
                  ),
                  options: {
                    afterContentClassName: 'intervieweeCodeEditorCursorPosition',
                    hoverMessage: { value: 'Interviewee' },
                  },
                },
              ]);
            }
            break;
          }
          case 'interviewer-data': {
            if (interviewerCursorPosition) {
              interviewerCursorPosition.set([
                {
                  range: new monaco.Range(
                    e.event.position.lineNumber,
                    e.event.position.column,
                    e.event.position.lineNumber,
                    e.event.position.column,
                  ),
                  options: {
                    afterContentClassName: 'interviewerCodeEditorCursorPosition',
                    hoverMessage: { value: 'Interviewer' },
                  },
                },
              ]);
            }
            break;
          }
        }
      }
    },
    [editor, monaco, intervieweeCursorPosition, interviewerCursorPosition],
  );

  const handleCodeEditorCursorSelectionUpdate = useCallback(
    (e: CodeEditorCursorSelectionUpdateMessage, track: RemoteDataTrack) => {
      // console.log('CODE_EDITOR_CURSOR_SELECTION_UPDATE', e.event);

      if (editor && monaco) {
        switch (track.name) {
          case 'interviewee-data': {
            if (intervieweeCursorSelection) {
              intervieweeCursorSelection.set([
                {
                  range: new monaco.Range(
                    e.event.selection.selectionStartLineNumber,
                    e.event.selection.selectionStartColumn,
                    e.event.selection.positionLineNumber,
                    e.event.selection.positionColumn,
                  ),
                  options: {
                    inlineClassName: 'intervieweeCodeEditorCursorSelection',
                    hoverMessage: { value: 'Interviewee' },
                  },
                },
              ]);
            }
            break;
          }
          case 'interviewer-data': {
            if (interviewerCursorSelection) {
              interviewerCursorSelection.set([
                {
                  range: new monaco.Range(
                    e.event.selection.selectionStartLineNumber,
                    e.event.selection.selectionStartColumn,
                    e.event.selection.positionLineNumber,
                    e.event.selection.positionColumn,
                  ),
                  options: {
                    inlineClassName: 'interviewerCodeEditorCursorSelection',
                    hoverMessage: { value: 'Interviewer' },
                  },
                },
              ]);
            }
            break;
          }
        }
      }
    },
    [editor, intervieweeCursorSelection, interviewerCursorSelection, monaco],
  );

  const processMessage = useCallback(
    (e: DataMessage, track: RemoteDataTrack, participant: RemoteParticipant) => {
      switch (e.type) {
        case MessageType.CODE_EDITOR_UPDATE: {
          handleCodeEditorUpdate(e);
          break;
        }
        case MessageType.CODE_EDITOR_CURSOR_POSITION_UPDATE: {
          handleCodeEditorCursorPositionUpdate(e, track);
          break;
        }
        case MessageType.CODE_EDITOR_CURSOR_SELECTION_UPDATE: {
          handleCodeEditorCursorSelectionUpdate(e, track);
          break;
        }
      }
    },
    [
      handleCodeEditorCursorPositionUpdate,
      handleCodeEditorCursorSelectionUpdate,
      handleCodeEditorUpdate,
    ],
  );

  useEffect(
    () => {
      if (intervieweeParticipant && intervieweeParticipantDataTrack) {
        const message = (v: string | ArrayBuffer) => {
          if (typeof v === 'string') {
            const e = JSON.parse(v) as DataMessage;

            processMessage(e, intervieweeParticipantDataTrack, intervieweeParticipant);
          }
        };

        // console.log('add interviewee track');
        intervieweeParticipantDataTrack.on('message', message);

        return () => {
          // console.log('remove interviewee track');
          intervieweeParticipantDataTrack.off('message', message);
        };
      }

      return () => { };
    },
    [
      intervieweeParticipant,
      intervieweeParticipantDataTrack,
      processMessage,
    ],
  );

  useEffect(
    () => {
      if (interviewerParticipant && interviewerParticipantDataTrack) {
        const message = (v: string | ArrayBuffer) => {
          if (typeof v === 'string') {
            const e = JSON.parse(v) as DataMessage;

            processMessage(e, interviewerParticipantDataTrack, interviewerParticipant);
          }
        };

        // console.log('add interviewer track');
        interviewerParticipantDataTrack.on('message', message);

        return () => {
          // console.log('remove interviewer track');
          interviewerParticipantDataTrack.off('message', message);
        };
      }

      return () => { };
    },
    [
      interviewerParticipant,
      interviewerParticipantDataTrack,
      processMessage,
    ],
  );

  const handleMount = useCallback(
    (e: monacoEditor.IStandaloneCodeEditor, m: Monaco) => {
      setEditor(e);
      setMonaco(m);

      setIntervieweeCursorPosition(e.createDecorationsCollection());
      setInterviewerCursorPosition(e.createDecorationsCollection());

      setIntervieweeCursorSelection(e.createDecorationsCollection());
      setInterviewerCursorSelection(e.createDecorationsCollection());

      e.onDidChangeCursorPosition((ev) => {
        localDataTrack?.send(JSON.stringify({
          type: MessageType.CODE_EDITOR_CURSOR_POSITION_UPDATE,
          event: ev,
        } as DataMessage));
      });

      e.onDidChangeCursorSelection((ev) => {
        localDataTrack?.send(JSON.stringify({
          type: MessageType.CODE_EDITOR_CURSOR_SELECTION_UPDATE,
          event: ev,
        } as DataMessage));
      });
    },
    [localDataTrack],
  );

  const [saving, setSaving] = useState(false);
  useEffect(
    () => {
      setChanged(true);

      const t = setTimeout(
        async () => {
          await runTransaction(firestore, async (tr) => {
            setSaving(true);

            const snap = await tr.get(scheduleItemRef);

            const oldAutomergeDoc = Automerge.load<{ value: Automerge.Text, language: string }>(
              base64ToUint8Array(snap.data()?.codeEditorState || ''),
            );

            const newAutomergeDoc = Automerge.merge(
              oldAutomergeDoc,
              Automerge.clone(automergeDoc),
            );

            const codeEditorState = uint8ArrayToBase64(Automerge.save(newAutomergeDoc));

            tr.set(scheduleItemRef, {
              codeEditorState,
            }, { merge: true });
          });

          setSaving(false);
          setChanged(false);
        },
        1000,
      );

      return () => {
        clearTimeout(t);
      };
    },
    [automergeDoc, firestore, scheduleItemRef],
  );

  return (
    <Grid templateRows="auto 1fr" h="100%" w="100%" rowGap={3}>
      <HStack>
        <Select size="sm" onChange={setLanguage} value={language}>
          <option value="plaintext">Plain Text</option>
          <option value="abap">ABAP</option>
          <option value="apex">Apex</option>
          <option value="azcli">Azure CLI</option>
          <option value="bat">Batch</option>
          <option value="bicep">Bicep</option>
          <option value="cameligo">Cameligo</option>
          <option value="clojure">Clojure</option>
          <option value="coffeescript">CoffeeScript</option>
          <option value="c">C</option>
          <option value="cpp">C++</option>
          <option value="csharp">C#</option>
          <option value="csp">CSP</option>
          <option value="css">CSS</option>
          <option value="cypher">Cypher</option>
          <option value="dart">Dart</option>
          <option value="dockerfile">Dockerfile</option>
          <option value="ecl">ECL</option>
          <option value="elixir">Elixir</option>
          <option value="flow9">Flow9</option>
          <option value="fsharp">F#</option>
          <option value="freemarker2">FreeMarker2</option>
          <option value="go">Go</option>
          <option value="graphql">GraphQL</option>
          <option value="handlebars">Handlebars</option>
          <option value="html">HTML</option>
          <option value="ini">Ini</option>
          <option value="pug">Jade</option>
          <option value="java">Java</option>
          <option value="javascript">JavaScript</option>
          <option value="json">JSON</option>
          <option value="julia">Julia</option>
          <option value="kotlin">Kotlin</option>
          <option value="less">Less</option>
          <option value="lexon">Lexon</option>
          <option value="lua">Lua</option>
          <option value="liquid">Liquid</option>
          <option value="m3">Modula-3</option>
          <option value="markdown">Markdown</option>
          <option value="mips">MIPS</option>
          <option value="msdax">MSDAX</option>
          <option value="mysql">MySQL</option>
          <option value="objective-c">Objective-C</option>
          <option value="pascal">Pascal</option>
          <option value="pascaligo">Pascaligo</option>
          <option value="perl">Perl</option>
          <option value="pgsql">PostgreSQL</option>
          <option value="php">PHP</option>
          <option value="pla">PLA</option>
          <option value="postiats">Postiats</option>
          <option value="powerquery">Power Query</option>
          <option value="powershell">PowerShell</option>
          <option value="proto">Protocol Buffers</option>
          <option value="python">Python</option>
          <option value="qsharp">Q#</option>
          <option value="r">R</option>
          <option value="razor">Razor</option>
          <option value="redis">redis</option>
          <option value="redshift">Redshift</option>
          <option value="restructuredtext">reStructuredText</option>
          <option value="ruby">Ruby</option>
          <option value="rust">Rust</option>
          <option value="sb">Small Basic</option>
          <option value="scala">Scala / Dotty</option>
          <option value="scheme">Scheme</option>
          <option value="scss">Sass</option>
          <option value="shell">Shell</option>
          <option value="sol">Solidity</option>
          <option value="aes">Sophia</option>
          <option value="sparql">SPARQL</option>
          <option value="sql">SQL</option>
          <option value="st">StructuredText</option>
          <option value="swift">Swift</option>
          <option value="systemverilog">SystemVerilog</option>
          <option value="tcl">Tcl/Tk</option>
          <option value="hcl">Terraform</option>
          <option value="twig">Twig</option>
          <option value="typescript">TypeScript</option>
          <option value="verilog">Verilog</option>
          <option value="vb">Visual Basic</option>
          <option value="xml">XML</option>
          <option value="yaml">YAML</option>
        </Select>

        <Box py={2} px={1} h={9}>
          {
            // eslint-disable-next-line no-nested-ternary
            saving
              ? (<Spinner h={5} w={5} display="block" color="cf.cntPrimary" />)
              : (
                changed
                  ? (<UploadIcon h={5} w={5} display="block" color="cf.cntPrimary" />)
                  : (<CheckIcon h={5} w={5} display="block" color="cf.cntPrimary" />)
              )
          }
        </Box>
      </HStack>

      <Box
        borderColor="cf.brdBlackAlpha12"
        borderRadius="sm"
        overflow="hidden"
        borderWidth={1}
      >
        <Editor
          width="100%"
          value={value}
          language={language}
          options={{
            minimap: { enabled: false },
            readOnly: role !== InterviewRole.INTERVIEWEE && role !== InterviewRole.INTERVIEWER,
          }}
          onChange={setValue}
          path="./index.ts"
          loading={<Spinner />}
          onMount={handleMount}
        />
      </Box>
    </Grid>
  );
};

const CallCodeEditorCatchFallback: React.FC = () => null;
const CallCodeEditorSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallCodeEditor: React.FC<Props> = (props) => (
  <Catch fallback={<CallCodeEditorCatchFallback />}>
    <Suspense fallback={<CallCodeEditorSuspenseFallback />}>
      <CallCodeEditorMain {...props} />
    </Suspense>
  </Catch>
);

export default CallCodeEditor;
