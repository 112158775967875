import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  collection,
} from 'firebase/firestore';
import { UserDoc } from './User';

export type GoogleCredentialsDoc = {
  email: string;
  userRef: DocumentReference<UserDoc>;
  credentials: unknown;
};

export const isGoogleCredentialsDoc = (
  doc: DocumentData,
): doc is GoogleCredentialsDoc => true;

export const isGoogleCredentialsRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<GoogleCredentialsDoc> => ref.parent.id === 'googleCredentials';

export const isGoogleCredentialsSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<GoogleCredentialsDoc> => isGoogleCredentialsRef(snap.ref);

export const getGoogleCredentialsCollectionRef = (firestore: Firestore): CollectionReference<GoogleCredentialsDoc> => collection(firestore, 'googleCredentials') as CollectionReference<GoogleCredentialsDoc>;
