import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useFirestore } from 'reactfire';
import ExpertRefProvider from '../../components/ExpertRefContext';
import { getExpertsCollectionRef } from '../../types/Expert';
import EditExpertScreen from './EditExpertScreen';

export type Params = {
  expertId: string;
  organizationId: string;
};

const OrganizationExpertEditPage: React.FC = () => {
  const { expertId } = useParams<Params>();
  const firestore = useFirestore();
  const expertRef = useMemo(
    () => doc(getExpertsCollectionRef(firestore), expertId),
    [expertId, firestore],
  );

  return (
    <ExpertRefProvider expertRef={expertRef} key={expertRef.path}>
      <EditExpertScreen />
    </ExpertRefProvider>
  );
};

export default OrganizationExpertEditPage;
