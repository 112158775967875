import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useAuth, useFirestore, useUser } from 'reactfire';
import CalendarIcon from '../../icons/CalendarIcon';
import LogOutIcon from '../../icons/LogOutIcon';
import MessageIcon from '../../icons/MessageIcon';
import SettingsIcon from '../../icons/SettingsIcon';
import { getUsersCollectionRef } from '../../types/User';
import Catch from '../Catch';
import UserAvatar from '../UserAvatar';

const NavUserMain: React.FC = () => {
  const { data: user } = useUser();
  const firestore = useFirestore();
  const userRef = useMemo(
    () => doc(getUsersCollectionRef(firestore), user?.uid),
    [firestore, user?.uid],
  );

  const navigate = useNavigate();

  const { shutdown, showNewMessage } = useIntercom();
  const auth = useAuth();

  const handleInterviewsClick = useCallback(() => {
    navigate('/interviews');
  }, [navigate]);

  const handleSettingsClick = useCallback(async () => {
    navigate('/settings');
  }, [navigate]);

  const handleLogoutClick = useCallback(async () => {
    await auth.signOut();
    shutdown();
    window.location.reload();
  }, [auth, shutdown]);

  const handleHelpClick = useCallback(() => {
    showNewMessage('Hi guys, I need your help!');
  }, [showNewMessage]);

  return (
    <Menu>
      <MenuButton>
        <UserAvatar
          size="sm"
          userRef={userRef}
          cursor="pointer"
          userSelect="none"
        />
      </MenuButton>

      <Portal>
        <MenuList>
          <MenuItem
            icon={<CalendarIcon />}
            onClick={handleInterviewsClick}
          >
            Interviews
          </MenuItem>

          <MenuItem
            icon={<SettingsIcon />}
            onClick={handleSettingsClick}
          >
            Settings
          </MenuItem>

          <MenuItem
            icon={<MessageIcon />}
            onClick={handleHelpClick}
          >
            Help and support
          </MenuItem>

          <MenuItem
            icon={<LogOutIcon />}
            onClick={handleLogoutClick}
          >
            Log out
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};

const NavUserCatchFallback: React.FC = () => null;
const NavUserSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const NavUser: React.FC = () => (
  <Catch fallback={<NavUserCatchFallback />}>
    <Suspense fallback={<NavUserSuspenseFallback />}>
      <NavUserMain />
    </Suspense>
  </Catch>
);

export default NavUser;
