import { useToast } from '@chakra-ui/react';
import React, {
  PropsWithChildren,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc, useFunctions } from 'reactfire';
import { Room, TwilioError, connect as twilioConnect } from 'twilio-video';
import { CancelablePromise } from 'twilio-video/tsdef/types';
import useSound from 'use-sound';
import useInterviewGetTwilioToken from '../functions/useInterviewGetTwilioToken';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';
import { useLocalCamera } from './LocalCameraProvider';
import { useLocalData } from './LocalDataProvider';
import { useLocalMicrophone } from './LocalMicrophoneProvider';

export type Result = {
  room: Room | null;
  connect: () => void;
  disconnect: () => void;
  isConnecting: boolean;
};

export const RoomContext = React.createContext<Result | undefined>(undefined);

export const useRoom = (): Result => {
  const res = useContext(RoomContext);
  if (!res) {
    throw new Error('useRoom needs to be wrapped with RoomProvider');
  }
  return res;
};

const RoomProviderMain: React.FC<PropsWithChildren> = ({ children }) => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const [playJoinSound] = useSound(
    'https://storage.googleapis.com/crewfilter.io/sounds/music_glass_lo_yes.mp3',
    { volume: 0.25 },
  );
  const [playLeaveSound] = useSound(
    'https://storage.googleapis.com/crewfilter.io/sounds/music_glass_lo_no.mp3',
    { volume: 0.25 },
  );

  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);

  // ***********
  // Local Audio
  // ***********

  const { track: localMicrophoneTrack } = useLocalMicrophone();

  useEffect(() => {
    if (localMicrophoneTrack && room) {
      room.localParticipant.publishTrack(localMicrophoneTrack);

      return () => {
        room.localParticipant.unpublishTrack(localMicrophoneTrack);
      };
    }

    return () => { };
  }, [localMicrophoneTrack, room]);

  // ***********
  // Local Video
  // ***********

  const { track: localCameraTrack } = useLocalCamera();

  useEffect(() => {
    if (localCameraTrack && room) {
      room.localParticipant.publishTrack(localCameraTrack);

      return () => {
        room.localParticipant.unpublishTrack(localCameraTrack);
      };
    }

    return () => { };
  }, [localCameraTrack, room]);

  // ***********
  // Local Data
  // ***********

  const { track: localDataTrack } = useLocalData();

  useEffect(() => {
    if (localDataTrack && room) {
      room.localParticipant.publishTrack(localDataTrack);

      return () => {
        room.localParticipant.unpublishTrack(localDataTrack);
      };
    }

    return () => { };
  }, [localDataTrack, room]);

  // ****************
  // Connection Logic
  // ****************

  const functions = useFunctions();
  const getCallTwilioToken = useInterviewGetTwilioToken(functions);

  const toast = useToast();

  const [roomPromise, setRoomPromise] = useState<CancelablePromise<Room> | null>(null);
  const connect = useCallback(
    async () => {
      if (room) {
        return;
      }

      setIsConnecting(true);

      try {
        const { data: token } = await getCallTwilioToken({ interviewId: interviewRef.id });

        const newRoomPromise = twilioConnect(
          token,
          {
            tracks: [],
            name: interviewRef.id,
            region: interview.region,
            bandwidthProfile: {
              video: {
                mode: 'grid',
              },
            },
            maxAudioBitrate: 16000,
            networkQuality: { local: 1, remote: 1 },
          },
        );

        setRoomPromise(newRoomPromise);

        const newRoom = await newRoomPromise;

        playJoinSound();
        setRoom(newRoom);
      } catch (err) {
        toast({
          status: 'error',
          title: 'Failed to join the interview',
          description: err instanceof Error ? err.message : undefined,
        });

        setRoom(null);
      } finally {
        setIsConnecting(false);
      }
    },
    [
      getCallTwilioToken,
      interview.region,
      interviewRef.id,
      playJoinSound,
      room,
      toast,
    ],
  );

  const disconnect = useCallback(
    () => {
      try {
        if (roomPromise) {
          roomPromise.cancel();
        }

        if (room) {
          playLeaveSound();
          room.disconnect();
          setRoom(null);
        }
      } catch (err) {
        toast({
          status: 'error',
          title: 'Failed to leave the interview',
          description: err instanceof Error ? err.message : undefined,
        });
      }
    },
    [
      playLeaveSound,
      room,
      roomPromise,
      toast,
    ],
  );

  useEffect(
    () => {
      if (room) {
        const disconnected = (r: Room, e?: TwilioError) => {
          playLeaveSound();
          setRoom(null);

          if (e) {
            toast({
              status: 'error',
              title: 'Disconnected from the interview',
              description: e.message,
            });
          }
        };

        room.on('disconnected', disconnected);

        return () => {
          room.off('disconnected', disconnected);
        };
      }

      return () => { };
    },
    [
      playLeaveSound,
      room,
      toast,
    ],
  );

  const res = useMemo<Result>(() => ({
    room,
    connect,
    disconnect,
    isConnecting,
  }), [
    room,
    connect,
    disconnect,
    isConnecting,
  ]);

  return (
    <RoomContext.Provider value={res}>
      {children}
    </RoomContext.Provider>
  );
};

const RoomProviderCatchFallback: React.FC = () => null;
const RoomProviderSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const RoomProvider: React.FC<PropsWithChildren> = (props) => (
  <Catch fallback={<RoomProviderCatchFallback />}>
    <Suspense fallback={<RoomProviderSuspenseFallback />}>
      <RoomProviderMain {...props} />
    </Suspense>
  </Catch>
);

export default RoomProvider;
