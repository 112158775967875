import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import { useUserRef } from '../../components/UserRefContext';
import { getUserScheduleDocRef } from '../../types/UserSchedule';

export type CheckUserScheduleProps = {
  fallback: React.ReactElement;
};

const CheckUserScheduleLayoutMain: React.FC<CheckUserScheduleProps> = ({ fallback }) => {
  const userRef = useUserRef();
  const { data: userScheduleSnap } = useFirestoreDoc(getUserScheduleDocRef(userRef));

  if (!userScheduleSnap.exists()) {
    return fallback;
  }

  return (
    <Outlet />
  );
};

export const CheckUserScheduleLayoutCatchFallback: React.FC = () => null;
export const CheckUserScheduleLayoutSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CheckUserScheduleLayout: React.FC<CheckUserScheduleProps> = (props) => (
  <Catch fallback={<CheckUserScheduleLayoutCatchFallback />}>
    <Suspense fallback={<CheckUserScheduleLayoutSuspenseFallback />}>
      <CheckUserScheduleLayoutMain {...props} />
    </Suspense>
  </Catch>
);

export default CheckUserScheduleLayout;
