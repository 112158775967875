import * as Automerge from '@automerge/automerge';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { InterviewDoc, isInterviewRef } from './Interview';
import { QuestionDoc } from './Question';
import { SkillDoc } from './Skill';

export type CodeEditorDoc = {
  value: Automerge.Text;
  language: string;
};

export enum InterviewScheduleItemStatus {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  ENDED = 'ENDED',
}

export type InterviewScheduleItemDoc = {
  codeEditorState: string;
  questionRef: DocumentReference<QuestionDoc>;
  skillRef: DocumentReference<SkillDoc>;
  startsAt: Timestamp;
  endsAt: Timestamp;
  status: InterviewScheduleItemStatus;
  startedAt?: Timestamp;
  endedAt?: Timestamp;
  score?: number;
  comments?: string;
} & (
  | {
    status: InterviewScheduleItemStatus.CREATED;
  }
  | {
    status: InterviewScheduleItemStatus.STARTED;
    startedAt: Timestamp;
  }
  | {
    status: InterviewScheduleItemStatus.ENDED;
    startedAt: Timestamp;
    endedAt: Timestamp;
    score: number;
    comments: string;
  }
);

export const isInterviewScheduleItemDoc = (
  doc?: DocumentData,
): doc is InterviewScheduleItemDoc => true;

export const isInterviewScheduleItemRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewScheduleItemDoc> => ref.parent.id === 'scheduleItems' && ref.parent.parent !== null && isInterviewRef(ref.parent.parent);

function isInterviewScheduleItemSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
InterviewScheduleItemDoc
>;
function isInterviewScheduleItemSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
InterviewScheduleItemDoc
>;
function isInterviewScheduleItemSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
InterviewScheduleItemDoc
> | QueryDocumentSnapshot<
InterviewScheduleItemDoc
> {
  return isInterviewScheduleItemRef(snap.ref);
}

export { isInterviewScheduleItemSnap as isInterviewSnap };

export const getInterviewScheduleItemsCollectionRef = (interviewRef: DocumentReference<InterviewDoc>): CollectionReference<InterviewScheduleItemDoc> => collection(interviewRef, 'scheduleItems') as CollectionReference<InterviewScheduleItemDoc>;
