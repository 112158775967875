import {
  AspectRatio,
  Box,
  Center,
  SkeletonCircle,
} from '@chakra-ui/react';
import { DocumentReference, query, where } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { getScoresCollectionRef } from '../../types/Score';
import { SkillDoc } from '../../types/Skill';
import Catch from '../Catch';
import { useInterviewRef } from '../InterviewRefContext';
import ScoreSpiderChart from '../ScoreSpiderChart';

const ScoreChartItemMain: React.FC = () => {
  const interviewRef = useInterviewRef();

  const firestore = useFirestore();

  const { data: scoresSnap } = useFirestoreCollection(
    query(
      getScoresCollectionRef(firestore),
      where('interviewRef', '==', interviewRef),
    ),
  );

  const items: Array<{ skillRef: DocumentReference<SkillDoc>; score?: number; }> = useMemo(
    () => scoresSnap.docs.map(
      (scoreSnap) => ({
        skillRef: scoreSnap.data().skillRef,
        score: scoreSnap.data().score,
      }),
    ),
    [scoresSnap.docs],
  );

  if (items.length <= 2) {
    return null;
  }

  return (
    <Center>
      <AspectRatio
        borderRadius="100%"
        background="cf.bgPrimary"
        boxShadow="inset 0px 0px 0px 1px var(--chakra-colors-cf-brdBlackAlpha12)"
        h="100%"
        w="100%"
        maxH="360px"
        maxW="360px"
        minH="100px"
        minW="100px"
        ratio={1}
      >
        <Box p={1}>
          <ScoreSpiderChart
            items={items}
            h="100%"
            w="100%"
          />
        </Box>
      </AspectRatio>
    </Center>
  );
};

export const ScoreChartItemSuspenseFallback: React.FC = () => (
  <Center
    minH={0}
    alignItems="stretch"
    h="100%"
    w="100%"
    px={5}
    py={3}
  >
    <AspectRatio maxW="360px" minW="100px" height="100%" width="100%" ratio={1}>
      <SkeletonCircle />
    </AspectRatio>
  </Center>
);

export const ScoreChartItemCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ScoreChartItem: React.FC = () => (
  <Catch fallback={<ScoreChartItemCatchFallback />}>
    <Suspense fallback={<ScoreChartItemSuspenseFallback />}>
      <ScoreChartItemMain />
    </Suspense>
  </Catch>
);

export default ScoreChartItem;
