import _ from 'lodash';
import { useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { RemoteParticipant } from 'twilio-video';
import { useInterviewRef } from '../components/InterviewRefContext';
import SnapNotFoundError from '../types/SnapshotNotFoundError';

export default function useIntervieweeParticipant(
  participants: RemoteParticipant[],
): RemoteParticipant | null {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const intervieweeIdentity = useMemo<string | undefined>(
    () => interviewSnap.data().intervieweeRef.id,
    [interviewSnap],
  );

  return useMemo<RemoteParticipant | null>(
    () => (
      intervieweeIdentity
        ? _.find(participants, { identity: intervieweeIdentity }) || null
        : null
    ),
    [participants, intervieweeIdentity],
  );
}
