import { Box, Center, Text } from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import useIntervieweeParticipant from '../hooks/useIntervieweeParticipant';
import useParticipantCameraTrack from '../hooks/useParticipantCameraTrack';
import useParticipantMicrophoneTrack from '../hooks/useParticipantMicrophoneTrack';
import useParticipants from '../hooks/useParticipants';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Audio from './Audio';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';
import LocalVideoPlaceholder from './LocalVideoPlaceholder';
import { useRoom } from './RoomProvider';
import Video from './Video';

const IntervieweeParticipantMediaMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: intervieweeSnap } = useFirestoreDoc(interview.intervieweeRef);

  if (!intervieweeSnap.exists()) {
    throw new SnapNotFoundError(intervieweeSnap);
  }

  const interviewee = useMemo(() => intervieweeSnap.data(), [intervieweeSnap]);

  const { room } = useRoom();

  const participants = useParticipants(room);

  const intervieweeParticipant = useIntervieweeParticipant(participants);

  const {
    track: intervieweeCameraTrack,
    enabled: intervieweeCameraTrackEnabled,
  } = useParticipantCameraTrack(intervieweeParticipant);

  const {
    track: intervieweeMicrophoneTrack,
  } = useParticipantMicrophoneTrack(intervieweeParticipant);

  return (
    <Box
      h={180}
      w={320}
      borderRadius="md"
      overflow="hidden"
      bg="cf.black"
    >
      {intervieweeCameraTrack && intervieweeCameraTrackEnabled ? (
        <Box h="100%" w="100%">
          <Video videoTrack={intervieweeCameraTrack} />
        </Box>
      ) : (
        <Center h="100%" w="100%">
          <LocalVideoPlaceholder userRef={interview.intervieweeRef} />
        </Center>
      )}

      <Box
        position="relative"
        left={3}
        bottom={8}
        display="inline-block"
        bg="rgba(2, 23, 41, 0.6)"
        borderRadius="sm"
        px={1}
        py="2px"
      >
        <Text fontSize="xs" lineHeight="shorter" color="cf.cntOnColor" userSelect="none">
          {interviewee.firstName}
        </Text>
      </Box>

      {intervieweeMicrophoneTrack ? (
        <Audio audioTrack={intervieweeMicrophoneTrack} />
      ) : null}
    </Box>
  );
};

const IntervieweeParticipantMediaCatchFallback: React.FC = () => null;
const IntervieweeParticipantMediaSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const IntervieweeParticipantMedia: React.FC = () => (
  <Catch fallback={<IntervieweeParticipantMediaCatchFallback />}>
    <Suspense fallback={<IntervieweeParticipantMediaSuspenseFallback />}>
      <IntervieweeParticipantMediaMain />
    </Suspense>
  </Catch>
);

export default IntervieweeParticipantMedia;
