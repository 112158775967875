import { Button } from '@chakra-ui/react';
import _ from 'lodash';
import React, { Suspense, useCallback, useState } from 'react';
import { useFunctions, useRemoteConfigString } from 'reactfire';
import useGoogleCalendarAdd from '../../functions/useGoogleCalendarAdd';
import GoogleIcon from '../../icons/GoogleIcon';
import Spinner from '../../icons/Spinner';
import Catch from '../Catch';

const SyncGoogleCalendarButtonMain: React.FC = () => {
  const { data: clientId } = useRemoteConfigString('gapi_clientId');

  const functions = useFunctions();
  const userAddCalendar = useGoogleCalendarAdd(functions);

  const getCode = useCallback(
    async (): Promise<{ code: string }> => {
      let resolve: (data: { code: string }) => void;
      let reject: (data: unknown) => void;
      const promise = new Promise<{ code: string }>((res, rej) => {
        resolve = res;
        reject = rej;
      });

      const client = google.accounts.oauth2.initCodeClient({
        client_id: clientId,
        scope: [
          'https://www.googleapis.com/auth/userinfo.email',
          'https://www.googleapis.com/auth/calendar.events.readonly',
          'https://www.googleapis.com/auth/calendar.freebusy',
          'https://www.googleapis.com/auth/calendar.readonly',
        ].join(' '),
        select_account: true,
        callback: (response) => {
          if (_.isObject(response) && _.has(response, 'code')) {
            resolve(response as { code: string });
          } else {
            reject(response);
          }
        },
      });
      client.requestCode();

      return promise;
    },
    [clientId],
  );

  const [requestingPermissions, setRequestingPermissions] = useState(false);
  const [addingCalendar, setAddingCalendar] = useState(false);
  const handleClick = useCallback(async () => {
    try {
      setRequestingPermissions(true);
      const { code } = await getCode();
      setRequestingPermissions(false);

      setAddingCalendar(true);
      await userAddCalendar({ code, redirectUri: window.location.origin });
      setAddingCalendar(false);
    } catch (err) {
      /* do nothing */
    } finally {
      setRequestingPermissions(false);
      setAddingCalendar(false);
    }
  }, [getCode, userAddCalendar]);

  return (
    <Button
      variant="outline"
      onClick={handleClick}
      spinner={<Spinner />}
      loadingText={requestingPermissions ? 'Requesting access' : 'Adding calendar'}
      isLoading={requestingPermissions || addingCalendar}
      leftIcon={<GoogleIcon />}
    >
      Sync with Google Calendar
    </Button>
  );
};

export const SyncGoogleCalendarButtonCatchFallback: React.FC = () => null;
export const SyncGoogleCalendarButtonSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SyncGoogleCalendarButton: React.FC = () => (
  <Catch fallback={<SyncGoogleCalendarButtonCatchFallback />}>
    <Suspense fallback={<SyncGoogleCalendarButtonSuspenseFallback />}>
      <SyncGoogleCalendarButtonMain />
    </Suspense>
  </Catch>
);

export default SyncGoogleCalendarButton;
