import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { OrganizationDoc } from './Organization';
import { TagDoc } from './Tag';

export type QuestionDoc = {
  title: string;
  content: string;
  hint: string;
  codeEditor: {
    value: string;
    language: string;
  };
  duration: number;
  answerHint5: string;
  answerHint4: string;
  answerHint3: string;
  answerHint2: string;
  answerHint1: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  revision: number;
  tagRefs: DocumentReference<TagDoc>[];
  organizationRef: DocumentReference<OrganizationDoc>;
};

export const isQuestionDoc = (
  doc: DocumentData,
): doc is QuestionDoc => true;

export const isQuestionRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<QuestionDoc> => ref.parent.id === 'questions';

export const isQuestionSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<QuestionDoc> => isQuestionRef(snap.ref);

export const getQuestionsCollectionRef = (firestore: Firestore): CollectionReference<QuestionDoc> => collection(firestore, 'questions') as CollectionReference<QuestionDoc>;
