import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  HStack,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useFunctions } from 'reactfire';
import useStripeOrganizationSubscriptionCancel from '../functions/useStripeOrganizationSubscriptionCancel';
import useStripeOrganizationSubscriptionChange from '../functions/useStripeOrganizationSubscriptionChange';
import usePlatformTier from '../hooks/usePlatformTier';
import CheckIcon from '../icons/CheckIcon';
import MissionMoonIcon from '../icons/MissionMoonIcon';
import Spinner from '../icons/Spinner';
import { OrganizationPlatformTier } from '../types/OrganizationCustomerProfile';
import { useOrganizationRef } from './OrganizationRefContext';

const MissionMoonCard: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const handleSubscribeClick = useCallback(
    () => {
      const url = new URL(`${window.location.origin}/organizations/${organizationRef.id}/subscribe-start?tier=MOON`);
      url.searchParams.set('tier', OrganizationPlatformTier.MOON);
      window.open(url.toString());
    },
    [organizationRef.id],
  );

  const functions = useFunctions();
  const change = useStripeOrganizationSubscriptionChange(functions);
  const cancel = useStripeOrganizationSubscriptionCancel(functions);
  const [isLoading, setLoading] = useState(false);

  const handleChangeClick = useCallback(
    async () => {
      try {
        setLoading(true);
        await change({
          organizationId: organizationRef.id,
          tier: OrganizationPlatformTier.MOON,
        });
      } finally {
        setLoading(false);
      }
    },
    [change, organizationRef.id],
  );

  const handleCancelClick = useCallback(
    async () => {
      try {
        setLoading(true);
        await cancel({
          organizationId: organizationRef.id,
        });
      } finally {
        setLoading(false);
      }
    },
    [cancel, organizationRef.id],
  );

  const formatCurrencyLong = useCallback(
    (v: number) => new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(v),
    [],
  );

  const tier = usePlatformTier();

  return (
    <Card
      boxShadow={
        tier === OrganizationPlatformTier.MOON
          ? 'rgb(53, 150, 81) 0px 0px 0px 2px inset'
          : 'rgba(2, 23, 41, 0.12) 0px 0px 0px 1px inset'
      }
    >
      {tier === OrganizationPlatformTier.MOON ? (
        <Center
          position="absolute"
          top={-3}
          left={0}
          right={0}
        >
          <Badge
            variant="solid"
            colorScheme="green"
            shadow="md"
            bg="#359651"
          >
            ACTIVE
          </Badge>
        </Center>
      ) : null}

      <CardBody>
        <VStack alignItems="stretch" spacing={3}>
          <HStack alignItems="start" spacing={3}>
            <MissionMoonIcon boxSize={16} />

            <VStack alignItems="stretch" spacing={0}>
              <Text fontWeight="medium" lineHeight={6} fontSize="lg">
                Mission &ldquo;Moon&rdquo;
              </Text>

              <HStack alignItems="stretch">
                <Text fontSize="7xl" lineHeight={10}>
                  FREE
                </Text>

                <VStack alignItems="stretch" spacing={0}>
                  <Text variant="labelSmall" py="2px" color="cf.cntTertiary">10 hours</Text>
                </VStack>
              </HStack>
            </VStack>
          </HStack>
          <Text variant="labelSmall" color="cf.cntTertiary">
            Core platform features
          </Text>
          <List spacing={1}>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Unlimited user accounts
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Unlimited candidates
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Availability sync
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Interview auto-scheduler
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              1to1 video interviews
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Collaborative coding
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Whiteboard
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Knowledge base
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Candidate scorecards
            </ListItem>
          </List>
        </VStack>
      </CardBody>
      <CardFooter>
        <VStack alignItems="stretch" spacing={3} width="100%">
          <List spacing={1}>
            <ListItem fontSize="sm" color="cf.cntPrimary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              10 hours on us
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntPrimary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Then
              {' '}
              {formatCurrencyLong(0.05 * 60)}
              {' '}
              per hour
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntPrimary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Pay for interview time only
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntPrimary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              No card details required today
            </ListItem>
          </List>

          {!tier ? (
            <Button
              onClick={handleSubscribeClick}
              variant="solid"
            >
              Subscribe
            </Button>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {tier === OrganizationPlatformTier.MOON ? (
                <Button
                  onClick={handleCancelClick}
                  variant="outline"
                  isLoading={isLoading}
                  spinner={<Spinner />}
                  loadingText="Cancelling..."
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  onClick={handleChangeClick}
                  variant="solid"
                  isLoading={isLoading}
                  spinner={<Spinner />}
                  loadingText="Switching..."
                >
                  Switch
                </Button>
              )}
            </>
          )}
        </VStack>
      </CardFooter>
    </Card>
  );
};

export default MissionMoonCard;
