import { SearchOptions } from '@algolia/client-search';
import { DocumentReference, doc } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { useCandidatesIndex } from '../../../components/AlgoliaOrganizationProvider';
import CandidateRow, {
  CandidateRowCatchFallback,
  CandidateRowSuspenseFallback,
} from '../../../components/CandidateRow';
import Catch from '../../../components/Catch';
import DocumentLoader from '../../../components/DocumentLoader';
import { AlgoliaCandidateRecord } from '../../../types/AlgoliaRecords';
import { CandidateDoc, getCandidatesCollectionRef } from '../../../types/Candidate';

export interface Props {
  query: string;
  searchOptions: SearchOptions;
  visible: boolean;
  page: number;
  hitsPerPage: number;
  onNbPagesChange: (pages: number) => unknown;
}

const CandidateSearchPageMain: React.FC<Props> = ({
  query,
  searchOptions,
  visible,
  page,
  hitsPerPage,
  onNbPagesChange,
}) => {
  const candidatesIndex = useCandidatesIndex();

  const [candidateRefs, setCandidateRefs] = useState<Array<DocumentReference<CandidateDoc>>>();

  const firestore = useFirestore();

  useEffect(() => {
    if (!candidatesIndex || !visible) {
      return;
    }

    candidatesIndex.search<AlgoliaCandidateRecord>(query, { ...searchOptions, hitsPerPage, page })
      .then((res) => {
        setCandidateRefs(res.hits.map(
          (hit) => doc(getCandidatesCollectionRef(firestore), hit.objectID),
        ));
        onNbPagesChange(res.nbPages);
      })
      .catch(() => {
        /* do nothing */
      });
  }, [
    candidatesIndex,
    firestore,
    hitsPerPage,
    onNbPagesChange,
    page,
    query,
    searchOptions,
    visible,
  ]);

  if (!candidatesIndex || !candidateRefs) {
    return (
      <CandidateSearchPageSuspenseFallback size={hitsPerPage} />
    );
  }

  return (
    <>
      {candidateRefs.map((candidateRef) => (
        <Catch key={candidateRef.path} fallback={<CandidateRowCatchFallback />}>
          <Suspense fallback={<CandidateRowSuspenseFallback />}>
            <DocumentLoader reference={candidateRef}>
              {(candidateSnap) => (
                <CandidateRow candidateSnap={candidateSnap} />
              )}
            </DocumentLoader>
          </Suspense>
        </Catch>
      ))}
    </>
  );
};

export const CandidateSearchPageSuspenseFallback: React.FC<{ size: number }> = ({ size }) => (
  <>
    {_.range(size).map((key) => (
      <CandidateRowSuspenseFallback key={key} />
    ))}
  </>
);

export const CandidateSearchPageCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidateSearchPage: React.FC<Props> = (props) => (
  <Catch fallback={<CandidateSearchPageCatchFallback />}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    <Suspense fallback={<CandidateSearchPageSuspenseFallback size={props.hitsPerPage} />}>
      <CandidateSearchPageMain {...props} />
    </Suspense>
  </Catch>
);

export default CandidateSearchPage;
