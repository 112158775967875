import {
  CircularProgress,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot, orderBy, query } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import DocumentLoader from '../../components/DocumentLoader';
import { useInterviewRef } from '../../components/InterviewRefContext';
import {
  InterviewScheduleItemDoc,
  getInterviewScheduleItemsCollectionRef,
} from '../../types/InterviewScheduleItem';
import { usePlayback } from './PlaybackScaleProvider';

const ScoreMain: React.FC = () => {
  const interviewRef = useInterviewRef();

  const { playbackTime, interviewToPlaybackTime } = usePlayback();

  const { data: scheduleItemsSnap } = useFirestoreCollection(
    query(
      getInterviewScheduleItemsCollectionRef(interviewRef),
      orderBy('startedAt', 'asc'),
    ),
  );

  const currentQuestionIndex = useMemo<number | undefined>(
    () => {
      const i = _.findIndex(
        scheduleItemsSnap.docs,
        (scheduleItemSnap) => {
          const { startedAt, endedAt } = scheduleItemSnap.data();
          return (
            interviewToPlaybackTime(startedAt?.toMillis() || Infinity) <= playbackTime
          && playbackTime < interviewToPlaybackTime(endedAt?.toMillis() || -Infinity)
          );
        },
      );

      return i >= 0 ? i : undefined;
    },
    [interviewToPlaybackTime, playbackTime, scheduleItemsSnap.docs],
  );

  const currentQuestionSnap = useMemo<QueryDocumentSnapshot<InterviewScheduleItemDoc> | undefined>(
    () => {
      if (currentQuestionIndex === undefined) {
        return undefined;
      }

      return scheduleItemsSnap.docs[currentQuestionIndex];
    },
    [currentQuestionIndex, scheduleItemsSnap.docs],
  );

  const currentQuestion = useMemo(
    () => (currentQuestionSnap ? currentQuestionSnap.data() : undefined),
    [currentQuestionSnap],
  );

  if (currentQuestionIndex === undefined || !currentQuestion) {
    return null;
  }

  return (
    <VStack spacing={3} alignItems="stretch">

      {currentQuestion.score !== undefined ? (
        <HStack spacing={5}>
          <HStack spacing={1} alignItems="center">
            <Text color="cf.cntSecondary" variant="labelMedium">
              {Math.round(currentQuestion.score * 100)}
            </Text>

            <CircularProgress
              value={Math.round(currentQuestion.score * 100)}
              color="cf.cntAccent"
              size={4}
              py={0.5}
            />
          </HStack>

          <DocumentLoader reference={currentQuestion.questionRef}>
            {(questionSnap) => {
              const question = questionSnap.data();

              if (_.isNil(currentQuestion.score) || _.isNil(question)) {
                return null;
              }

              return (
                <Text>
                  {currentQuestion.score <= 0.2 ? question.answerHint1 : null}
                  {currentQuestion.score > 0.2 && currentQuestion.score <= 0.4
                    ? question.answerHint2 : null}
                  {currentQuestion.score > 0.4 && currentQuestion.score <= 0.6
                    ? question.answerHint3 : null}
                  {currentQuestion.score > 0.6 && currentQuestion.score <= 0.8
                    ? question.answerHint4 : null}
                  {currentQuestion.score > 0.8 ? question.answerHint5 : null}
                </Text>
              );
            }}
          </DocumentLoader>
        </HStack>
      ) : null}
    </VStack>
  );
};

export const ScoreSuspenseFallback: React.FC = () => null;
export const ScoreCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Score: React.FC = () => (
  <Catch fallback={<ScoreCatchFallback />}>
    <Suspense fallback={<ScoreSuspenseFallback />}>
      <ScoreMain />
    </Suspense>
  </Catch>
);

export default Score;
