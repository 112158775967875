import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { UserDoc } from './User';

export type AvailabilityExceptionDoc = {
  userRef: DocumentReference<UserDoc>;
  startsAt: Timestamp;
  endsAt: Timestamp;
};

export const isAvailabilityExceptionDoc = (
  doc?: DocumentData,
): doc is AvailabilityExceptionDoc => true;

export const isAvailabilityExceptionRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<AvailabilityExceptionDoc> => ref.parent.id === 'availabilityExceptions';

export const isAvailabilityExceptionSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
AvailabilityExceptionDoc
> => isAvailabilityExceptionRef(snap.ref);

export const getAvailabilityExceptionsCollectionRef = (firestore: Firestore): CollectionReference<AvailabilityExceptionDoc> => collection(firestore, 'availabilityExceptions') as CollectionReference<AvailabilityExceptionDoc>;
