import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { CandidateDoc } from './Candidate';
import { ExpertDoc } from './Expert';
import { InterviewDoc } from './Interview';
import { Language } from './Language';
import { OrganizationDoc } from './Organization';
import { UserDoc } from './User';

export enum ReviewerType {
  CUSTOMER = 'CUSTOMER',
  CANDIDATE = 'CANDIDATE',
}

export enum ReviewAspectType {
  ACCURACY = 'ACCURACY',
  BEHAVIOR = 'BEHAVIOR',
  DEPTH = 'DEPTH',
  LANGUAGE = 'LANGUAGE',
  PUNCTUALITY = 'PUNCTUALITY',
  QUALITY = 'QUALITY',
}

export type ReviewDoc = {
  aspect: ReviewAspectType;
  createdAt: Timestamp;
  interviewRef: DocumentReference<InterviewDoc>;
  customerRef: DocumentReference<OrganizationDoc>;
  candidateRef: DocumentReference<CandidateDoc>;
  vendorRef: DocumentReference<OrganizationDoc>;
  expertRef: DocumentReference<ExpertDoc>;
  reviewer: ReviewerType;
  userRef: DocumentReference<UserDoc>;
  value: number;
  language: Language;
};

export const isReviewDoc = (
  doc: DocumentData,
): doc is ReviewDoc => true;

export const isReviewRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ReviewDoc> => ref.parent.id === 'reviews';

export const isReviewSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ReviewDoc> => isReviewRef(snap.ref);

export const getReviewsCollectionRef = (firestore: Firestore): CollectionReference<ReviewDoc> => collection(firestore, 'reviews') as CollectionReference<ReviewDoc>;
