import {
  Center,
  Container,
  Grid,
  GridItem,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import CallLobbyIntervieweeInfo from '../../../../components/CallLobbyIntervieweeInfo';
import CallLobbyInterviewerInfo from '../../../../components/CallLobbyInterviewerInfo';
import CallLobbySkillsInfo from '../../../../components/CallLobbySkillsInfo';
import CallLobbyTimeInfo from '../../../../components/CallLobbyTimeInfo';
import NavUser from '../../../../components/NavUser';
import BackButton from './BackButton';
import CallAction from './CallAction';

const Lobby: React.FC = () => (
  <Grid
    templateColumns="36px auto"
    columnGap={5}
    rowGap={3}
    height="100%"
    px={5}
    py={3}
  >
    <GridItem>
      <Grid
        gridTemplateColumns="1fr"
        gridTemplateRows="36px 1fr 36px"
        gap={5}
        height="100%"
        width="100%"
        alignItems="center"
        alignContent="center"
      >
        <GridItem>
          <BackButton />
        </GridItem>
        <GridItem />
        <GridItem>
          <NavUser />
        </GridItem>
      </Grid>
    </GridItem>

    <GridItem>
      <Center height="100%">
        <Container maxW="container.sm">
          <VStack alignItems="stretch" spacing={4}>
            <VStack spacing={4} alignItems="stretch">
              <CallLobbyTimeInfo />
              <CallLobbyIntervieweeInfo />
              <CallLobbyInterviewerInfo />
              <CallLobbySkillsInfo />
            </VStack>

            <CallAction />
          </VStack>
        </Container>
      </Center>
    </GridItem>
  </Grid>
);

export default Lobby;
