import { SkeletonCircle } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import Spinner from '../icons/Spinner';
import { ExpertDoc } from '../types/Expert';
import Catch from './Catch';
import { StorageAvatarStatic } from './StorageAvatar';
import UserAvatar, { ProfileAvatarProps } from './UserAvatar';

export type Props = Omit<ProfileAvatarProps, 'userRef'> & {
  expertSnap: QueryDocumentSnapshot<ExpertDoc>;
};

const ExpertAvatarMain: React.FC<Props> = ({
  expertSnap,
  ...profileAvatarProps
}: Props) => {
  const expert = useMemo(() => expertSnap.data(), [expertSnap]);

  if (!expert.userRef) {
    return (
      <StorageAvatarStatic
        icon={<Spinner />}
        color="cf.status.empty"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...profileAvatarProps}
      />
    );
  }

  return (
    <UserAvatar
      userRef={expert.userRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...profileAvatarProps}
    />
  );
};

export const ExpertAvatarSuspenseFallback: React.FC = () => (
  <SkeletonCircle boxSize={11} />
);

export const ExpertAvatarCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ExpertAvatar: React.FC<Props> = (props) => (
  <Catch fallback={<ExpertAvatarCatchFallback />}>
    <Suspense fallback={<ExpertAvatarSuspenseFallback />}>
      <ExpertAvatarMain {...props} />
    </Suspense>
  </Catch>
);

export default ExpertAvatar;
