import { useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useFunctions } from 'reactfire';
import Loader from '../../components/Loader';
import useOrganizationAcceptMemberInvitation from '../../functions/useOrganizationAcceptMemberInvitation';

export type Params = {
  organizationId: string;
  memberInvitationId: string;
};

const OrganizationMemberAcceptInvitationPage: React.FC = () => {
  const { organizationId, memberInvitationId } = useParams<Params>();

  const functions = useFunctions();
  const acceptMemberInvitation = useOrganizationAcceptMemberInvitation(functions);
  const navigate = useNavigate();
  const toast = useToast();
  useEffect(() => {
    if (!organizationId || !memberInvitationId) {
      return;
    }

    acceptMemberInvitation({ organizationId, memberInvitationId })
      .then(() => {
        toast({
          title: 'Invitation accepted',
          status: 'success',
        });
        navigate(`/organizations/${organizationId}`);
      })
      .catch((err) => {
        if (err instanceof Error) {
          toast({
            title: 'Failed to withdraw invitation',
            status: 'error',
            description: err.message,
          });
        }
        navigate('/');
      });
  }, [acceptMemberInvitation, organizationId, memberInvitationId, navigate, toast]);

  return (
    <Loader />
  );
};

export default OrganizationMemberAcceptInvitationPage;
