import {
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { Suspense } from 'react';
import { Navigate } from 'react-router';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import { useUserRef } from '../../components/UserRefContext';
import { getOrganizationsCollectionRef } from '../../types/Organization';

const OrganizationsPageMain: React.FC = () => {
  const userRef = useUserRef();

  const firestore = useFirestore();

  const { data: organizationsSnap } = useFirestoreCollection(
    query(
      getOrganizationsCollectionRef(firestore),
      where('owners', 'array-contains', userRef),
      orderBy('createdAt', 'asc'),
      limit(1),
    ),
  );

  if (organizationsSnap.docs.length > 0) {
    return (
      <Navigate to={organizationsSnap.docs[0].id} replace />
    );
  }

  return (
    <Navigate to="new" replace />
  );
};

export const OrganizationsPageCatchFallback: React.FC = () => null;
export const OrganizationsPageSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const OrganizationsPage: React.FC = () => (
  <Catch fallback={<OrganizationsPageCatchFallback />}>
    <Suspense fallback={<OrganizationsPageSuspenseFallback />}>
      <OrganizationsPageMain />
    </Suspense>
  </Catch>
);

export default OrganizationsPage;
