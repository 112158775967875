import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  Step,
  StepProps,
  Steps,
  useSteps,
} from 'chakra-ui-steps';
import {
  PartialWithFieldValue,
  arrayUnion,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useFirestore, useFirestoreDoc, useUser } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import PaddingBlock from '../../components/PaddingBlock';
import UserRefProvider from '../../components/UserRefContext';
import CheckIcon from '../../icons/CheckIcon';
import FlagIcon from '../../icons/FlagIcon';
import AuthError from '../../types/AuthError';
import { UserDoc, getUsersCollectionRef } from '../../types/User';
import { getUserScheduleDocRef, timeSlots } from '../../types/UserSchedule';
import { UserSensitiveDoc, getUserSensitiveDocRef } from '../../types/UserSensitive';
import AvailabilityForm, { FormFields as AvailabilityFormFields } from './AvailabilityForm';
import ProfileForm, { FormFields as ProfileFormFields } from './ProfileForm';

const UserSignUpPageMain: React.FC = () => {
  const { data: user } = useUser();

  if (!user) {
    throw new AuthError();
  }

  const { trackEvent } = useIntercom();

  const firestore = useFirestore();
  const userRef = useMemo(
    () => doc(getUsersCollectionRef(firestore), user.uid),
    [firestore, user.uid],
  );
  const userSensitiveRef = useMemo(() => getUserSensitiveDocRef(userRef), [userRef]);
  const userScheduleRef = useMemo(() => getUserScheduleDocRef(userRef), [userRef]);

  const { data: userSnap } = useFirestoreDoc(userRef);
  const { data: userSensitiveSnap } = useFirestoreDoc(userSensitiveRef);

  const profileStepProps = useMemo<StepProps>(
    () => ({
      checkIcon: CheckIcon,
      label: 'Profile',
      description: 'Let\'s get to know you',
      isCompletedStep: userSnap.exists() && userSensitiveSnap.exists(),
    }),
    [userSnap, userSensitiveSnap],
  );

  const { data: userAvailabilitySnap } = useFirestoreDoc(userScheduleRef);

  const scheduleStepProps = useMemo<StepProps>(
    () => ({
      checkIcon: CheckIcon,
      label: 'Availability',
      description: 'For interview scheduling',
      isCompletedStep: userAvailabilitySnap.exists(),
    }),
    [userAvailabilitySnap],
  );

  const { nextStep, prevStep, activeStep } = useSteps({ initialStep: 0 });

  const profileFormInitialValues = useMemo<ProfileFormFields>(
    () => {
      const parts = _.compact((user.displayName || '').split(' '));
      const firstName = _.dropRight(parts).join(' ');
      const lastName = _.last(parts) || '';

      const res: ProfileFormFields = {
        firstName,
        lastName,
        avatarRef: null,
        concent: false,
        location: null,
        phoneNumber: '',
      };

      const userData = userSnap.data();

      if (userSnap.exists() && userData) {
        res.firstName = userData.firstName;
        res.lastName = userData.lastName;
        res.avatarRef = userData.avatarRef || null;
        res.concent = true;
      }

      const userSensitiveData = userSensitiveSnap.data();

      if (userSensitiveSnap.exists() && userSensitiveData) {
        res.location = userSensitiveData.location || null;
        res.phoneNumber = userSensitiveData.phoneNumber || '';
      }

      return res;
    },
    [user.displayName, userSnap, userSensitiveSnap],
  );

  const handleProfileFormSubmit = useCallback(async ({
    avatarRef,
    firstName,
    lastName,
    location,
    phoneNumber,
  }: ProfileFormFields) => {
    const userData: PartialWithFieldValue<UserDoc> = {
      firstName,
      lastName,
    };

    if (avatarRef) {
      userData.avatarRef = avatarRef;
    }

    if (!userSnap) {
      userData.createdAt = serverTimestamp();
    }

    await setDoc(userRef, userData, { merge: true });

    if (!location) {
      return;
    }

    const userSensitiveData: PartialWithFieldValue<UserSensitiveDoc> = {
      location,
      phoneNumber,
      languages: arrayUnion('en'),
    };

    await setDoc(userSensitiveRef, userSensitiveData, { merge: true });

    trackEvent('clarwis-user-created', {
      firstName,
      lastName,
    });

    nextStep();
  }, [nextStep, trackEvent, userRef, userSnap, userSensitiveRef]);

  const availabilityFormInitialValues = useMemo<AvailabilityFormFields>(
    () => {
      const userAvailabilityData = userAvailabilitySnap.data();

      if (userSensitiveSnap.exists() && userAvailabilityData) {
        return { schedule: userAvailabilityData };
      }

      return {
        schedule: {
          su: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          mo: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          tu: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          we: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          th: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          fr: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          sa: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
        },
      };
    },
    [userAvailabilitySnap, userSensitiveSnap],
  );

  const handleAvailabilityFormSubmit = useCallback(async ({
    schedule,
  }: AvailabilityFormFields) => {
    await setDoc(userScheduleRef, schedule);
    trackEvent('clarwis-user-availability-created');
    nextStep();
  }, [nextStep, trackEvent, userScheduleRef]);

  const finishStepProps = useMemo<StepProps>(
    () => ({
      icon: FlagIcon,
      checkIcon: FlagIcon,
      label: 'Done',
      description: 'Ready for launch!',
      isCompletedStep: userSnap.exists()
        && userSensitiveSnap.exists()
        && userAvailabilitySnap.exists(),
    }),
    [userAvailabilitySnap, userSensitiveSnap, userSnap],
  );

  const { search } = useLocation();
  const next = useMemo(() => new URLSearchParams(search).get('next'), [search]);

  const navigate = useNavigate();
  const handleDashboardClick = useCallback(
    () => navigate(next || '/interviews'),
    [navigate, next],
  );

  return (
    <UserRefProvider userRef={userRef}>
      <Container maxW="container.sm">
        <VStack alignItems="stretch" spacing={5} py={3}>
          <Box py={1}>
            <Heading>
              Onboarding
            </Heading>
          </Box>

          <Steps
            activeStep={activeStep}
            colorScheme="accent"
            orientation="vertical"
            checkIcon={CheckIcon}
          >
            <Step
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...profileStepProps}
            >
              <ProfileForm
                initialValues={profileFormInitialValues}
                onSubmit={handleProfileFormSubmit}
              />
            </Step>

            <Step
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...scheduleStepProps}
            >
              <AvailabilityForm
                initialValues={availabilityFormInitialValues}
                onSubmit={handleAvailabilityFormSubmit}
                onPrevious={prevStep}
              />
            </Step>

            <Step
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...finishStepProps}
            >
              <PaddingBlock>
                <VStack alignItems="stretch" spacing={3}>
                  <Heading fontSize="xl" textAlign="center">
                    You are all set!
                  </Heading>

                  <Text textAlign="center">
                    You can now start scheduling interviews and prove your skills.
                  </Text>

                  <Button onClick={handleDashboardClick}>
                    Go to your dashboard
                  </Button>
                </VStack>
              </PaddingBlock>
            </Step>
          </Steps>
        </VStack>
      </Container>
    </UserRefProvider>
  );
};

export const UserSignUpPageCatchFallback: React.FC = () => null;
export const UserSignUpPageSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const UserSignUpPage: React.FC = () => (
  <Catch fallback={<UserSignUpPageCatchFallback />}>
    <Suspense fallback={<UserSignUpPageSuspenseFallback />}>
      <UserSignUpPageMain />
    </Suspense>
  </Catch>
);

export default UserSignUpPage;
