import {
  Button,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import BlockList from '../../../components/BlockList';
import BlockListItem from '../../../components/BlockListItem';
import Catch from '../../../components/Catch';
import ExpertInfo, { ExpertInfoSuspenseFallback } from '../../../components/ExpertInfo';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import { useSkillRef } from '../../../components/SkillRefContext';
import { ExpertStatus, getExpertsCollectionRef } from '../../../types/Expert';

const ExpertListMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const skillRef = useSkillRef();

  const firestore = useFirestore();

  const { data: expertsSnap } = useFirestoreCollection(query(
    getExpertsCollectionRef(firestore),
    where('organizationRef', '==', organizationRef),
    where('status', 'in', [ExpertStatus.ACTIVE, ExpertStatus.PAUSED]),
    where('skillRefs', 'array-contains', skillRef),
    orderBy('createdAt', 'desc'),
    limit(5),
  ));

  const navigate = useNavigate();

  if (!expertsSnap.docs.length) {
    return null;
  }

  return (
    <VStack spacing={1} alignItems="stretch" data-intercom-target="Experts">
      <HStack>
        <Text
          pt="1px"
          pb="3px"
          color="cf.cntTertiary"
          fontSize="sm"
          lineHeight="short"
          fontWeight="medium"
          flexGrow={1}
        >
          Experts
          {' '}
          &middot;
          {' '}
          {expertsSnap.docs.length}
        </Text>

        <Button
          variant="link"
          size="sm"
          onClick={() => navigate(`../../experts?skillId=${skillRef.id}`)}
        >
          all
        </Button>
      </HStack>

      <BlockList variant="outline">
        {expertsSnap.docs.map((expertSnap) => (
          <BlockListItem
            key={expertSnap.id}
            data-intercom-target="ExpertRow"
            onClick={() => navigate(`../../experts/${expertSnap.id}`)}
          >
            <ExpertInfo expertSnap={expertSnap} />
          </BlockListItem>
        ))}
      </BlockList>
    </VStack>
  );
};

export const ExpertListSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch" data-intercom-target="Experts">
    <Text
      pt="1px"
      pb="3px"
      color="cf.cntTertiary"
      fontSize="sm"
      lineHeight="short"
      fontWeight="medium"
    >
      Experts
    </Text>

    <BlockList variant="outline">
      <BlockListItem>
        <ExpertInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <ExpertInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <ExpertInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <ExpertInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <ExpertInfoSuspenseFallback />
      </BlockListItem>
    </BlockList>
  </VStack>
);

export const ExpertListCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ExpertList: React.FC = () => (
  <Catch fallback={<ExpertListCatchFallback />}>
    <Suspense fallback={<ExpertListSuspenseFallback />}>
      <ExpertListMain />
    </Suspense>
  </Catch>
);

export default ExpertList;
