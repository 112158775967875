import { useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { useUserRef } from '../components/UserRefContext';
import { getUserTimezoneDocRef } from '../types/UserTimezone';

export default function useTimezone(): string {
  const userRef = useUserRef();
  const { data: userTimezoneSnap } = useFirestoreDoc(getUserTimezoneDocRef(userRef));
  const userTimezone = useMemo(() => userTimezoneSnap.data(), [userTimezoneSnap]);
  return useMemo(() => userTimezone?.timezone || 'UTC', [userTimezone]);
}
