import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { UserDoc, isUserRef } from './User';

export const timeSlots = [
  '0000',
  '0015',
  '0030',
  '0045',
  '0100',
  '0115',
  '0130',
  '0145',
  '0200',
  '0215',
  '0230',
  '0245',
  '0300',
  '0315',
  '0330',
  '0345',
  '0400',
  '0415',
  '0430',
  '0445',
  '0500',
  '0515',
  '0530',
  '0545',
  '0600',
  '0615',
  '0630',
  '0645',
  '0700',
  '0715',
  '0730',
  '0745',
  '0800',
  '0815',
  '0830',
  '0845',
  '0900',
  '0915',
  '0930',
  '0945',
  '1000',
  '1015',
  '1030',
  '1045',
  '1100',
  '1115',
  '1130',
  '1145',
  '1200',
  '1215',
  '1230',
  '1245',
  '1300',
  '1315',
  '1330',
  '1345',
  '1400',
  '1415',
  '1430',
  '1445',
  '1500',
  '1515',
  '1530',
  '1545',
  '1600',
  '1615',
  '1630',
  '1645',
  '1700',
  '1715',
  '1730',
  '1745',
  '1800',
  '1815',
  '1830',
  '1845',
  '1900',
  '1915',
  '1930',
  '1945',
  '2000',
  '2015',
  '2030',
  '2045',
  '2100',
  '2115',
  '2130',
  '2145',
  '2200',
  '2215',
  '2230',
  '2245',
  '2300',
  '2315',
  '2330',
  '2345',
] as const;

export type TimeSlot = typeof timeSlots[number];

export type UserScheduleDoc = {
  su: TimeSlot[];
  mo: TimeSlot[];
  tu: TimeSlot[];
  we: TimeSlot[];
  th: TimeSlot[];
  fr: TimeSlot[];
  sa: TimeSlot[];
};

export const isUserScheduleDoc = (
  data: DocumentData,
): data is UserScheduleDoc => true;

export const isUserScheduleRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<UserScheduleDoc> => ref.id === 'schedule' && ref.parent.id === 'private' && ref.parent.parent !== null && isUserRef(ref.parent.parent);

export const isUserScheduleSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<UserScheduleDoc> => isUserScheduleRef(snap.ref);

export const getUserScheduleDocRef = (userRef: DocumentReference<UserDoc>): DocumentReference<UserScheduleDoc> => doc(collection(userRef, 'private'), 'schedule') as DocumentReference<UserScheduleDoc>;
