import _ from 'lodash';
import { useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { RemoteParticipant } from 'twilio-video';
import { useInterviewRef } from '../components/InterviewRefContext';
import SnapNotFoundError from '../types/SnapshotNotFoundError';

export default function useGhostParticipants(
  participants: RemoteParticipant[],
): RemoteParticipant[] {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const intervieweeIdentity = useMemo<string | undefined>(
    () => interviewSnap.data().intervieweeRef.id,
    [interviewSnap],
  );

  const interviewerIdentity = useMemo<string | undefined>(
    () => interviewSnap.data().interviewerRef.id,
    [interviewSnap],
  );

  return useMemo<RemoteParticipant[]>(
    () => _.reject(
      participants,
      ({ identity }) => _.includes(_.compact([intervieweeIdentity, interviewerIdentity]), identity),
    ) || null,
    [intervieweeIdentity, interviewerIdentity, participants],
  );
}
