import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { InterviewDoc, isInterviewRef } from './Interview';

export enum InterviewTabStateTab {
  QUESTION = 'QUESTION',
  CODE_EDITOR = 'CODE_EDITOR',
  WHITEBOARD = 'WHITEBOARD',
}

export type InterviewTabStateDoc = {
  tab: InterviewTabStateTab;
  timestamp: Timestamp;
};

export const isInterviewTabStateDoc = (
  doc?: DocumentData,
): doc is InterviewTabStateDoc => true;

export const isInterviewTabStateRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewTabStateDoc> => ref.parent.id === 'tabStates' && ref.parent.parent !== null && isInterviewRef(ref.parent.parent);

export const isInterviewTabStateSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
InterviewTabStateDoc
> => isInterviewTabStateRef(snap.ref);

export const getInterviewTabStatesCollectionRef = (interviewRef: DocumentReference<InterviewDoc>): CollectionReference<InterviewTabStateDoc> => collection(interviewRef, 'tabStates') as CollectionReference<InterviewTabStateDoc>;
