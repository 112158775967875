import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import SkillRefProvider from '../../components/SkillRefContext';
import { getSkillsCollectionRef } from '../../types/Skill';
import SkillDetailsScreen from './SkillDetailsScreen';

export type Params = {
  skillId: string;
  organizationId: string;
};

const OrganizationSkillDetailsPage: React.FC = () => {
  const { skillId } = useParams<Params>();
  const firestore = useFirestore();
  const skillRef = useMemo(
    () => doc(getSkillsCollectionRef(firestore), skillId),
    [skillId, firestore],
  );

  return (
    <SkillRefProvider skillRef={skillRef} key={skillRef.path}>
      <SkillDetailsScreen />
    </SkillRefProvider>
  );
};

export default OrganizationSkillDetailsPage;
