import {
  Box,
  Button,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirestore, useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import PlayIcon from '../../icons/PlayIcon';
import Spinner from '../../icons/Spinner';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import {
  TwilioCompositionStatus,
  getTwilioCompositionsCollectionRef,
} from '../../types/TwilioComposition';
import Catch from '../Catch';
import { useInterviewRef } from '../InterviewRefContext';
import PaddingBlock from '../PaddingBlock';
import Composition from './Composition';

export const InterviewDetailsCompositionsMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const navigate = useNavigate();

  const handlePlaybackClick = useCallback(async () => {
    navigate(`/organizations/${interview.customerRef.id}/interviews/${interviewRef.id}/playback`);
  }, [navigate, interview.customerRef.id, interviewRef.id]);

  const firestore = useFirestore();
  const { data: twilioCompositionsSnap } = useFirestoreCollection(
    query(
      getTwilioCompositionsCollectionRef(firestore),
      where('interviewRef', '==', interviewSnap.ref),
      orderBy('start'),
    ),
  );

  const allCompositionsUploaded = useMemo<boolean>(
    () => _.every(
      twilioCompositionsSnap.docs,
      (twilioCompositionSnap) => twilioCompositionSnap
        .data()?.status === TwilioCompositionStatus.UPLOADED,
    ),
    [twilioCompositionsSnap.docs],
  );

  if (!twilioCompositionsSnap.docs.length) {
    return null;
  }

  return (
    <PaddingBlock data-intercom-target="InterviewDetailsCompositions">
      <HStack spacing={3} alignItems="center">
        {!allCompositionsUploaded ? (
          <Box p={0.5} color="cf.cntAccent">
            <Spinner />
          </Box>
        ) : null}

        <VStack spacing={1} alignItems="stretch">
          <Text fontWeight="medium" lineHeight="short">
            Call recording
          </Text>

          <VStack spacing={1} alignItems="stretch">
            {twilioCompositionsSnap.docs.map((twilioCompositionSnap, i) => (
              <Text lineHeight="shorter" fontSize="sm" color="cf.cntSecondary" pt="3px" pb="1px" key={twilioCompositionSnap.id}>
                {twilioCompositionsSnap.docs.length > 1 ? (
                  <>
                    {i + 1}
                    /
                    {twilioCompositionsSnap.docs.length}
                    {' '}
                    &middot;
                    {' '}
                  </>
                ) : null}
                <Composition twilioCompositionSnap={twilioCompositionSnap} />
              </Text>
            ))}
          </VStack>
        </VStack>

        <Spacer />

        {allCompositionsUploaded ? (
          <Button leftIcon={<PlayIcon />} onClick={handlePlaybackClick}>
            Watch
          </Button>
        ) : null}
      </HStack>
    </PaddingBlock>
  );
};

export const InterviewDetailsCompositionsCatchFallback: React.FC = () => null;
export const InterviewDetailsCompositionsSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const InterviewDetailsCompositions: React.FC = () => (
  <Catch fallback={<InterviewDetailsCompositionsCatchFallback />}>
    <Suspense fallback={<InterviewDetailsCompositionsSuspenseFallback />}>
      <InterviewDetailsCompositionsMain />
    </Suspense>
  </Catch>
);

export default InterviewDetailsCompositions;
