import {
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { limit, query, where } from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useFirestore,
  useFirestoreCollection,
  useFirestoreDoc,
  useFunctions,
} from 'reactfire';
import * as yup from 'yup';
import Catch from '../../../../components/Catch';
import { useExpertRef } from '../../../../components/ExpertRefContext';
import { useOrganizationRef } from '../../../../components/OrganizationRefContext';
import PaddingBlock from '../../../../components/PaddingBlock';
import { useUserRef } from '../../../../components/UserRefContext';
import useDeelRefreshContract from '../../../../functions/useDeelRefreshContract';
import useDeelSendContractToExpert from '../../../../functions/useDeelSendContractToExpert';
import useDeelSignContract from '../../../../functions/useDeelSignContract';
import DeelLogo from '../../../../icons/DeelLogo';
import OtherPageIcon from '../../../../icons/OtherPageIcon';
import Spinner from '../../../../icons/Spinner';
import { getDeelAccessTokensCollectionRef } from '../../../../types/DeelAccessToken';
import { DeelContractStatus } from '../../../../types/DeelContract';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

export interface SignatureFormFields {
  signature: string;
}

const DeelContractMain: React.FC = () => {
  const expertRef = useExpertRef();
  const { data: exportSnap } = useFirestoreDoc(expertRef);

  if (!exportSnap.exists()) {
    throw new SnapNotFoundError(exportSnap);
  }

  const expert = useMemo(() => exportSnap.data(), [exportSnap]);

  if (!expert.deelContractRef) {
    throw new Error('No contract');
  }

  const functions = useFunctions();
  const refreshContract = useDeelRefreshContract(functions);
  useEffect(() => {
    if (expert.deelContractRef?.id) {
      refreshContract({
        organizationId: expert.organizationRef.id,
        dellContractId: expert.deelContractRef.id,
      });
    }
  }, [expert.deelContractRef?.id, expert.organizationRef.id, refreshContract]);

  const { data: deelContractSnap } = useFirestoreDoc(expert.deelContractRef);

  if (!deelContractSnap.exists()) {
    throw new SnapNotFoundError(deelContractSnap);
  }

  const deelContract = useMemo(() => deelContractSnap.data(), [deelContractSnap]);

  const signContract = useDeelSignContract(functions);
  const signatureSchema = yup.object().shape({
    signature: yup.string().required(),
  });
  const onSignatureSubmit = useCallback(async ({ signature }: SignatureFormFields) => {
    if (deelContract.status === DeelContractStatus.WAITING_FOR_CLIENT_SIGN) {
      await signContract({
        expertId: expertRef.id,
        signature,
      });
    }
  }, [deelContract.status, expertRef.id, signContract]);

  const [sending, setSending] = useState(false);
  const sendContract = useDeelSendContractToExpert(functions);
  const onSendClick = useCallback(async () => {
    if (deelContract.status === DeelContractStatus.WAITING_FOR_CLIENT_SIGN) {
      setSending(true);
      try {
        await sendContract({
          expertId: expertRef.id,
        });
      } finally {
        setSending(false);
      }
    }
  }, [deelContract.status, expertRef.id, sendContract]);

  const statusLabel = useMemo(() => {
    switch (deelContract.status) {
      case DeelContractStatus.AWAITING_DEPOSIT_PAYMENT: return 'Awaiting deposit payment';
      case DeelContractStatus.CANCELLED: return 'Cancelled';
      case DeelContractStatus.COMPLETED: return 'Completed';
      case DeelContractStatus.IN_PROGRESS: return 'In progress';
      case DeelContractStatus.NEW: return 'New';
      case DeelContractStatus.PROCESSING_PAYMENT: return 'Processing payment';
      case DeelContractStatus.REJECTED: return 'Rejected';
      case DeelContractStatus.UNDER_REVIEW: return 'Under review';
      case DeelContractStatus.USER_CANCELLED: return 'User cancelled';
      case DeelContractStatus.WAITING_FOR_CLIENT_PAYMENT: return 'Waiting for client payment';
      case DeelContractStatus.WAITING_FOR_CLIENT_SIGN: return 'Waiting for client sign';
      case DeelContractStatus.WAITING_FOR_CONTRACTOR_SIGN: return 'Waiting for contractor sign';
      case DeelContractStatus.WAITING_FOR_EMPLOYEE_CONTRACT: return 'Waiting for employee contract';
      case DeelContractStatus.WAITING_FOR_EMPLOYEE_SIGN: return 'Waiting for employee sign';
      case DeelContractStatus.WAITING_FOR_EOR_SIGN: return 'Waiting for eor sign';
      default: return 'Unknown';
    }
  }, [deelContract.status]);

  const userRef = useUserRef();
  const organizationRef = useOrganizationRef();
  const firestore = useFirestore();
  const { data: deelAccessTokensSnap } = useFirestoreCollection(
    query(
      getDeelAccessTokensCollectionRef(firestore),
      where('organizationRef', '==', organizationRef),
      where('userRef', '==', userRef),
      limit(1),
    ),
  );

  if (!deelAccessTokensSnap.docs.length) {
    return null;
  }

  return (
    <VStack spacing={1} alignItems="stretch">
      <DeelLogo height="14px" />

      <PaddingBlock variant="outline">
        <VStack alignItems="stretch" spacing={2}>

          <HStack spacing={2} flexGrow={1}>
            <VStack alignItems="stretch" spacing={1} flexGrow={1}>
              <Text
                fontWeight="semibold"
                lineHeight="short"
                flexGrow={1}
                flexShrink={1}
              >
                {deelContract.title}
              </Text>

              <Text variant="labelSmall" color="cf.cntSecondary">
                {statusLabel}
              </Text>
            </VStack>

            {deelContract.status === DeelContractStatus.WAITING_FOR_CONTRACTOR_SIGN ? (
              <Button
                onClick={onSendClick}
                isLoading={sending}
                loadingText="Sending..."
                spinner={<Spinner />}
              >
                Send to expert
              </Button>
            ) : null}

            <IconButton
              aria-label="Open"
              icon={<OtherPageIcon />}
              variant="outline"
              as="a"
              href={`https://app.deel.com/contract/${expert.deelContractRef.id}/overview`}
              target="_blank"
            />
          </HStack>

          {deelContract.status === DeelContractStatus.WAITING_FOR_CLIENT_SIGN ? (
            <Formik<SignatureFormFields>
              validationSchema={signatureSchema}
              initialValues={{
                signature: '',
              }}
              onSubmit={onSignatureSubmit}
            >
              {({ handleSubmit, isSubmitting, getFieldProps }) => (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      placeholder="John Doe"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getFieldProps({ name: 'signature' })}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        type="submit"
                        h="1.75rem"
                        size="sm"
                        isLoading={isSubmitting}
                        loadingText="Signing..."
                        spinner={<Spinner />}
                      >
                        Sign
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </form>
              )}
            </Formik>
          ) : null}
        </VStack>
      </PaddingBlock>
    </VStack>
  );
};

export const DeelContractCatchFallback: React.FC = () => null;

export const DeelContractSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Skeleton pt="1px" pb="3px" height="20px" width="140px" />
  </VStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const DeelContract: React.FC = () => (
  <Catch fallback={<DeelContractCatchFallback />}>
    <Suspense fallback={<DeelContractSuspenseFallback />}>
      <DeelContractMain />
    </Suspense>
  </Catch>
);

export default DeelContract;
