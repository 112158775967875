import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
} from 'firebase/firestore';
import { OrganizationDoc } from './Organization';
import { SendGridEventsParentDoc } from './SendGridEvent';

export type OrganizationMemberInvitationDoc = {
  accepted: boolean;
  email: string;
  firstName: string;
  lastName: string;
} & SendGridEventsParentDoc;

export const isOrganizationMemberInvitationDoc = (
  doc?: DocumentData,
): doc is OrganizationMemberInvitationDoc => true;

export const isOrganizationMemberInvitationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<OrganizationMemberInvitationDoc> => ref.parent.id === 'memberInvitations';

export const isOrganizationMemberInvitationSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
OrganizationMemberInvitationDoc
> => isOrganizationMemberInvitationRef(snap.ref);

export const getOrganizationMemberInvitationsCollectionRef = (organizationRef: DocumentReference<OrganizationDoc>): CollectionReference<OrganizationMemberInvitationDoc> => collection(organizationRef, 'memberInvitations') as CollectionReference<OrganizationMemberInvitationDoc>;
