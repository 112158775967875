import { Box, Center, Heading } from '@chakra-ui/react';
import { orderBy, query } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import { useInterviewRef } from '../../components/InterviewRefContext';
import { getInterviewScheduleItemsCollectionRef } from '../../types/InterviewScheduleItem';
import {
  InterviewTabStateTab,
  getInterviewTabStatesCollectionRef,
} from '../../types/InterviewTabState';
import PlaybackCodeEditor from './PlaybackCodeEditor';
import PlaybackCurrentQuestion from './PlaybackCurrentQuestion';
import { usePlayback } from './PlaybackScaleProvider';
import PlaybackWhiteboard from './PlaybackWhiteboard';

const PlaybackContentMain: React.FC = () => {
  const interviewRef = useInterviewRef();

  const { playbackTime, interviewToPlaybackTime } = usePlayback();

  const { data: tabStatesSnap } = useFirestoreCollection(
    query(
      getInterviewTabStatesCollectionRef(interviewRef),
      orderBy('timestamp'),
    ),
  );

  const currentState = useMemo(
    () => _.findLast(tabStatesSnap.docs, (snap) => {
      const data = snap.data();
      const timestamp = Math.round(interviewToPlaybackTime(data.timestamp.toMillis()));
      return timestamp < playbackTime;
    }),
    [tabStatesSnap, interviewToPlaybackTime, playbackTime],
  );

  const currentValue = useMemo(
    () => currentState?.data().tab || InterviewTabStateTab.QUESTION,
    [currentState],
  );

  const { data: scheduleItemsSnap } = useFirestoreCollection(
    query(
      getInterviewScheduleItemsCollectionRef(interviewRef),
      orderBy('startedAt', 'asc'),
    ),
  );

  const currentScheduleItemIndex = useMemo(
    () => {
      const i = _.findIndex(
        scheduleItemsSnap.docs,
        (scheduleItemSnap) => {
          const { startedAt, endedAt } = scheduleItemSnap.data();
          return (
            interviewToPlaybackTime(startedAt?.toMillis() || Infinity) <= playbackTime
            && playbackTime < interviewToPlaybackTime(endedAt?.toMillis() || -Infinity)
          );
        },
      );

      return i >= 0 ? i : undefined;
    },
    [interviewToPlaybackTime, playbackTime, scheduleItemsSnap.docs],
  );

  const currentScheduleItemSnap = useMemo(
    () => {
      if (currentScheduleItemIndex === undefined) {
        return undefined;
      }

      return scheduleItemsSnap.docs[currentScheduleItemIndex];
    },
    [currentScheduleItemIndex, scheduleItemsSnap.docs],
  );

  const startedAt = useMemo(
    () => interviewToPlaybackTime(
      _.first(scheduleItemsSnap.docs)?.data()?.startedAt?.toMillis() || Infinity,
    ),
    [interviewToPlaybackTime, scheduleItemsSnap.docs],
  );

  const endedAt = useMemo(
    () => interviewToPlaybackTime(
      _.last(scheduleItemsSnap.docs)?.data()?.endedAt?.toMillis() || Infinity,
    ),
    [interviewToPlaybackTime, scheduleItemsSnap.docs],
  );

  if (playbackTime < startedAt) {
    return (
      <Center h="100%">
        <Heading>
          Starting the interview...
        </Heading>
      </Center>
    );
  }

  if (endedAt < playbackTime) {
    return (
      <Center h="100%">
        <Heading>
          Finishing the interview...
        </Heading>
      </Center>
    );
  }

  return (
    <Box h="100%">
      {
        currentValue === InterviewTabStateTab.QUESTION
          && currentScheduleItemSnap
          && currentScheduleItemIndex
          ? (
            <PlaybackCurrentQuestion
              scheduleItemIndex={currentScheduleItemIndex}
              scheduleItemSnap={currentScheduleItemSnap}
            />
          ) : null
      }

      {currentValue === InterviewTabStateTab.CODE_EDITOR && currentScheduleItemSnap ? (
        <PlaybackCodeEditor scheduleItemSnap={currentScheduleItemSnap} />
      ) : null}

      {currentValue === InterviewTabStateTab.WHITEBOARD ? (
        <PlaybackWhiteboard />
      ) : null}
    </Box>
  );
};

const PlaybackContentCatchFallback: React.FC = () => null;
const PlaybackContentSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const PlaybackContent: React.FC = () => (
  <Catch fallback={<PlaybackContentCatchFallback />}>
    <Suspense fallback={<PlaybackContentSuspenseFallback />}>
      <PlaybackContentMain />
    </Suspense>
  </Catch>
);

export default PlaybackContent;
