import {
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference, WithFieldValue, setDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import * as yup from 'yup';
import Catch from '../../../components/Catch';
import ExpertsSelectorField from '../../../components/ExpertsSelectorField';
import ExternalExpertTiersField from '../../../components/ExternalExpertTiersField';
import InternalExpertTiersField from '../../../components/InternalExpertTiersField';
import Loader from '../../../components/Loader';
import SkillsSelectorField from '../../../components/SkillsSelectorField';
import StageActionsField, { StageActionsFieldValue } from '../../../components/StageActionsField';
import { useStageRef } from '../../../components/StageRefProvider';
import TextField from '../../../components/TextField';
import Spinner from '../../../icons/Spinner';
import { ExpertDoc, ExpertTier } from '../../../types/Expert';
import { SkillDoc } from '../../../types/Skill';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import { StageActionType, StageDoc } from '../../../types/Stage';
import Header from './Header';

export interface FormFields {
  name: string;
  skillRefs: DocumentReference<SkillDoc>[];
  expertRefs: DocumentReference<ExpertDoc>[];
  internalExpertTiers: ExpertTier[];
  externalExpertTiers: ExpertTier[];
  actions: StageActionsFieldValue;
}

const schema = yup.object().shape({
  name: yup.string().label('Name').required(),
  skillRefs: yup.array().label('Skills').required(),
  expertRefs: yup.array().label('Experts').required(),
  internalExpertTiers: yup.array(yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ])).required(),
  externalExpertTiers: yup.array(yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ])).required(),
  actions: yup.array(
    yup.object().shape({
      filter: yup.mixed().required(),
      stageRef: yup.mixed().required(),
      type: yup.string().oneOf([StageActionType.TRANSITION]).required(),
    }).label('Action'),
  ).label('Actions').required(),
});

const EditStageScreenMain: React.FC = () => {
  const stageRef = useStageRef();

  const { data: stageSnap } = useFirestoreDoc<StageDoc>(stageRef);

  if (!stageSnap.exists()) {
    throw new SnapNotFoundError(stageSnap);
  }

  const stage = useMemo(() => stageSnap.data(), [stageSnap]);

  const navigate = useNavigate();

  const handleFormSubmit = useCallback(async ({
    name,
    skillRefs,
    expertRefs,
    internalExpertTiers,
    externalExpertTiers,
    actions,
  }: FormFields) => {
    const requestData: Partial<WithFieldValue<StageDoc>> = {
      name,
      internalExpertTiers,
      externalExpertTiers,
      skillRefs,
      expertRefs,
      actions: actions as any,
    };

    await setDoc(
      stageRef,
      requestData,
      { merge: true },
    );

    navigate(`../${stageRef.id}`);
  }, [stageRef, navigate]);

  const handleCancelClick = useCallback(() => {
    navigate(`../${stageRef.id}`);
  }, [stageRef.id, navigate]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        name: stage.name || '',
        skillRefs: stage.skillRefs || [],
        expertRefs: stage.expertRefs || [],
        internalExpertTiers: stage.internalExpertTiers || [],
        externalExpertTiers: stage.externalExpertTiers || [],
        actions: stage.actions || [],
      }}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
      }) => (
        <form
          style={{ height: '100%' }}
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <Grid
            templateColumns="auto"
            templateRows="auto 1fr auto"
            height="100%"
          >
            <GridItem borderBottomWidth={1} borderColor="cf.brdBlackAlpha12">
              <Header />
            </GridItem>

            <GridItem py={3} overflow="auto">
              <Container>
                <VStack alignItems="stretch" spacing={3}>
                  <TextField isRequired label="Name" name="name" placeholder="NodeJS Full Stack - Stage 1" />

                  <SkillsSelectorField
                    name="skillRefs"
                    label="Skills"
                    isRequired
                  />

                  <HStack spacing={5}>
                    <InternalExpertTiersField
                      label="Expert ranks"
                      name="internalExpertTiers"
                    />

                    <ExternalExpertTiersField
                      label="Clarwis Expert ranks"
                      name="externalExpertTiers"
                    />
                  </HStack>

                  <ExpertsSelectorField
                    name="expertRefs"
                    label="Specific experts"
                  />

                  <StageActionsField
                    name="actions"
                    label="Actions"
                  />
                </VStack>
              </Container>
            </GridItem>

            <GridItem py={4} borderTopWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <SimpleGrid columns={2} gap={4}>
                  <Button
                    variant="outline"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="solid"
                    type="submit"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                    spinner={<Spinner />}
                    loadingText="Saving..."
                  >
                    Save
                  </Button>
                </SimpleGrid>
              </Container>
            </GridItem>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export const EditStageScreenCatchFallback: React.FC = () => null;
export const EditStageScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const EditStageScreen: React.FC = () => (
  <Catch fallback={<EditStageScreenCatchFallback />}>
    <Suspense fallback={<EditStageScreenSuspenseFallback />}>
      <EditStageScreenMain />
    </Suspense>
  </Catch>
);

export default EditStageScreen;
