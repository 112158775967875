import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import Loader from '../../components/Loader';
import useDeelGetAccessToken from '../../functions/useDeelGetAccessToken';

const OrganizationDeelRedirectPage: React.FC = () => {
  const { search } = useLocation();
  const { state, code } = useMemo(() => {
    const query = new URLSearchParams(search);
    return {
      state: query.get('state'),
      code: query.get('code'),
    };
  }, [search]);

  const functions = useFunctions();
  const getAccessToken = useDeelGetAccessToken(functions);

  useEffect(
    () => {
      if (state && code) {
        getAccessToken({
          state,
          code,
        }).then((res) => {
          window.opener?.postMessage({ state: 'success' }, '*');
          // close the popup
          window.close();
        }, (err) => {
          window.opener?.postMessage({ state: 'failure' }, '*');
          // close the popup
          window.close();
        });
      } else {
        window.opener?.postMessage({ state: 'failure' }, '*');
        // close the popup
        window.close();
      }
    },
    [code, getAccessToken, state],
  );

  return (
    <Loader />
  );
};

export default OrganizationDeelRedirectPage;
