import {
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import BlockList from '../../../components/BlockList';
import Catch from '../../../components/Catch';
import DocumentLoader from '../../../components/DocumentLoader';
import ExpertRow, {
  ExpertRowCatchFallback,
  ExpertRowSuspenseFallback,
} from '../../../components/ExpertRow';
import { useInterviewRef } from '../../../components/InterviewRefContext';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import PaddingBlock from '../../../components/PaddingBlock';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

const InterviewDetailsInterviewerMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const organizationRef = useOrganizationRef();
  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  if (!interview.expertRef) {
    throw new Error('Interview has no expert');
  }

  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(`/organizations/${organizationRef.id}/experts/${interview.expertRef?.id}`);
  }, [organizationRef.id, interview.expertRef.id, navigate]);

  if (interview.vendorRef.path !== organizationRef.path) {
    return null;
  }

  return (
    <VStack spacing={1} alignItems="stretch">
      <Text pt="1px" pb="3px" color="cf.cntTertiary" fontSize="sm" lineHeight="short" fontWeight="medium">
        Expert
      </Text>

      <BlockList variant="outline">
        <Catch key="0" fallback={<ExpertRowCatchFallback />}>
          <Suspense fallback={<ExpertRowSuspenseFallback />}>
            <DocumentLoader reference={interview.expertRef}>
              {(expertSnap) => (
                <ExpertRow
                  expertSnap={expertSnap}
                  onClick={handleClick}
                  key={expertSnap.ref.path}
                />
              )}
            </DocumentLoader>
          </Suspense>
        </Catch>
      </BlockList>
    </VStack>
  );
};

export const InterviewDetailsInterviewerCatchFallback: React.FC = () => null;

export const InterviewDetailsInterviewerSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Skeleton pt="1px" pb="3px" height="20px" width="140px" />

    <PaddingBlock>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <HStack spacing={3}>
          <SkeletonCircle size="44px" />

          <VStack spacing={1} alignItems="stretch">
            <Skeleton h="20px" w="140px" />
            <Skeleton h="20px" w="160px" />
          </VStack>
        </HStack>
      </Flex>
    </PaddingBlock>
  </VStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewDetailsInterviewer: React.FC = () => (
  <Catch fallback={<InterviewDetailsInterviewerCatchFallback />}>
    <Suspense fallback={<InterviewDetailsInterviewerSuspenseFallback />}>
      <InterviewDetailsInterviewerMain />
    </Suspense>
  </Catch>
);

export default InterviewDetailsInterviewer;
