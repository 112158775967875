import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { OrganizationDoc } from './Organization';
import { TagDoc } from './Tag';

export type AssetDoc = {
  title: string;
  description: string;
  ref: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  revision: number;
  tagRefs: DocumentReference<TagDoc>[];
  organizationRef: DocumentReference<OrganizationDoc>;
};

export const isAssetDoc = (
  doc: DocumentData,
): doc is AssetDoc => true;

export const isAssetRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<AssetDoc> => ref.parent.id === 'assets';

export const isAssetSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<AssetDoc> => isAssetRef(snap.ref);

export const getAssetsCollectionRef = (firestore: Firestore): CollectionReference<AssetDoc> => collection(firestore, 'assets') as CollectionReference<AssetDoc>;
