import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  collection,
} from 'firebase/firestore';
import { OrganizationDoc } from './Organization';

export type DeelTeamDoc = {
  organizationRef: DocumentReference<OrganizationDoc>;
  name: string;
};

export const isDeelTeamDoc = (
  doc?: DocumentData,
): doc is DeelTeamDoc => true;

export const isDeelTeamRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<DeelTeamDoc> => ref.parent.id === 'deelTeams';

export const isDeelTeamSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<DeelTeamDoc> => isDeelTeamRef(snap.ref);

export const getDeelTeamsCollectionRef = (firestore: Firestore): CollectionReference<DeelTeamDoc> => collection(firestore, 'deelTeams') as CollectionReference<DeelTeamDoc>;
