import {
  QueryDocumentSnapshot,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { ReactNode, Suspense } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import Catch from '../components/Catch';
import Loader from '../components/Loader';
import { useUserRef } from '../components/UserRefContext';
import { OrganizationDoc, getOrganizationsCollectionRef } from '../types/Organization';

export type Props = {
  children: (snapshot: QueryDocumentSnapshot<OrganizationDoc> | null) => ReactNode;
};

const LoadOrganizationMain: React.FC<Props> = ({ children }) => {
  const firestore = useFirestore();
  const userRef = useUserRef();
  const { data: organizationsSnap } = useFirestoreCollection(
    query(
      getOrganizationsCollectionRef(firestore),
      where('owners', 'array-contains', userRef),
      orderBy('createdAt', 'asc'),
      limit(1),
    ),
  );

  if (organizationsSnap.docs.length) {
    return (
      <>
        {children(organizationsSnap.docs[0])}
      </>
    );
  }

  return (
    <>
      {children(null)}
    </>
  );
};

export const LoadOrganizationCatchFallback: React.FC = () => null;
export const LoadOrganizationSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const LoadOrganization: React.FC<Props> = (props) => (
  <Catch fallback={<LoadOrganizationCatchFallback />}>
    <Suspense fallback={<LoadOrganizationSuspenseFallback />}>
      <LoadOrganizationMain {...props} />
    </Suspense>
  </Catch>
);

export default LoadOrganization;
