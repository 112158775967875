import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { CandidateDoc } from './Candidate';
import { ExpertDoc } from './Expert';
import { InterviewDoc } from './Interview';
import { OrganizationDoc } from './Organization';
import { TwilioRoomDoc } from './TwilioRoom';
import { UserDoc } from './User';

export enum TwilioCompositionStatus {
  ENQUEUED = 'ENQUEUED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
}

export type TwilioCompositionDoc = {
  interviewRef: DocumentReference<InterviewDoc>;
  candidateRef: DocumentReference<CandidateDoc>;
  customerRef: DocumentReference<OrganizationDoc>;
  expertRef: DocumentReference<ExpertDoc>;
  vendorRef: DocumentReference<OrganizationDoc>;
  intervieweeRef: DocumentReference<UserDoc>;
  interviewerRef: DocumentReference<UserDoc>;
  twilioRoomRef: DocumentReference<TwilioRoomDoc>;
  lastUpdate: Timestamp;
  enqueuedAt?: Timestamp;
  completedAt?: Timestamp;
  uploadedAt?: Timestamp;
  ref?: string;
  status: TwilioCompositionStatus;
  progress?: number;
  start: Timestamp;
  end: Timestamp;
};

export const isTwilioCompositionDoc = (
  doc: DocumentData,
): doc is TwilioCompositionDoc => true;

export const isTwilioCompositionRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TwilioCompositionDoc> => ref.parent.id === 'twilioCompositions';

export const isTwilioCompositionSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TwilioCompositionDoc> => isTwilioCompositionRef(snap.ref);

export const getTwilioCompositionsCollectionRef = (firestore: Firestore): CollectionReference<TwilioCompositionDoc> => collection(firestore, 'twilioCompositions') as CollectionReference<TwilioCompositionDoc>;
