import {
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Heading,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentReference,
  WithFieldValue,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, { Suspense, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useFirestore } from 'reactfire';
import * as yup from 'yup';
import Catch from '../../../components/Catch';
import ExpertsSelectorField from '../../../components/ExpertsSelectorField';
import ExternalExpertTiersField from '../../../components/ExternalExpertTiersField';
import InternalExpertTiersField from '../../../components/InternalExpertTiersField';
import Loader from '../../../components/Loader';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import SkillsSelectorField from '../../../components/SkillsSelectorField';
import StageActionsField, { StageActionsFieldValue } from '../../../components/StageActionsField';
import TextField from '../../../components/TextField';
import Spinner from '../../../icons/Spinner';
import { ExpertDoc, ExpertTier } from '../../../types/Expert';
import { SkillDoc } from '../../../types/Skill';
import { StageDoc, getStagesCollectionRef } from '../../../types/Stage';

export interface FormFields {
  name: string;
  skillRefs: DocumentReference<SkillDoc>[];
  expertRefs: DocumentReference<ExpertDoc>[];
  internalExpertTiers: ExpertTier[];
  externalExpertTiers: ExpertTier[];
  actions: StageActionsFieldValue;
}

const schema = yup.object().shape({
  name: yup.string().label('Name').required(),
  skillRefs: yup.array().label('Skills').required(),
  expertRefs: yup.array().label('Experts').required(),
  internalExpertTiers: yup.array(yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ])).required(),
  externalExpertTiers: yup.array(yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ])).required(),
  actions: yup.array().label('Actions').required(),
});

const CreateStageScreenMain: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const navigate = useNavigate();

  const firestore = useFirestore();
  const handleFormSubmit = useCallback(async ({
    name,
    skillRefs,
    expertRefs,
    internalExpertTiers,
    externalExpertTiers,
    actions,
  }: FormFields) => {
    const stageRef = doc(getStagesCollectionRef(firestore));

    const requestData: WithFieldValue<StageDoc> = {
      name,
      organizationRef,
      skillRefs,
      expertRefs,
      createdAt: serverTimestamp(),
      internalExpertTiers,
      externalExpertTiers,
      actions: actions as any,
      _v: 1,
    };

    await setDoc(
      stageRef,
      requestData,
    );

    navigate(`../${stageRef.id}`);
  }, [firestore, navigate, organizationRef]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        name: '',
        skillRefs: [],
        expertRefs: [],
        internalExpertTiers: [],
        externalExpertTiers: [],
        actions: [],
      }}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
      }) => (
        <form
          style={{ height: '100%' }}
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <Grid
            templateColumns="auto"
            templateRows="auto 1fr auto"
            height="100%"
          >
            <GridItem py={4} borderBottomWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <Heading>
                  New stage
                </Heading>
              </Container>
            </GridItem>

            <GridItem py={3} overflow="auto">
              <Container>
                <VStack alignItems="stretch" spacing={3}>
                  <TextField isRequired label="Name" name="name" placeholder="NodeJS Full Stack - Stage 1" />

                  <SkillsSelectorField
                    name="skillRefs"
                    label="Skills"
                    isRequired
                  />

                  <HStack spacing={5}>
                    <InternalExpertTiersField
                      label="Expert ranks"
                      name="internalExpertTiers"
                    />

                    <ExternalExpertTiersField
                      label="Clarwis Expert ranks"
                      name="externalExpertTiers"
                    />
                  </HStack>

                  <ExpertsSelectorField
                    name="expertRefs"
                    label="Specific experts"
                  />

                  <StageActionsField
                    name="actions"
                    label="Actions"
                  />
                </VStack>
              </Container>
            </GridItem>

            <GridItem py={4} borderTopWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <Button
                  width="100%"
                  variant="solid"
                  type="submit"
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                  spinner={<Spinner />}
                  loadingText="Adding..."
                >
                  Add stage
                </Button>
              </Container>
            </GridItem>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export const CreateStageScreenCatchFallback: React.FC = () => null;
export const CreateStageScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CreateStageScreen: React.FC = () => (
  <Catch fallback={<CreateStageScreenCatchFallback />}>
    <Suspense fallback={<CreateStageScreenSuspenseFallback />}>
      <CreateStageScreenMain />
    </Suspense>
  </Catch>
);

export default CreateStageScreen;
