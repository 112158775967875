import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

export type RequestData = void;

export type ResponseData = string;

const useAlgoliaGetPublicApiKey = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'algolia-getpublicapikey'),
  [functions],
);

export default useAlgoliaGetPublicApiKey;
