import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { SkillDoc } from './Skill';
import { UserDoc, isUserRef } from './User';

export type UserInterviewerProfileDoc = {
  rate?: number;
  deelContractId?: string;
  skillRefs?: DocumentReference<SkillDoc>[];
  claimedSkillRefs?: DocumentReference<SkillDoc>[];
};

export const isUserInterviewerProfileDoc = (
  data: DocumentData,
): data is UserInterviewerProfileDoc => true;

export const isUserInterviewerProfileRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<UserInterviewerProfileDoc> => ref.id === 'interviewerProfile' && ref.parent.id === 'private' && ref.parent.parent !== null && isUserRef(ref.parent.parent);

export const isUserInterviewerProfileSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
UserInterviewerProfileDoc
> => isUserInterviewerProfileRef(snap.ref);

export const getUserInterviewerProfileDocRef = (userRef: DocumentReference<UserDoc>): DocumentReference<UserInterviewerProfileDoc> => doc(collection(userRef, 'private'), 'interviewerProfile') as DocumentReference<UserInterviewerProfileDoc>;
