import { HStack, IconButton, Text } from '@chakra-ui/react';
import { DocumentReference, arrayRemove, setDoc } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useFirestoreDoc, useUser } from 'reactfire';
import Catch from '../../components/Catch';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import UserAvatar from '../../components/UserAvatar';
import TrashIcon from '../../icons/TrashIcon';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import { UserDoc } from '../../types/User';

export type Props = {
  userRef: DocumentReference<UserDoc>;
};

const MemberItemMain: React.FC<Props> = ({ userRef }) => {
  const { data: userSnap } = useFirestoreDoc(userRef);

  if (!userSnap.exists()) {
    throw new SnapNotFoundError(userSnap);
  }

  const organizationRef = useOrganizationRef();
  const { data: authUser } = useUser();

  const handleRemoveMemberClick = useCallback(
    async () => {
      if (userSnap.id === authUser?.uid) {
        // can't remove self
        return;
      }

      await setDoc(
        organizationRef,
        {
          owners: arrayRemove(userSnap.ref),
        },
        { merge: true },
      );
    },
    [authUser?.uid, organizationRef, userSnap],
  );

  const user = useMemo(() => userSnap.data(), [userSnap]);

  return (
    <HStack>
      <UserAvatar userRef={userRef} size="xs" />
      <Text variant="labelMedium" flexGrow={1}>
        {user.firstName}
        {' '}
        {user.lastName}

        {authUser?.uid === userSnap.id ? (
          <Text as="span" color="cf.cntSecondary" fontStyle="italic">
            {' '}
            (you)
          </Text>
        ) : null}
      </Text>

      <IconButton
        aria-label="Remove"
        colorScheme="negative"
        icon={<TrashIcon />}
        isDisabled={authUser?.uid === userSnap.id}
        onClick={handleRemoveMemberClick}
      />
    </HStack>
  );
};

export const MemberItemCatchFallback: React.FC = () => null;
export const MemberItemSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const MemberItem: React.FC<Props> = (props) => (
  <Catch fallback={<MemberItemCatchFallback />}>
    <Suspense fallback={<MemberItemSuspenseFallback />}>
      <MemberItemMain {...props} />
    </Suspense>
  </Catch>
);

export default MemberItem;
