import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import {
  DocumentReference,
  doc,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useField } from 'formik';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { StageDoc, getStagesCollectionRef } from '../../types/Stage';
import { useOrganizationRef } from '../OrganizationRefContext';

export type Props = {
  name: string;
};

export type StageActionStageRefFieldValue = DocumentReference<StageDoc> | null;

const StageActionStageRefField: React.FC<Props> = ({
  name,
}) => {
  const [field, , helpers] = useField<StageActionStageRefFieldValue>(name);

  const organizationRef = useOrganizationRef();

  const firestore = useFirestore();

  const value = useMemo(
    () => field.value?.id ?? '',
    [field.value],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helpers.setValue(
        doc(getStagesCollectionRef(firestore), e.target.value),
      );
    },
    [firestore, helpers],
  );

  const { data: stagesSnap } = useFirestoreCollection(
    query(
      getStagesCollectionRef(firestore),
      where('organizationRef', '==', organizationRef),
      orderBy('createdAt', 'desc'),
    ),
  );

  return (
    <FormControl>
      <FormLabel>
        Transfer to
      </FormLabel>

      <Select value={value} onChange={onChange}>
        <option value="">
          Select stage
        </option>

        {stagesSnap.docs.map((stageSnap) => (
          <option key={stageSnap.id} value={stageSnap.id}>
            {stageSnap.data().name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default StageActionStageRefField;
