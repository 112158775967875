import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { CandidateDoc } from './Candidate';
import { InterviewDoc } from './Interview';
import { OrganizationDoc } from './Organization';
import { SkillDoc } from './Skill';
import { UserDoc } from './User';

export type ScoreDoc = {
  userRef: DocumentReference<UserDoc>;
  skillRef: DocumentReference<SkillDoc>;
  interviewRef: DocumentReference<InterviewDoc>;
  candidateRef: DocumentReference<CandidateDoc>;
  customerRef: DocumentReference<OrganizationDoc>;
  expertRef: DocumentReference<CandidateDoc>;
  vendorRef: DocumentReference<OrganizationDoc>;
  score: number;
  timestamp: Timestamp;
};

export const isScoreDoc = (
  doc: DocumentData,
): doc is ScoreDoc => true;

export const isScoreRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ScoreDoc> => ref.parent.id === 'scores';

export const isScoreSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<ScoreDoc> => isScoreRef(snap.ref);

export const getScoresCollectionRef = (firestore: Firestore): CollectionReference<ScoreDoc> => collection(firestore, 'scores') as CollectionReference<ScoreDoc>;
