import { Text, VStack } from '@chakra-ui/react';
import { query, where } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import BlockList from '../../components/BlockList';
import BlockListItem from '../../components/BlockListItem';
import Catch from '../../components/Catch';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import PaddingBlock from '../../components/PaddingBlock';
import {
  getOrganizationMemberInvitationsCollectionRef,
} from '../../types/OrganizationMemberInvitation';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import InvitationItem from './InvitationItem';
import MemberInvitationForm from './MemberInvitationForm';
import MemberItem from './MemberItem';

const MembersMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const { data: organizationSnap } = useFirestoreDoc(organizationRef);

  if (!organizationSnap.exists()) {
    throw new SnapNotFoundError(organizationSnap);
  }

  const organization = useMemo(() => organizationSnap.data(), [organizationSnap]);

  const { data: memberInvitationsSnap } = useFirestoreCollection(
    query(
      getOrganizationMemberInvitationsCollectionRef(organizationRef),
      where('accepted', '==', false),
    ),
  );

  return (
    <VStack alignItems="stretch" spacing={8}>
      <VStack spacing={1} alignItems="stretch">
        <Text
          pt="1px"
          pb="3px"
          color="cf.cntTertiary"
          fontSize="sm"
          lineHeight="short"
          fontWeight="medium"
        >
          Members
          {' '}
          &middot;
          {' '}
          {organization.owners.length}
        </Text>

        <BlockList variant="outline">
          {organization.owners.map((userRef) => (
            <BlockListItem key={userRef.id}>
              <MemberItem userRef={userRef} />
            </BlockListItem>
          ))}

          {memberInvitationsSnap.docs.map((invitationSnap) => (
            <BlockListItem key={invitationSnap.id}>
              <InvitationItem invitationSnap={invitationSnap} />
            </BlockListItem>
          ))}
        </BlockList>
      </VStack>

      <VStack spacing={1} alignItems="stretch">
        <Text
          pt="1px"
          pb="3px"
          color="cf.cntTertiary"
          fontSize="sm"
          lineHeight="short"
          fontWeight="medium"
        >
          New Member
        </Text>

        <PaddingBlock>
          <MemberInvitationForm />
        </PaddingBlock>
      </VStack>
    </VStack>
  );
};

const MembersCatchFallback: React.FC = () => null;
const MembersSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Members: React.FC = () => (
  <Catch fallback={<MembersCatchFallback />}>
    <Suspense fallback={<MembersSuspenseFallback />}>
      <MembersMain />
    </Suspense>
  </Catch>
);

export default Members;
