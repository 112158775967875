import { AvatarProps } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { UserDoc } from '../types/User';
import Catch from './Catch';
import UserAvatar from './UserAvatar';

export type LocalVideoPlaceholderProps = AvatarProps & {
  userRef: DocumentReference<UserDoc>;
};

const LocalVideoPlaceholderMain: React.FC<LocalVideoPlaceholderProps> = ({
  userRef,
  ...avatarProps
}) => (
  <UserAvatar
    size="md"
    userRef={userRef}
    bg="cf.bgAccent"
    color="cf.cntOnColor"
        // eslint-disable-next-line react/jsx-props-no-spreading
    {...avatarProps}
  />
);

const LocalVideoPlaceholderCatchFallback: React.FC = () => null;
const LocalVideoPlaceholderSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const LocalVideoPlaceholder: React.FC<LocalVideoPlaceholderProps> = (props) => (
  <Catch fallback={<LocalVideoPlaceholderCatchFallback />}>
    <Suspense fallback={<LocalVideoPlaceholderSuspenseFallback />}>
      <LocalVideoPlaceholderMain {...props} />
    </Suspense>
  </Catch>
);

export default LocalVideoPlaceholder;
