import {
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentReference,
  WithFieldValue,
  deleteField,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import * as yup from 'yup';
import Catch from '../../../components/Catch';
import { useExpertRef } from '../../../components/ExpertRefContext';
import ExpertTierField from '../../../components/ExpertTierField';
import Loader from '../../../components/Loader';
import PhoneNumberField from '../../../components/PhoneNumberField';
import SkillsSelectorField from '../../../components/SkillsSelectorField';
import TextField from '../../../components/TextField';
import Spinner from '../../../icons/Spinner';
import { ExpertDoc, ExpertTier } from '../../../types/Expert';
import { SkillDoc } from '../../../types/Skill';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import Header from './Header';

export interface FormFields {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  customerUrl: string;
  skillRefs: DocumentReference<SkillDoc>[];
  tier: ExpertTier;
}

const schema = yup.object().shape({
  email: yup.string().email().label('Expert email').required(),
  phoneNumber: yup.string().label('Expert phone'),
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  customerUrl: yup.string().url().label('RMS profile'),
  skillRefs: yup.array().min(1).label('Skills').required(),
  tier: yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ]).required(),
});

const EditExpertScreenMain: React.FC = () => {
  const expertRef = useExpertRef();

  const { data: expertSnap } = useFirestoreDoc<ExpertDoc>(expertRef);

  if (!expertSnap.exists()) {
    throw new SnapNotFoundError(expertSnap);
  }

  const expert = useMemo(() => expertSnap.data(), [expertSnap]);

  const navigate = useNavigate();

  const handleFormSubmit = useCallback(async ({
    email,
    firstName,
    lastName,
    phoneNumber,
    customerUrl,
    skillRefs,
    tier,
  }: FormFields) => {
    const requestData: Partial<WithFieldValue<ExpertDoc>> = {
      email,
      firstName,
      lastName,
      skillRefs,
      tier,
      phoneNumber: phoneNumber !== '' ? phoneNumber : deleteField(),
      customerUrl: customerUrl !== '' ? customerUrl : deleteField(),
    };

    await setDoc(
      expertRef,
      requestData,
      { merge: true },
    );

    navigate(`../${expertRef.id}`);
  }, [expertRef, navigate]);

  const handleCancelClick = useCallback(() => {
    navigate(`../${expertRef.id}`);
  }, [expertRef.id, navigate]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        firstName: expert.firstName || '',
        lastName: expert.lastName || '',
        email: expert.email || '',
        phoneNumber: expert.phoneNumber || '',
        customerUrl: expert.customerUrl || '',
        skillRefs: expert.skillRefs || [],
        tier: expert.tier || ExpertTier.LIEUTENANT,
      }}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
      }) => (
        <form
          style={{ height: '100%' }}
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <Grid
            templateColumns="auto"
            templateRows="auto 1fr auto"
            height="100%"
            data-intercom-target="OrganizationCreateExpertPage"
          >
            <GridItem borderBottomWidth={1} borderColor="cf.brdBlackAlpha12">
              <Header />
            </GridItem>

            <GridItem py={4} overflow="auto">
              <Container>
                <VStack alignItems="stretch" spacing={4}>
                  <HStack spacing={4} alignItems="start" className="expert-name">
                    <TextField isRequired label="First name" name="firstName" placeholder="John" data-intercom-target="OrganizationCreateExpertFormFirstName" />
                    <TextField isRequired label="Last name" name="lastName" placeholder="Doe" data-intercom-target="OrganizationCreateExpertFormLastName" />
                  </HStack>

                  <TextField isRequired label="Expert email" name="email" type="email" placeholder="expert@email.com" data-intercom-target="OrganizationCreateExpertFormEmail" />

                  <HStack spacing={4} alignItems="start">
                    <PhoneNumberField label="Expert phone" name="phoneNumber" />
                    <TextField label="RMS profile" name="customerUrl" type="url" placeholder="https://example.com/profile/123" />
                  </HStack>

                  <ExpertTierField
                    isRequired
                    label="Rank"
                    name="tier"
                  />

                  <SkillsSelectorField
                    name="skillRefs"
                    label="Skills"
                    isRequired
                  />
                </VStack>
              </Container>
            </GridItem>

            <GridItem py={4} borderTopWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <SimpleGrid columns={2} gap={4}>
                  <Button
                    variant="outline"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="solid"
                    type="submit"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                    spinner={<Spinner />}
                    loadingText="Processing..."
                    className="cart-checkout"
                    data-intercom-target="OrganizationCreateExpertFormSubmit"
                  >
                    Save
                  </Button>
                </SimpleGrid>
              </Container>
            </GridItem>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export const EditExpertScreenCatchFallback: React.FC = () => null;
export const EditExpertScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const EditExpertScreen: React.FC = () => (
  <Catch fallback={<EditExpertScreenCatchFallback />}>
    <Suspense fallback={<EditExpertScreenSuspenseFallback />}>
      <EditExpertScreenMain />
    </Suspense>
  </Catch>
);

export default EditExpertScreen;
