import {
  AvatarBadge,
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';
import PaddingBlock from './PaddingBlock';
import UserAvatar from './UserAvatar';

const CallLobbyInterviewerInfoMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: interviewerSnap } = useFirestoreDoc(interview.interviewerRef);

  if (!interviewerSnap.exists()) {
    throw new SnapNotFoundError(interviewerSnap);
  }

  const interviewer = useMemo(() => interviewerSnap.data(), [interviewerSnap]);

  return (
    <PaddingBlock>
      <HStack spacing={3} alignItems="center">
        <UserAvatar
          userRef={interview.interviewerRef}
        >
          {interview.interviewerIsConnected ? (<AvatarBadge />) : null}
        </UserAvatar>

        <VStack spacing={1} alignItems="stretch" flexGrow={1}>
          <Text
            fontWeight="medium"
            fontSize="sm"
            lineHeight="shorter"
            color="cf.cntTertiary"
            pt="1px"
            pb="3px"
          >
            Interviewer
          </Text>

          <Text variant="labelMedium">
            {interviewer.firstName}
            {' '}
            {interviewer.lastName}
          </Text>
        </VStack>
      </HStack>
    </PaddingBlock>
  );
};

const CallLobbyInterviewerInfoCatchFallback: React.FC = () => null;
const CallLobbyInterviewerInfoSuspenseFallback: React.FC = () => (
  <PaddingBlock>
    <HStack spacing={3} alignItems="center">
      <SkeletonCircle size="36px" />

      <VStack spacing={0} alignItems="stretch">
        <Skeleton h="20px" w="100px" pt="1px" />
        <Skeleton h="24px" w="140px" pt="1px" pb="2px" />
      </VStack>
    </HStack>
  </PaddingBlock>
);

/* eslint-disable react/jsx-props-no-spreading */
const CallLobbyInterviewerInfo: React.FC = () => (
  <Catch fallback={<CallLobbyInterviewerInfoCatchFallback />}>
    <Suspense fallback={<CallLobbyInterviewerInfoSuspenseFallback />}>
      <CallLobbyInterviewerInfoMain />
    </Suspense>
  </Catch>
);

export default CallLobbyInterviewerInfo;
