import { SearchOptions, SearchResponse } from '@algolia/client-search';
import {
  Box,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  useControllableState,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { useSkillsIndex } from '../../../components/AlgoliaPublicProvider';
import SkillShortInfo from '../../../components/SkillShortInfo';
import useDebounce from '../../../functions/useDebounce';
import FilterIcon from '../../../icons/FilterIcon';
import SearchIcon from '../../../icons/SearchIcon';
import { AlgoliaSkillRecord } from '../../../types/AlgoliaRecords';
import { getSkillsCollectionRef } from '../../../types/Skill';

type Mutable<Type> = {
  -readonly [Key in keyof Type]: Type[Key];
};

export interface Props {
  onSearchOptionsChange: (query: string, searchOptions: SearchOptions) => unknown;
}

const SearchBar: React.FC<Props> = ({ onSearchOptionsChange }) => {
  const skillsIndex = useSkillsIndex();

  const [searchTerm, setSearchTerm] = useControllableState({ defaultValue: '' });
  const query = useDebounce(searchTerm, 500);

  const [skillIds, setSkillIds] = useControllableState<string | string[]>({ defaultValue: [] });

  useEffect(() => {
    const searchOptions: Mutable<SearchOptions> = { };

    if (typeof skillIds === 'string') {
      searchOptions.facetFilters = [`skills.id: ${skillIds}`];
    } else {
      searchOptions.facetFilters = skillIds.map((skillId) => (`skills.id: ${skillId}`));
    }

    onSearchOptionsChange(query, searchOptions);
  }, [onSearchOptionsChange, query, skillIds]);

  const [searchResponse, setSearchResponse] = useState<SearchResponse<AlgoliaSkillRecord>>();

  const [skillsSearchTerm, setSkillsSearchTerm] = useControllableState({ defaultValue: '' });
  const skillsQuery = useDebounce(skillsSearchTerm, 500);

  useEffect(() => {
    if (!skillsIndex) {
      return;
    }

    skillsIndex
      .search<AlgoliaSkillRecord>(skillsQuery, { facetFilters: 'isPublic: true' })
      .then(setSearchResponse);
  }, [skillsIndex, skillsQuery]);

  const firestore = useFirestore();

  return (
    <HStack pb={3}>
      <InputGroup size="sm">
        <InputLeftElement pointerEvents="none" color="cf.cntSecondary">
          <SearchIcon />
        </InputLeftElement>
        <Input
          placeholder="Search for names, skills, etc."
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          variant="flushed"
        />
      </InputGroup>

      <Menu isLazy closeOnSelect={false}>
        <MenuButton
          as={IconButton}
          variant="ghost"
          flexShrink={0}
          aria-label="Filter"
          icon={<FilterIcon />}
        />

        <Portal>
          <MenuList>
            <MenuOptionGroup
              type="checkbox"
              value={skillIds}
              onChange={(v) => setSkillIds(v)}
            >
              <Box pl={9} pr={3} pb={1}>
                <InputGroup size="sm">
                  <InputLeftElement
                    pointerEvents="none"
                    color="cf.cntSecondary"
                    w={5}
                  >
                    <SearchIcon />
                  </InputLeftElement>
                  <Input
                    value={skillsSearchTerm}
                    onChange={(e) => setSkillsSearchTerm(e.target.value)}
                    variant="flushed"
                    pl={7}
                  />
                </InputGroup>
              </Box>

              {searchResponse ? searchResponse.hits.map(({ objectID }) => (
                <MenuItemOption key={objectID} value={objectID}>
                  <SkillShortInfo skillRef={doc(getSkillsCollectionRef(firestore), objectID)} />
                </MenuItemOption>
              )) : null}
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
    </HStack>
  );
};

export default SearchBar;
