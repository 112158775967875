import { Text, VStack } from '@chakra-ui/react';
import { orderBy, query } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useFirestoreCollection } from 'reactfire';
import BlockList from '../../../../components/BlockList';
import BlockListItem from '../../../../components/BlockListItem';
import { useCandidateRef } from '../../../../components/CandidateRefProvider';
import Catch from '../../../../components/Catch';
import { getCandidateInvitationsCollectionRef } from '../../../../types/CandidateInvitation';
import InvitationRow from './InvitationRow';

const InvitationsListMain: React.FC = () => {
  const candidateRef = useCandidateRef();
  const { data: invitationsSnap } = useFirestoreCollection(
    query(
      getCandidateInvitationsCollectionRef(candidateRef),
      orderBy('sentAt', 'asc'),
    ),
  );

  return (
    <VStack spacing={1} alignItems="stretch">
      <Text
        pt="1px"
        pb="3px"
        color="cf.cntTertiary"
        fontSize="sm"
        lineHeight="short"
        fontWeight="medium"
      >
        Invitations
        {' '}
        &middot;
        {' '}
        {invitationsSnap.docs.length}
      </Text>

      <BlockList variant="outline">
        {invitationsSnap.docs.map((invitationSnap) => (
          <BlockListItem key={invitationSnap.id}>
            <InvitationRow invitationSnap={invitationSnap} />
          </BlockListItem>
        ))}
      </BlockList>
    </VStack>
  );
};

export const InvitationsListCatchFallback: React.FC = () => null;
export const InvitationsListSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const InvitationsList: React.FC = () => (
  <Catch fallback={<InvitationsListCatchFallback />}>
    <Suspense fallback={<InvitationsListSuspenseFallback />}>
      <InvitationsListMain />
    </Suspense>
  </Catch>
);

export default InvitationsList;
