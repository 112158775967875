import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  collection,
} from 'firebase/firestore';
import { MicrosoftCredentialsDoc } from './MicrosoftAuthCredentials';
import { UserDoc } from './User';

export type MicrosoftCalendarDoc = {
  userRef: DocumentReference<UserDoc>;
  credentialsRef: DocumentReference<MicrosoftCredentialsDoc>;
  calendar: {
    hexColor: string;
    owner: {
      name: string;
      address: string;
    };
    name: string;
    isTallyingResponses: boolean;
    canEdit: boolean;
    canShare: boolean;
    isDefaultCalendar: boolean;
    defaultOnlineMeetingProvider: string;
    canViewPrivateItems: boolean;
    isRemovable: boolean;
    id: string;
    allowedOnlineMeetingProviders: string[];
    color: string;
    changeKey: string;
  };
  isEnabled: boolean;
  busy: string[];
};

export const isMicrosoftCalendarDoc = (
  doc?: DocumentData,
): doc is MicrosoftCalendarDoc => true;

export const isMicrosoftCalendarRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<MicrosoftCalendarDoc> => ref.parent.id === 'microsoftCalendars';

export const isMicrosoftCalendarSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<MicrosoftCalendarDoc> => isMicrosoftCalendarRef(snap.ref);

export const getMicrosoftCalendarsCollectionRef = (firestore: Firestore): CollectionReference<MicrosoftCalendarDoc> => collection(firestore, 'microsoftCalendars') as CollectionReference<MicrosoftCalendarDoc>;
