import {
  Box,
  Button,
  Container,
  HStack,
  Heading,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentReference,
  WithFieldValue,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import * as yup from 'yup';
import AvatarField from '../../components/AvatarField';
import TextField from '../../components/TextField';
import { useUserRef } from '../../components/UserRefContext';
import Spinner from '../../icons/Spinner';
import { AvatarDoc } from '../../types/Avatar';
import { OrganizationDoc, getOrganizationsCollectionRef } from '../../types/Organization';

export interface FormFields {
  name: string;
  email: string;
  logoRef: DocumentReference<AvatarDoc> | null;
}

const schema = yup.object().shape({
  name: yup.string().label('Company name').required(),
  email: yup.string().label('Contact email').email().required(),
  logoRef: yup.mixed().label('Company logo').required(),
});

const OrganizationCreatePage: React.FC = () => {
  const firestore = useFirestore();
  const userRef = useUserRef();

  const navigate = useNavigate();

  const handleFormSubmit = useCallback(async ({
    logoRef,
    ...requiredFields
  }: FormFields) => {
    const organizationRef = doc(getOrganizationsCollectionRef(firestore));

    const organizationData: WithFieldValue<OrganizationDoc> = {
      ...requiredFields,
      owners: [
        userRef,
      ],
      createdAt: serverTimestamp(),
    };

    if (logoRef) {
      organizationData.logoRef = logoRef;
    }

    await setDoc(organizationRef, organizationData, { merge: true });

    navigate(`/organizations/${organizationRef.id}`);
  }, [firestore, navigate, userRef]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Container maxW="container.sm">
      <Box pt={4} pb={2}>
        <Heading>Organization Details</Heading>
      </Box>

      <Formik<FormFields>
        initialValues={{
          name: '',
          email: '',
          logoRef: null,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={schema}
        validateOnChange={validateAll}
        validateOnBlur={validateAll}
      >
        {({ handleSubmit, values, isSubmitting }) => (
          <form
            noValidate
            onSubmit={(...props) => {
              setValidateAll(true);
              return handleSubmit(...props);
            }}
          >
            <Box py={4}>
              <VStack spacing={8} alignItems="stretch">
                <AvatarField name="logoRef" label="Logo" placeholder="Upload your logo" logo />

                <HStack spacing={4} alignItems="start">
                  <TextField name="name" label="Name" placeholder="MegaCorp" />
                  <TextField name="email" label="Contact email" placeholder="lord@megacorp.com" />
                </HStack>

                <Button
                  size="lg"
                  type="submit"
                  isLoading={isSubmitting}
                  spinner={<Spinner />}
                  loadingText="Saving..."
                >
                  Create organization
                </Button>
              </VStack>
            </Box>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default OrganizationCreatePage;
