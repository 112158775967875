import { Button, VStack } from '@chakra-ui/react';
import {
  GeoPoint,
  PartialWithFieldValue,
  arrayUnion,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc, useFunctions } from 'reactfire';
import * as yup from 'yup';
import Catch from '../../components/Catch';
import CityField from '../../components/CityField';
import PhoneNumberField from '../../components/PhoneNumberField';
import { useUserRef } from '../../components/UserRefContext';
import useTwilioLookup from '../../functions/useTwilioLookup';
import Spinner from '../../icons/Spinner';
import { UserSensitiveDoc, getUserSensitiveDocRef } from '../../types/UserSensitive';

export interface FormFields {
  location: GeoPoint | null;
  phoneNumber: string;
}

const PrivateFormMain: React.FC = () => {
  const [validateAll, setValidateAll] = useState(false);

  const functions = useFunctions();
  const lookup = useTwilioLookup(functions);

  const schema = useMemo(() => yup.object().shape({
    location: yup.mixed().label('City').nullable().test(
      'required',
      'City is a required field',
      (v: GeoPoint | null, ...rest) => v !== null,
    ),
    phoneNumber: yup.string().test(
      'validPhoneNumber',
      'Should be valid phone number',
      async (phoneNumber?: string) => {
        if (!phoneNumber) {
          return false;
        }

        try {
          await lookup({ phoneNumber });
          return true;
        } catch (err) {
          return false;
        }
      },
    ),
  }), [lookup]);

  const userRef = useUserRef();
  const userSensitiveRef = useMemo(() => getUserSensitiveDocRef(userRef), [userRef]);

  const { data: userSensitiveSnap } = useFirestoreDoc(userSensitiveRef);
  const userSensitive = useMemo(() => userSensitiveSnap.data(), [userSensitiveSnap]);

  const onSubmit = useCallback(async ({
    location,
    phoneNumber,
  }: FormFields) => {
    if (!location) {
      return;
    }

    const userSensitiveData: PartialWithFieldValue<UserSensitiveDoc> = {
      location,
      phoneNumber,
      languages: arrayUnion('en'),
    };

    await setDoc(userSensitiveRef, userSensitiveData, { merge: true });
  }, [userSensitiveRef]);

  if (!userSensitive) {
    return null;
  }

  return (
    <Formik<FormFields>
      initialValues={{
        location: userSensitive.location || null,
        phoneNumber: userSensitive.phoneNumber || '',
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <VStack spacing={8} alignItems="stretch" textAlign="left">
            <CityField />

            <PhoneNumberField label="Phone number" name="phoneNumber" />

            <Button
              type="submit"
              isLoading={isSubmitting}
              spinner={<Spinner />}
              loadingText="Saving..."
            >
              Save
            </Button>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

export const PrivateFormCatchFallback: React.FC = () => null;
export const PrivateFormSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const PrivateForm: React.FC = () => (
  <Catch fallback={<PrivateFormCatchFallback />}>
    <Suspense fallback={<PrivateFormSuspenseFallback />}>
      <PrivateFormMain />
    </Suspense>
  </Catch>
);

export default PrivateForm;
