import { useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { useOrganizationRef } from '../components/OrganizationRefContext';
import {
  OrganizationPlatformTier,
  getOrganizationCustomerProfileRef,
} from '../types/OrganizationCustomerProfile';

const usePlatformTier = (): OrganizationPlatformTier | undefined => {
  const organizationRef = useOrganizationRef();

  const { data: snap } = useFirestoreDoc(
    getOrganizationCustomerProfileRef(organizationRef),
  );

  return useMemo(
    () => snap.data()?.platformTier,
    [snap],
  );
};

export default usePlatformTier;
