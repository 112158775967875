import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

export type RequestData = {
  organizationId: string;
};

export type ResponseData = string;

const useApiCreateApiToken = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'api-createapitoken'),
  [functions],
);

export default useApiCreateApiToken;
