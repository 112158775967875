import {
  Button,
  HStack,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useFunctions } from 'reactfire';
import * as yup from 'yup';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import TextField from '../../components/TextField';
import useOrganizationSendMemberInvitation from '../../functions/useOrganizationSendMemberInvitation';
import Spinner from '../../icons/Spinner';

export interface FormFields {
  firstName: string;
  lastName: string;
  email: string;
}

const schema = yup.object().shape({
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  email: yup.string().label('Contact email').email().required(),
});

const MemberInvitationForm: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const [validateAll, setValidateAll] = useState(false);

  const functions = useFunctions();
  const sendMemberInvitation = useOrganizationSendMemberInvitation(functions);
  const toast = useToast();
  const onSubmit = useCallback(
    async ({
      firstName,
      lastName,
      email,
    }: FormFields) => {
      try {
        await sendMemberInvitation({
          firstName,
          lastName,
          email,
          organizationId: organizationRef.id,
        });
        toast({
          title: 'Invitation sent',
          status: 'success',
        });
      } catch (err) {
        if (err instanceof Error) {
          toast({
            title: 'Failed to send invitation',
            status: 'error',
            description: err.message,
          });
        }
      }
    },
    [organizationRef.id, sendMemberInvitation, toast],
  );

  return (
    <Formik<FormFields>
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <VStack spacing={8} alignItems="stretch" textAlign="left">
            <HStack spacing={4} alignItems="start">
              <TextField name="firstName" label="First name" placeholder="John" />
              <TextField name="lastName" label="Last name" placeholder="doe" />
            </HStack>

            <TextField name="email" type="email" label="Contact email" placeholder="john@megacorp.com" />

            <HStack justifyContent="right">
              <Button
                type="submit"
                isLoading={isSubmitting}
                spinner={<Spinner />}
                loadingText="Inviting..."
              >
                Invite
              </Button>
            </HStack>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

export default MemberInvitationForm;
