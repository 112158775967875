import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  collection,
} from 'firebase/firestore';
import { OrganizationDoc } from './Organization';

export enum DeelLegalEntityType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export type DeelLegalEntityDoc = {
  organizationRef: DocumentReference<OrganizationDoc>;
  name: string;
  type: DeelLegalEntityType;
};

export const isDeelLegalEntityDoc = (
  doc?: DocumentData,
): doc is DeelLegalEntityDoc => true;

export const isDeelLegalEntityRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<DeelLegalEntityDoc> => ref.parent.id === 'deelLegalEntities';

export const isDeelLegalEntitySnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<DeelLegalEntityDoc> => isDeelLegalEntityRef(snap.ref);

export const getDeelLegalEntitiesCollectionRef = (firestore: Firestore): CollectionReference<DeelLegalEntityDoc> => collection(firestore, 'deelLegalEntities') as CollectionReference<DeelLegalEntityDoc>;
