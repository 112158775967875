import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

export type RequestData = {
  candidateId: string;
  invitationId: string;
};

export type ResponseData = void;

const useCandidateAcceptInvitation = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'candidate-acceptinvitation'),
  [functions],
);

export default useCandidateAcceptInvitation;
