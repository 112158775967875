import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

export type RequestData = { skillIds: string[]; };

export type ResponseData = void;

const useUserRequestSkills = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'user-requestskills'),
  [functions],
);

export default useUserRequestSkills;
