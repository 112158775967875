import {
  Grid,
  GridItem,
  HStack,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import CallContent from '../../../../components/CallContent';
import CallSchedule from '../../../../components/CallSchedule';
import GhostParticipants from '../../../../components/GhostParticipants';
import InterviewerParticipantMedia from '../../../../components/InterviewerParticipantMedia';
import LeaveButton from '../../../../components/LeaveButton';
import LocalParticipantMedia from '../../../../components/LocalParticipantMedia';

const CallRoom: React.FC = () => (
  <Grid
    px={5}
    py={3}
    templateColumns="auto 320px"
    templateRows="auto 64px"
    templateAreas={' "content media"  "schedule schedule" '}
    columnGap={5}
    rowGap={3}
    h="100%"
  >
    <GridItem gridArea="content">
      <CallContent />
    </GridItem>

    <GridItem gridArea="media">
      <VStack spacing={3} alignItems="stretch">
        <InterviewerParticipantMedia />
        <LocalParticipantMedia />
        <GhostParticipants />
      </VStack>
    </GridItem>

    <GridItem gridArea="schedule">
      <HStack spacing={5}>
        <CallSchedule flexGrow={1} />
        <LeaveButton />
      </HStack>
    </GridItem>
  </Grid>
);

export default CallRoom;
