import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

export type RequestData = {
  calendarId: string;
};

export type ResponseData = void;

const useGoogleCalendarEnable = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'google-calendar-enable'),
  [functions],
);

export default useGoogleCalendarEnable;
