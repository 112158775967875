import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useFirestore } from 'reactfire';
import StageRefProvider from '../../components/StageRefProvider';
import { getStagesCollectionRef } from '../../types/Stage';
import EditStageScreen from './EditStageScreen';

export type Params = {
  stageId: string;
  organizationId: string;
};

const OrganizationStageEditPage: React.FC = () => {
  const { stageId } = useParams<Params>();
  const firestore = useFirestore();
  const stageRef = useMemo(
    () => doc(getStagesCollectionRef(firestore), stageId),
    [stageId, firestore],
  );

  return (
    <StageRefProvider stageRef={stageRef} key={stageRef.path}>
      <EditStageScreen />
    </StageRefProvider>
  );
};

export default OrganizationStageEditPage;
