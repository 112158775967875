import {
  Box,
  Button,
  HStack,
  IconButton,
  Skeleton,
  SkeletonText,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import {
  Timestamp,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import Spinner from '../icons/Spinner';
import { InterviewStatus } from '../types/Interview';
import {
  InterviewScheduleItemStatus,
  getInterviewScheduleItemsCollectionRef,
} from '../types/InterviewScheduleItem';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';
import DocumentLoader from './DocumentLoader';
import { useInterviewRef } from './InterviewRefContext';
import { useUserRef } from './UserRefContext';

enum AnswerLevel {
  TERRIBLE = 'Terrible',
  BAD = 'Bad',
  MODERATE = 'Moderate',
  GOOD = 'Good',
  EXCELLENT = 'Excellent',
}

const CallScoreMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: scheduleItemsSnap } = useFirestoreCollection(
    query(
      getInterviewScheduleItemsCollectionRef(interviewRef),
      orderBy('startsAt', 'desc'),
    ),
  );

  const allCreated = useMemo(
    () => _.every(
      scheduleItemsSnap.docs,
      (scheduleItemSnap) => scheduleItemSnap.data().status === InterviewScheduleItemStatus.CREATED,
    ),
    [scheduleItemsSnap.docs],
  );

  const currentScheduleItemIndex = useMemo(
    () => _.findIndex(
      scheduleItemsSnap.docs,
      (scheduleItemSnap) => {
        const scheduleItem = scheduleItemSnap.data();
        return !!scheduleItem.startedAt && !scheduleItem.endedAt;
      },
    ),
    [scheduleItemsSnap.docs],
  );

  const currentScheduleItemSnap = useMemo(
    () => (currentScheduleItemIndex < 0 ? null : scheduleItemsSnap.docs[currentScheduleItemIndex]),
    [currentScheduleItemIndex, scheduleItemsSnap.docs],
  );

  const userRef = useUserRef();

  const interviewScheduleItem = useMemo(
    () => currentScheduleItemSnap?.data(),
    [currentScheduleItemSnap],
  );

  const [sliderValue, setSliderValue] = useState(0.50);
  const [showTooltip, setShowTooltip] = useState(false);

  const answerLevel = useMemo<AnswerLevel>(() => {
    if (sliderValue < 0.20) {
      return AnswerLevel.TERRIBLE;
    }

    if (sliderValue < 0.40) {
      return AnswerLevel.BAD;
    }

    if (sliderValue < 0.60) {
      return AnswerLevel.MODERATE;
    }

    if (sliderValue < 0.80) {
      return AnswerLevel.GOOD;
    }

    return AnswerLevel.EXCELLENT;
  }, [sliderValue]);

  const [isLoading, setLoading] = useState<boolean>(false);
  const handleClick = useCallback(
    async (score: number) => {
      setLoading(true);
      try {
        if (interview.status !== InterviewStatus.STARTED) {
          throw new Error('You can not switch questions when interview is not active');
        }

        if (currentScheduleItemIndex < 0) {
          throw new Error('Interview has no active question');
        }

        if (currentScheduleItemIndex + 1 > scheduleItemsSnap.docs.length) {
          throw new Error('This is already the last question');
        }

        const t = Timestamp.now();

        await setDoc(
          scheduleItemsSnap.docs[currentScheduleItemIndex].ref,
          {
            status: InterviewScheduleItemStatus.ENDED,
            endedAt: t,
            score,
          },
          { merge: true },
        );

        if (currentScheduleItemIndex + 1 < scheduleItemsSnap.docs.length) {
          await setDoc(
            scheduleItemsSnap.docs[currentScheduleItemIndex + 1].ref,
            {
              status: InterviewScheduleItemStatus.STARTED,
              startedAt: t,
            },
            { merge: true },
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [currentScheduleItemIndex, interview.status, scheduleItemsSnap.docs],
  );

  const handleStartClick = useCallback(
    async () => {
      await setDoc(
        scheduleItemsSnap.docs[0].ref,
        {
          status: InterviewScheduleItemStatus.STARTED,
          startedAt: serverTimestamp(),
        },
        { merge: true },
      );
    },
    [scheduleItemsSnap.docs],
  );

  if (interview.interviewerRef.id !== userRef.id) {
    return null;
  }

  if (!interviewScheduleItem && allCreated) {
    return (
      <Button
        onClick={handleStartClick}
      >
        Start interview
      </Button>
    );
  }

  if (!interviewScheduleItem) {
    return null;
  }

  return (
    <VStack alignItems="stretch">
      <Text variant="labelSmall" color="cf.cntSecondary">Rate Interviewee&apos;s response</Text>
      <Slider
        min={0}
        max={1}
        step={0.05}
        onChange={(val) => setSliderValue(val)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg="teal.500"
          color="white"
          placement="top"
          isOpen={showTooltip}
          label={`${Math.round(sliderValue * 100)}%`}
        >
          <SliderThumb />
        </Tooltip>
      </Slider>

      <HStack alignItems="end">
        <VStack alignItems="stretch" flexGrow={1}>
          <Text variant="labelMedium">
            {answerLevel}
          </Text>
          {currentScheduleItemSnap ? (
            <DocumentLoader reference={currentScheduleItemSnap?.data().questionRef}>
              {(questionSnap) => {
                let answerHint = '';
                switch (answerLevel) {
                  case AnswerLevel.TERRIBLE:
                    answerHint = questionSnap.data()?.answerHint1;
                    break;
                  case AnswerLevel.BAD:
                    answerHint = questionSnap.data()?.answerHint2;
                    break;
                  case AnswerLevel.MODERATE:
                    answerHint = questionSnap.data()?.answerHint3;
                    break;
                  case AnswerLevel.GOOD:
                    answerHint = questionSnap.data()?.answerHint4;
                    break;
                  case AnswerLevel.EXCELLENT:
                    answerHint = questionSnap.data()?.answerHint5;
                    break;
                }

                return (
                  <Text variant="subtitle" color="cf.cntSecondary">
                    {answerHint}
                  </Text>
                );
              }}
            </DocumentLoader>
          ) : null}
        </VStack>

        <IconButton
          aria-label="Next"
          icon={<ArrowRightIcon />}
          onClick={() => handleClick(sliderValue)}
          isLoading={isLoading}
          spinner={<Spinner />}
        />
      </HStack>
    </VStack>
  );
};

export const CallScoreSuspenseFallback: React.FC = () => (
  <Box px={5} py={4} flexGrow={1} flexShrink={1} overflow="auto">
    <HStack spacing={4} alignItems="start">
      <Skeleton h="28px" w="20px" />
      <Box minW="0px" flexGrow={1}>
        <VStack spacing={4} alignItems="start">
          <Skeleton h="28px" w="100%" />

          <SkeletonText w="100%" noOfLines={10} spacing={4} />
        </VStack>
      </Box>
    </HStack>
  </Box>
);

export const CallScoreCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallScore: React.FC = () => (
  <Catch fallback={<CallScoreCatchFallback />}>
    <Suspense fallback={<CallScoreSuspenseFallback />}>
      <CallScoreMain />
    </Suspense>
  </Catch>
);

export default CallScore;
