import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  collection,
} from 'firebase/firestore';

export type AvatarResolutionMap = {
  '1x'?: string;
  '1.5x'?: string;
  '2x'?: string;
  '3x'?: string;
};

export type AvatarDoc = {
  original: string;
  xs?: AvatarResolutionMap;
  sm?: AvatarResolutionMap;
  md?: AvatarResolutionMap;
  '2xl'?: AvatarResolutionMap;
};

export const isAvatarDoc = (
  doc: DocumentData,
): doc is AvatarDoc => true;

export const isAvatarRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<AvatarDoc> => ref.parent.id === 'avatars';

export const isAvatarSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<AvatarDoc> => isAvatarRef(snap.ref);

export const getAvatarsCollectionRef = (firestore: Firestore): CollectionReference<AvatarDoc> => collection(firestore, 'avatars') as CollectionReference<AvatarDoc>;
