import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { UserDoc, isUserRef } from './User';

export type UserTimezoneDoc = {
  timezone?: string;
};

export const isUserTimezoneDoc = (
  data: DocumentData,
): data is UserTimezoneDoc => true;

export const isUserTimezoneRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<UserTimezoneDoc> => ref.id === 'timezone' && ref.parent.id === 'private' && ref.parent.parent !== null && isUserRef(ref.parent.parent);

export const isUserTimezoneSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<UserTimezoneDoc> => isUserTimezoneRef(snap.ref);

export const getUserTimezoneDocRef = (userRef: DocumentReference<UserDoc>): DocumentReference<UserTimezoneDoc> => doc(collection(userRef, 'private'), 'timezone') as DocumentReference<UserTimezoneDoc>;
