import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import {
  useFirestore,
  useFunctions,
  useStorage,
  useUser,
} from 'reactfire';
import useIntercomGetHash from '../functions/useIntercomGetHash';
import { getUsersCollectionRef } from '../types/User';

const IntercomBoot: React.FC = () => {
  const { data: user } = useUser();
  const { update } = useIntercom();
  const firestore = useFirestore();
  const storage = useStorage();

  const functions = useFunctions();
  const intercomGetHash = useIntercomGetHash(functions);

  useEffect(() => {
    if (!user) {
      return () => { };
    }

    intercomGetHash()
      .then(({ data: userHash }) => {
        update({
          userId: user.uid,
          userHash,
          ...(user.email ? { email: user.email } : {}),
          ...(user.phoneNumber ? { phone: user.phoneNumber } : {}),
        });
      }, () => {
        /* do nothing */
      });

    return onSnapshot(
      doc(getUsersCollectionRef(firestore), user.uid),
      async (profileSnap) => {
        const profile = profileSnap.data();

        if (!profile) {
          return;
        }

        if (profile.firstName || profile.lastName) {
          update({
            name: _.compact([profile.firstName, profile.lastName]).join(' '),
          });
        }

        if (!profile.avatarRef) {
          return;
        }

        const avatarSnap = await getDoc(profile.avatarRef);
        const avatar = avatarSnap.data();

        if (!avatarSnap.exists() || !avatar?.original) {
          return;
        }

        getDownloadURL(ref(storage, avatar.original))
          .then((avatarUrl) => {
            update({
              avatar: {
                type: 'avatar',
                imageUrl: avatarUrl,
              },
            });
          }, () => {
            /* do nothing */
          });
      },
      () => {
        /* do nothing */
      },
    );
  }, [firestore, storage, update, user, intercomGetHash]);

  return null;
};

export default IntercomBoot;
