import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { doc, query, where } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useFirestore, useFirestoreCollection, useUser } from 'reactfire';
import { getOrganizationsCollectionRef } from '../types/Organization';
import { getUsersCollectionRef } from '../types/User';
import Catch from './Catch';
import ClarwisLogo from './Logo';
import OrganizationItem from './NavUser/OrganizationItem';
import OrganizationAvatar from './OrganizationAvatar';

const NavLogoMain: React.FC = () => {
  const { data: user } = useUser();
  const navigate = useNavigate();
  const match = useMatch('/organizations/:organizationId/*');
  const firestore = useFirestore();
  const userRef = useMemo(
    () => doc(getUsersCollectionRef(firestore), user?.uid),
    [firestore, user?.uid],
  );

  const { data: organizationsSnap } = useFirestoreCollection(
    query(
      getOrganizationsCollectionRef(firestore),
      where('owners', 'array-contains', userRef),
    ),
  );

  const organizationSnap = useMemo(
    () => (match?.params.organizationId ? _.find(
      organizationsSnap.docs,
      (snap) => snap.id === match.params.organizationId,
    ) : undefined),
    [match?.params.organizationId, organizationsSnap.docs],
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <>
      {organizationSnap ? (
        <OrganizationAvatar
          size="sm"
          organizationSnap={organizationSnap}
          cursor="pointer"
          onClick={onOpen}
          userSelect="none"
        />
      ) : (
        <ClarwisLogo
          cursor="pointer"
          onClick={handleClick}
          userSelect="none"
        />
      )}

      {organizationsSnap.docs.length ? (
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          size="sm"
        >
          <DrawerOverlay />
          <DrawerContent
            py={3}
            px={5}
            overflow="auto"
          >
            <VStack alignItems="stretch" spacing={5}>
              {organizationsSnap.docs.map((snap, i) => (
                <OrganizationItem key={snap.id} organizationSnap={snap} />
              ))}
            </VStack>
          </DrawerContent>
        </Drawer>
      ) : null}
    </>
  );
};

export const NavLogoCatchFallback: React.FC = () => null;
export const NavLogoSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const NavLogo: React.FC = () => (
  <Catch fallback={<NavLogoCatchFallback />}>
    <Suspense fallback={<NavLogoSuspenseFallback />}>
      <NavLogoMain />
    </Suspense>
  </Catch>
);

export default NavLogo;
