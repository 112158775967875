import {
  BoxProps,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { DocumentReference, doc } from 'firebase/firestore';
import { useField } from 'formik';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useFirestore } from 'reactfire';
import PlusIcon from '../../icons/PlusIcon';
import { QuestionDoc, getQuestionsCollectionRef } from '../../types/Question';
import BlockList from '../BlockList';
import PaddingBlock from '../PaddingBlock';
import QuestionRefProvider from '../QuestionRefContext';
import CreateQuestionModal from './CreateQuestionModal';
import QuestionField from './QuestionField';

export type Props = BoxProps & {
  name: string;
  label: string;
  isRequired?: boolean;
};

const QuestionsField: React.FC<Props> = ({
  name,
  label,
  isRequired,
  ...boxProps
}) => {
  const [field, meta, helpers] = useField<DocumentReference<QuestionDoc>[]>(name);

  const handleUpClick = useCallback(
    (questionRef: DocumentReference<QuestionDoc>) => {
      const currentIndex = _.findIndex(field.value, (r) => r.id === questionRef.id);
      helpers.setValue([
        ...field.value.slice(0, currentIndex - 1),
        questionRef,
        field.value[currentIndex - 1],
        ...field.value.slice(currentIndex + 1),
      ]);
    },
    [field.value, helpers],
  );

  const handleRemoveClick = useCallback(
    (questionRef: DocumentReference<QuestionDoc>) => {
      helpers.setValue(_.filter(field.value, (r) => r.id !== questionRef.id));
    },
    [field.value, helpers],
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const firestore = useFirestore();
  const [newQuestionRef, setNewQuestionRef] = useState(doc(getQuestionsCollectionRef(firestore)));

  const handleCreate = useCallback(
    (questionRef: DocumentReference<QuestionDoc>) => {
      helpers.setValue([
        ...field.value,
        questionRef,
      ]);
      onClose();
      setNewQuestionRef(doc(getQuestionsCollectionRef(firestore)));
    },
    [field.value, firestore, helpers, onClose],
  );

  return (
    <FormControl
      isInvalid={!!meta.error}
      isRequired={isRequired}
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <HStack>
        <FormLabel flexGrow={1}>
          {label}
        </FormLabel>

        {field.value.length ? (
          <Button
            leftIcon={<PlusIcon />}
            variant="ghost"
            size="xs"
            onClick={onOpen}
          >
            Add question
          </Button>
        ) : null}
      </HStack>

      {field.value.length ? (
        <BlockList variant="outline">
          {field.value.map((questionRef, i) => (
            <QuestionField
              key={questionRef.id}
              name={`${name}[${i}]`}
              onUpClick={i > 0 ? handleUpClick : undefined}
              onRemoveClick={handleRemoveClick}
            />
          ))}
        </BlockList>
      ) : (
        <PaddingBlock>
          <Center>
            <Button
              leftIcon={<PlusIcon />}
              onClick={onOpen}
            >
              Add question
            </Button>
          </Center>
        </PaddingBlock>
      )}

      <QuestionRefProvider questionRef={newQuestionRef}>
        <CreateQuestionModal
          key={newQuestionRef.id}
          isOpen={isOpen}
          onClose={onClose}
          onCreate={handleCreate}
        />
      </QuestionRefProvider>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

QuestionsField.defaultProps = {
  isRequired: undefined,
};

export default QuestionsField;
