import {
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import { useUserRef } from '../../components/UserRefContext';
import { getOrganizationsCollectionRef } from '../../types/Organization';

export type CheckOrganizationProps = {
  fallback: React.ReactElement;
};

const CheckOrganizationLayoutMain: React.FC<CheckOrganizationProps> = ({ fallback }) => {
  const userRef = useUserRef();
  const firestore = useFirestore();
  const { data: organizationsSnap } = useFirestoreCollection(
    query(
      getOrganizationsCollectionRef(firestore),
      where('owners', 'array-contains', userRef),
      orderBy('createdAt', 'asc'),
      limit(1),
    ),
  );

  if (!organizationsSnap.docs.length) {
    return fallback;
  }

  return (
    <Outlet />
  );
};

export const CheckOrganizationLayoutCatchFallback: React.FC = () => null;
export const CheckOrganizationLayoutSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CheckOrganizationLayout: React.FC<CheckOrganizationProps> = (props) => (
  <Catch fallback={<CheckOrganizationLayoutCatchFallback />}>
    <Suspense fallback={<CheckOrganizationLayoutSuspenseFallback />}>
      <CheckOrganizationLayoutMain {...props} />
    </Suspense>
  </Catch>
);

export default CheckOrganizationLayout;
