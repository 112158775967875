import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

export type RequestData = {
  expertId: string;
};

export type ResponseData = void;

const useExpertSendInvitation = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'expert-sendinvitation'),
  [functions],
);

export default useExpertSendInvitation;
