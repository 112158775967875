import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Spacer,
  Text,
} from '@chakra-ui/react';
import {
  DocumentReference,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useField } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import { useFirestore, useStorage } from 'reactfire';
import getCroppedImage from '../helpers/getCroppedImage';
import EditIcon from '../icons/EditIcon';
import TrashIcon from '../icons/TrashIcon';
import UploadIcon from '../icons/UploadIcon';
import UserIcon from '../icons/UserIcon';
import { AssetDoc, getAssetsCollectionRef } from '../types/Asset';
import AssetImage from './AssetImage';
import { useOrganizationRef } from './OrganizationRefContext';
import PaddingBlock from './PaddingBlock';

export type AssetFieldProps = {
  name: string;
  placeholder?: string;
  label: string;
  isRequired?: boolean;
};

const AssetField: React.FC<AssetFieldProps> = ({
  name,
  placeholder = 'Upload your photo',
  label,
  isRequired = false,
}) => {
  const organizationRef = useOrganizationRef();
  const [field, meta, helpers] = useField<DocumentReference<AssetDoc> | null>(name);
  const storage = useStorage();
  const firestore = useFirestore();
  const inputFile = useRef<HTMLInputElement>(null);

  const [uploadProgress, setUploadProgress] = useState<number | null>(null);

  const handleUploadButtonClick = useCallback(() => {
    if (inputFile.current === null) {
      return;
    }

    inputFile.current.click();
  }, [inputFile]);

  const handleRemoveButtonClick = useCallback(() => {
    helpers.setValue(null);
  }, [helpers]);

  const handleImageChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }
    const file = e.target.files[0];

    const data = await getCroppedImage(file, false);

    const assetRef = doc(getAssetsCollectionRef(firestore));

    const dest = ref(storage, `assets/${organizationRef.id}/${assetRef.id}.webp`);
    const uploadTask = uploadBytesResumable(dest, data, { contentType: 'image/webp' });

    await setDoc(assetRef, {
      title: '',
      description: '',
      ref: dest.fullPath,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      revision: 1,
      tagRefs: [],
      organizationRef,
    });

    const stop1 = uploadTask.on(
      'state_changed',
      {
        next: (v) => {
          setUploadProgress(v.bytesTransferred / v.totalBytes);
        },
        complete: async () => {
          stop1();
          helpers.setValue(assetRef);
          setUploadProgress(null);
        },
      },
    );
  }, [firestore, helpers, organizationRef, storage]);

  return (
    <FormControl
      isInvalid={!!meta.error}
      isRequired={isRequired}
    >
      <FormLabel>{label}</FormLabel>

      <PaddingBlock>
        {!field.value ? (
          <HStack spacing={3}>
            {
              uploadProgress !== null ? (
                <CircularProgress value={uploadProgress * 100} size={5} color="cf.cntAccent" />
              ) : (
                <UserIcon />
              )
            }

            <input
              type="file"
              accept="image/*"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />

            <Text fontWeight="medium" lineHeight="short">
              {placeholder}
            </Text>

            <Spacer />

            <Button variant="outline" leftIcon={<UploadIcon />} onClick={handleUploadButtonClick}>Upload</Button>
          </HStack>
        ) : (
          <HStack spacing={3}>
            <Box>
              <AssetImage
                assetRef={field.value}
              />
            </Box>

            <Spacer />

            <input
              type="file"
              accept="image/*"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <Button variant="outline" leftIcon={<EditIcon />} onClick={handleUploadButtonClick}>Change</Button>

            <IconButton colorScheme="negative" icon={<TrashIcon />} onClick={handleRemoveButtonClick} aria-label="Remove" />
          </HStack>
        )}
      </PaddingBlock>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

AssetField.defaultProps = {
  placeholder: undefined,
  isRequired: false,
};

export default AssetField;
