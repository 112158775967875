import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { UserDoc } from './User';

export type MicrosoftCredentialsDoc = {
  email: string;
  userRef: DocumentReference<UserDoc>;
  credentials: {
    access_token: string;
    expires_in: number;
    ext_expires_in: number;
    id_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
  };
  expiresAt: Timestamp;
};

export const isMicrosoftCredentialsDoc = (
  doc?: DocumentData,
): doc is MicrosoftCredentialsDoc => true;

export const isMicrosoftCredentialsRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<MicrosoftCredentialsDoc> => ref.parent.id === 'microsoftCredentials';

export const isMicrosoftCredentialsSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
MicrosoftCredentialsDoc
> => isMicrosoftCredentialsRef(snap.ref);

export const getMicrosoftCredentialsCollectionRef = (firestore: Firestore): CollectionReference<MicrosoftCredentialsDoc> => collection(firestore, 'microsoftCredentials') as CollectionReference<MicrosoftCredentialsDoc>;
