import {
  Box,
  Container,
  Divider,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import { useStageRef } from '../../../components/StageRefProvider';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import { StageDoc } from '../../../types/Stage';
import Candidates, { CandidatesSuspenseFallback } from './Candidates';
import ContactInfo, { ContactInfoSuspenseFallback } from './ContactInfo';
import Experts, { ExpertsSuspenseFallback } from './Experts';
import Header, { HeaderSuspenseFallback } from './Header';
import Skills, { SkillsSuspenseFallback } from './Skills';

const StageDetailsScreenMain: React.FC = () => {
  const stageRef = useStageRef();

  const { data: stageSnap } = useFirestoreDoc<StageDoc>(stageRef);

  if (!stageSnap.exists()) {
    throw new SnapNotFoundError(stageSnap);
  }

  const stage = useMemo(() => stageSnap.data(), [stageSnap]);

  return (
    <VStack
      height="100%"
      spacing={0}
      alignItems="stretch"
      overflow="hidden"
    >
      <Header />

      <Divider />

      <Box overflow="auto" flexGrow={1} flexShrink={1}>
        <Container>
          <VStack spacing={8} py={3} alignItems="stretch">
            <ContactInfo />
            <Skills skillRefs={stage.skillRefs} />
            <Experts expertRefs={stage.expertRefs} />
            <Candidates />
          </VStack>
        </Container>
      </Box>
    </VStack>
  );
};

export const StageDetailsScreenSuspenseFallback: React.FC = () => (
  <VStack
    height="100%"
    spacing={0}
    alignItems="stretch"
    overflow="hidden"
  >
    <HeaderSuspenseFallback />

    <Divider />

    <Box overflow="auto" flexGrow={1} flexShrink={1}>
      <Container py={4}>
        <VStack spacing={8} alignItems="stretch">
          <ContactInfoSuspenseFallback />
          <SkillsSuspenseFallback />
          <ExpertsSuspenseFallback />
          <CandidatesSuspenseFallback />
        </VStack>
      </Container>
    </Box>
  </VStack>
);

const StageDetailsScreenCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const StageDetailsScreen: React.FC = () => (
  <Catch fallback={<StageDetailsScreenCatchFallback />}>
    <Suspense fallback={<StageDetailsScreenSuspenseFallback />}>
      <StageDetailsScreenMain />
    </Suspense>
  </Catch>
);

export default StageDetailsScreen;
