import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { OrganizationPlatformTier } from '../types/OrganizationCustomerProfile';

export type RequestData = {
  organizationId: string;
  tier: OrganizationPlatformTier;
};

export type ResponseData = { url: string };

const useStripeOrganizationSubscriptionChange = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(
    functions,
    'stripe-organization-subscription-change',
  ),
  [functions],
);

export default useStripeOrganizationSubscriptionChange;
