import {
  HStack,
  IconButton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { useCallback, useMemo, useState } from 'react';
import { useFunctions } from 'reactfire';
import useGoogleCalendarRemove from '../../functions/useGoogleCalendarRemove';
import GoogleIcon from '../../icons/GoogleIcon';
import Spinner from '../../icons/Spinner';
import TrashIcon from '../../icons/TrashIcon';
import { GoogleCredentialsDoc } from '../../types/GoogleCredentials';
import GoogleCalendars from './GoogleCalendars';

export interface Props {
  googleCredentialSnap: QueryDocumentSnapshot<GoogleCredentialsDoc>;
}

const GoogleCredentialsItem: React.FC<Props> = ({ googleCredentialSnap }) => {
  const functions = useFunctions();
  const userGoogleCalendarRemove = useGoogleCalendarRemove(functions);

  const [loading, setLoading] = useState(false);
  const handleRemoveClick = useCallback(async () => {
    setLoading(true);
    try {
      await userGoogleCalendarRemove({ credentialId: googleCredentialSnap.id });
    } catch (err) {
      /* do nothing */
    } finally {
      setLoading(false);
    }
  }, [userGoogleCalendarRemove, setLoading, googleCredentialSnap.id]);

  const googleCredential = useMemo(() => googleCredentialSnap.data(), [googleCredentialSnap]);

  return (
    <VStack alignItems="stretch" spacing={3} py={3}>
      <HStack spacing={2}>
        <GoogleIcon />

        <Text lineHeight="short" fontWeight="medium">
          {googleCredential.email}
        </Text>

        <Spacer />

        <IconButton
          icon={<TrashIcon />}
          colorScheme="negative"
          aria-label="Remove"
          spinner={<Spinner />}
          isLoading={loading}
          onClick={handleRemoveClick}
        />
      </HStack>

      <GoogleCalendars credentialsRef={googleCredentialSnap.ref} />
    </VStack>
  );
};

export default GoogleCredentialsItem;
