import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  collection,
} from 'firebase/firestore';
import { OrganizationDoc } from './Organization';

export enum WebhookEventType {
  SCORE_CREATED = 'score.created',
}

export type WebhookDoc = {
  url: string;
  secret: string;
  eventTypes: WebhookEventType[];
  isActive: boolean;
  organizationRef?: DocumentReference<OrganizationDoc>;
};

export const isWebhookDoc = (
  doc?: DocumentData,
): doc is WebhookDoc => true;

export const isWebhookRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<WebhookDoc> => ref.parent.id === 'webhooks';

export const isWebhookSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<WebhookDoc> => isWebhookRef(snap.ref);

export const getWebhooksCollectionRef = (firestore: Firestore): CollectionReference<WebhookDoc> => collection(firestore, 'webhooks') as CollectionReference<WebhookDoc>;
