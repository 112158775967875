import algolia, { SearchClient, SearchIndex } from 'algoliasearch';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFunctions, useUser } from 'reactfire';
import useAlgoliaGetPublicApiKey from '../functions/useAlgoliaGetPublicApiKey';

const PublicSearchClientContext = React.createContext<SearchClient | undefined>(undefined);

export const useSkillsIndex = (): SearchIndex | undefined => {
  const searchClient = useContext(PublicSearchClientContext);
  return useMemo(() => searchClient?.initIndex('skills'), [searchClient]);
};

export type AlgoliaPublicProviderProps = {
  appId: string;
};

export const AlgoliaPublicProvider: React.FC<PropsWithChildren<AlgoliaPublicProviderProps>> = (
  { children, appId },
) => {
  const [searchClient, setSearchClient] = useState<SearchClient | undefined>();

  const functions = useFunctions();
  const getPublicApiKey = useAlgoliaGetPublicApiKey(functions);
  const { data: user } = useUser();

  useEffect(() => {
    if (!user?.uid) {
      setSearchClient(undefined);

      return () => {};
    }

    let stopped = false;

    getPublicApiKey()
      .then(({ data: apiKey }) => {
        if (!stopped) {
          setSearchClient(algolia(appId, apiKey));
        }
      })
      .catch(() => {
        if (!stopped) {
          setSearchClient(undefined);
        }
      });

    return () => {
      stopped = true;
    };
  }, [appId, getPublicApiKey, user?.uid]);

  return (
    <PublicSearchClientContext.Provider value={searchClient}>
      {children}
    </PublicSearchClientContext.Provider>
  );
};
