import { Button, useToast } from '@chakra-ui/react';
import { limit, orderBy, query } from 'firebase/firestore';
import _ from 'lodash';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreCollection, useFunctions } from 'reactfire';
import Catch from '../../../components/Catch';
import { useExpertRef } from '../../../components/ExpertRefContext';
import useExpertSendInvitation from '../../../functions/useExpertSendInvitation';
import useCurrentTime from '../../../hooks/useCurrentTime';
import Spinner from '../../../icons/Spinner';
import { getExpertInvitationsCollectionRef } from '../../../types/ExpertInvitation';

const ResendInvitationMain: React.FC = () => {
  const expertRef = useExpertRef();
  const { data: invitationsSnap } = useFirestoreCollection(
    query(
      getExpertInvitationsCollectionRef(expertRef),
      orderBy('sentAt', 'desc'),
      limit(1),
    ),
  );

  const currentTime = useCurrentTime(1000 * 60);

  const canResend = useMemo<boolean>(() => {
    const lastInvitation = _.last(invitationsSnap.docs)?.data();

    if (!lastInvitation) {
      return true;
    }

    switch (lastInvitation.status) {
      case 'sent':
      case 'processed':
      case 'delivered':
      case 'open':
      case 'click': {
        return lastInvitation.sentAt.toMillis() < (currentTime.getTime() - (1000 * 60 * 60));
      }
      default: return true;
    }

    return false;
  }, [invitationsSnap, currentTime]);

  const functions = useFunctions();
  const sendInvitation = useExpertSendInvitation(functions);
  const toast = useToast();
  const [isSending, setSending] = useState(false);
  const handleClick = useCallback(async () => {
    try {
      setSending(true);
      await sendInvitation({ expertId: expertRef.id });
    } catch (err) {
      toast({
        status: 'error',
        title: 'Failed to send invitation',
        description: err instanceof Error ? err.message : undefined,
      });
    } finally {
      setSending(false);
    }
  }, [expertRef.id, sendInvitation, toast]);

  if (!canResend) {
    return null;
  }

  return (
    <Button
      width="100%"
      onClick={handleClick}
      isLoading={isSending}
      loadingText="Sending invitation..."
      spinner={<Spinner />}
    >
      Resend invitation
    </Button>
  );
};

export const ResendInvitationCatchFallback: React.FC = () => null;
export const ResendInvitationSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ResendInvitation: React.FC = () => (
  <Catch fallback={<ResendInvitationCatchFallback />}>
    <Suspense fallback={<ResendInvitationSuspenseFallback />}>
      <ResendInvitationMain />
    </Suspense>
  </Catch>
);

export default ResendInvitation;
