import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useFirestore } from 'reactfire';
import { getScoresCollectionRef } from '../../types/Score';
import ScoreScreen from './ScoreScreen';

const ScorePage: React.FC = () => {
  const { scoreId } = useParams<{
    scoreId: string;
  }>();

  const firestore = useFirestore();
  const scoreRef = useMemo(
    () => doc(getScoresCollectionRef(firestore), scoreId),
    [firestore, scoreId],
  );

  return (
    <ScoreScreen scoreRef={scoreRef} />
  );
};

export default ScorePage;
