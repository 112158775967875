import {
  Button,
  Container,
  Grid,
  GridItem,
  Heading,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentReference,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, { Suspense, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useFirestore } from 'reactfire';
import * as yup from 'yup';
import AssetField from '../../../components/AssetField';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import QuestionsField from '../../../components/QuestionsField';
import SkillsSelectorField from '../../../components/SkillsSelectorField';
import TextareaField from '../../../components/TextareaField';
import TextField from '../../../components/TextField';
import PlusIcon from '../../../icons/PlusIcon';
import Spinner from '../../../icons/Spinner';
import { AssetDoc } from '../../../types/Asset';
import { QuestionDoc } from '../../../types/Question';
import { SkillDoc, getSkillsCollectionRef } from '../../../types/Skill';
import { TagDoc } from '../../../types/Tag';

export interface FormFields {
  name: string;
  description: string;
  logoRef: DocumentReference<AssetDoc> | null;
  dependsOnRefs: DocumentReference<SkillDoc>[];
  questionRefs: DocumentReference<QuestionDoc>[];
  tagRefs: DocumentReference<TagDoc>[];
  synonyms: string[];
  speechContext: string[];
}

const schema = yup.object().shape({
  name: yup.string().label('Name').required(),
  description: yup.string().label('Description').required(),
  logoRef: yup.mixed().label('Company logo').required(),
});

const CreateSkillScreenMain: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const navigate = useNavigate();

  const firestore = useFirestore();

  const handleFormSubmit = useCallback(async ({
    name,
    description,
    logoRef,
    dependsOnRefs,
    questionRefs,
    tagRefs,
    synonyms,
    speechContext,
  }: FormFields) => {
    const skillRef = doc(getSkillsCollectionRef(firestore));

    if (!logoRef) {
      throw new Error();
    }

    await setDoc(skillRef, {
      name,
      description,
      logoRef,
      dependsOnRefs,
      questionRefs,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      revision: 1,
      tagRefs,
      duration: 0,
      organizationRef,
      synonyms,
      speechContext,
    });

    navigate(`../${skillRef.id}`);
  }, [firestore, navigate, organizationRef]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        name: '',
        description: '',
        logoRef: null,
        dependsOnRefs: [],
        questionRefs: [],
        tagRefs: [],
        synonyms: [],
        speechContext: [],
      }}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
      }) => (
        <form
          style={{ height: '100%' }}
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <Grid
            templateColumns="auto"
            templateRows="auto 1fr auto"
            height="100%"
          >
            <GridItem py={4} borderBottomWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <Heading>
                  New skill
                </Heading>
              </Container>
            </GridItem>

            <GridItem py={4} overflow="auto">
              <Container>
                <VStack alignItems="stretch" spacing={4}>
                  <TextField isRequired label="Name" name="name" />
                  <TextareaField isRequired label="Description" name="description" />
                  <AssetField label="Logo" name="logoRef" isRequired />
                  <QuestionsField name="questionRefs" label="Questions" isRequired />
                  <SkillsSelectorField name="dependsOnRefs" label="Depends on" isRequired />
                </VStack>
              </Container>
            </GridItem>

            <GridItem py={4} borderTopWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <Button
                  width="100%"
                  variant="solid"
                  type="submit"
                  leftIcon={<PlusIcon />}
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                  spinner={<Spinner />}
                  loadingText="Processing..."
                  className="cart-checkout"
                  data-intercom-target="OrganizationCreateSkillFormSubmit"
                >
                  Create skill
                </Button>
              </Container>
            </GridItem>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export const CreateSkillScreenCatchFallback: React.FC = () => null;
export const CreateSkillScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CreateSkillScreen: React.FC = () => (
  <Catch fallback={<CreateSkillScreenCatchFallback />}>
    <Suspense fallback={<CreateSkillScreenSuspenseFallback />}>
      <CreateSkillScreenMain />
    </Suspense>
  </Catch>
);

export default CreateSkillScreen;
