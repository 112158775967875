import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
} from 'firebase/firestore';
import { CandidateDoc } from './Candidate';
import { SendGridEventsParentDoc } from './SendGridEvent';

export type CandidateInvitationDoc = {
  accepted: boolean;
  email: string;
  firstName: string;
  lastName: string;
} & SendGridEventsParentDoc;

export const isCandidateInvitationDoc = (
  doc?: DocumentData,
): doc is CandidateInvitationDoc => true;

export const isCandidateInvitationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<CandidateInvitationDoc> => ref.parent.id === 'invitations';

export const isCandidateInvitationSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<CandidateInvitationDoc> => isCandidateInvitationRef(snap.ref);

export const getCandidateInvitationsCollectionRef = (candidateRef: DocumentReference<CandidateDoc>): CollectionReference<CandidateInvitationDoc> => collection(candidateRef, 'invitations') as CollectionReference<CandidateInvitationDoc>;
