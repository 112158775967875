import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { ReviewAspectType } from '../types/Review';

export type RequestData = {
  interviewId: string;
  value: number;
  aspect: ReviewAspectType;
};

export type ResponseData = void;

const useInterviewSetReview = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'interview-setreview'),
  [functions],
);

export default useInterviewSetReview;
