import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { AvatarDoc } from './Avatar';

export type UserDoc = {
  firstName: string;
  lastName: string;
  avatarRef?: DocumentReference<AvatarDoc>;
  createdAt: Timestamp;
};

export const isUserDoc = (
  doc?: DocumentData,
): doc is UserDoc => true;

export const isUserRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<UserDoc> => ref.parent.id === 'users';

export const isUserSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<UserDoc> => isUserRef(snap.ref);

export const getUsersCollectionRef = (firestore: Firestore): CollectionReference<UserDoc> => collection(firestore, 'users') as CollectionReference<UserDoc>;
