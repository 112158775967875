import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { CandidateDoc, isCandidateRef } from './Candidate';
import { SkillDoc } from './Skill';

export type CandidateSkillsStatusDoc = {
  requestedSkillRefs: DocumentReference<SkillDoc>[];
  pendingSkillRefs: DocumentReference<SkillDoc>[];
  finalizedSkillRefs: DocumentReference<SkillDoc>[];
};

export const isCandidateSkillsStatusDoc = (
  data?: DocumentData,
): data is CandidateSkillsStatusDoc => true;

export const isCandidateSkillsStatusRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<CandidateSkillsStatusDoc> => ref.id === 'skillsStatus' && ref.parent.id === 'private' && ref.parent.parent !== null && isCandidateRef(ref.parent.parent);

export const isCandidateSkillsStatusSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
CandidateSkillsStatusDoc
> => isCandidateSkillsStatusRef(snap.ref);

export const getCandidateSkillsStatusRef = (candidateRef: DocumentReference<CandidateDoc>): DocumentReference<CandidateSkillsStatusDoc> => doc(collection(candidateRef, 'private'), 'skillsStatus') as DocumentReference<CandidateSkillsStatusDoc>;
