import { SearchResponse } from '@algolia/client-search';
import {
  BoxProps,
  Fade,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference, doc } from 'firebase/firestore';
import { useField } from 'formik';
import _, { debounce } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestore, useRemoteConfigString } from 'reactfire';
import CrossInCircleIcon from '../../icons/CrossInCircleIcon';
import SearchIcon from '../../icons/SearchIcon';
import Spinner from '../../icons/Spinner';
import { AlgoliaExpertRecord } from '../../types/AlgoliaRecords';
import { ExpertDoc, getExpertsCollectionRef } from '../../types/Expert';
import { useExpertsIndex } from '../AlgoliaOrganizationProvider';
import BlockList from '../BlockList';
import BlockListItem from '../BlockListItem';
import { useOrganizationRef } from '../OrganizationRefContext';
import ExpertCheckbox from './ExpertCheckbox';

export type Props = BoxProps & {
  name: string;
  label: string;
  isRequired?: boolean;
};

const ExpertsSelectorField: React.FC<Props> = ({
  name,
  label,
  isRequired,
  ...boxProps
}) => {
  const organizationRef = useOrganizationRef();
  const expertsIndex = useExpertsIndex();

  const [field, meta, helpers] = useField<DocumentReference<ExpertDoc>[]>(name);

  const firestore = useFirestore();

  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');
  const throttled = useRef(debounce((newValue) => setQuery(newValue), 600));
  useEffect(() => {
    throttled.current(search);
  }, [search, throttled]);

  const handleSearchChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, [setSearch]);

  const [searchResponse, setSearchResponse] = useState<SearchResponse<AlgoliaExpertRecord>>();
  const [searching, setSearching] = useState(false);

  const { data: clarwisOrganizationId } = useRemoteConfigString('clarwis_organization_id');

  useEffect(
    () => {
      if (!expertsIndex) {
        return;
      }

      setSearching(true);
      expertsIndex.search<AlgoliaExpertRecord>(
        query,
        {
          facetFilters: [
            'status: ACTIVE',
            [
              `organization.id: ${clarwisOrganizationId}`,
              `organization.id: ${organizationRef.id}`,
            ],
            ...field.value.map((ref) => `objectID:-${ref.id}`),
          ],
          hitsPerPage: 5,
        },
      )
        .then((resp) => {
          setSearchResponse(resp);
          setSearching(false);
        });
    },
    [field.value, organizationRef.id, query, expertsIndex, clarwisOrganizationId],
  );

  const expertRefs = useMemo<DocumentReference<ExpertDoc>[]>(
    () => (searchResponse?.hits || [])
      .map(({ objectID }) => doc(getExpertsCollectionRef(firestore), objectID)),
    [firestore, searchResponse?.hits],
  );

  const handleExpertCheck = useCallback((
    expertRef: DocumentReference<ExpertDoc>,
    checked: boolean,
  ) => {
    if (checked) {
      helpers.setValue([
        ...field.value,
        expertRef,
      ]);
    } else {
      helpers.setValue(_.filter(
        field.value,
        (oldExpertRef: DocumentReference<ExpertDoc>) => oldExpertRef.path !== expertRef.path,
      ));
    }
  }, [helpers, field.value]);

  const isChecked = (
    expertRef: DocumentReference<ExpertDoc>,
  ): boolean => _.some(
    field.value,
    (
      selectedExpert: DocumentReference<ExpertDoc>,
    ) => selectedExpert.path === expertRef.path,
  );

  const { t } = useTranslation();

  return (
    <FormControl
      isInvalid={!!meta.error}
      isRequired={isRequired}
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <FormLabel flexGrow={1}>
        {label}
      </FormLabel>

      <VStack spacing={4} alignItems="stretch">
        <BlockList variant="outline">
          {field.value.map((expertRef) => (
            <ExpertCheckbox
              key={`${expertRef.id}-selected`}
              expertRef={expertRef}
              onChange={(e) => handleExpertCheck(expertRef, e.target.checked)}
              isChecked={isChecked(expertRef)}
            />
          ))}

          <BlockListItem>
            <VStack alignItems="stretch" spacing={1}>
              <InputGroup size="sm">
                <InputLeftElement pointerEvents="none" color="cf.cntSecondary">
                  <SearchIcon />
                </InputLeftElement>

                <Input
                  placeholder="Look for specific expert"
                  onChange={handleSearchChange}
                  value={search}
                  variant="flushed"
                  data-intercom-target="ExpertsSelectorFieldSearch"
                />

                {searching ? (
                  <InputRightElement color="cf.cntSecondary">
                    <Spinner />
                  </InputRightElement>
                ) : (
                  <InputRightElement color="cf.cntSecondary">
                    <IconButton
                      isDisabled={!query.length}
                      size="sm"
                      icon={<CrossInCircleIcon />}
                      aria-label="Clear"
                      variant="ghost"
                      onClick={() => setSearch('')}
                    />
                  </InputRightElement>
                )}
              </InputGroup>

              <Fade in={!searching}>
                <Text color="cf.cntSecondary" fontSize="sm" lineHeight="shorter" pt="3px" pb="1px" userSelect="none">
                  {t('expertsSelectorField.search.nbExpertsFound', { count: searchResponse?.nbHits || 0 })}
                </Text>
              </Fade>
            </VStack>
          </BlockListItem>

          {expertRefs.map((expertRef) => (
            <ExpertCheckbox
              key={expertRef.id}
              expertRef={expertRef}
              onChange={(e) => handleExpertCheck(expertRef, e.target.checked)}
              isChecked={isChecked(expertRef)}
            />
          ))}
        </BlockList>
      </VStack>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

ExpertsSelectorField.defaultProps = {
  isRequired: undefined,
};

export default ExpertsSelectorField;
