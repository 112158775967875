import {
  Button,
  HStack,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import * as yup from 'yup';
import ConnectedCalendars from '../../components/ConnectedCalendars';
import ScheduleField from '../../components/ScheduleField';
import Spinner from '../../icons/Spinner';
import { UserScheduleDoc, timeSlots } from '../../types/UserSchedule';

export type Props = {
  initialValues: FormFields;
  onSubmit: (values: FormFields) => Promise<unknown>;
  onPrevious: () => unknown;
};

export interface FormFields {
  schedule: UserScheduleDoc;
}

const weekDaySchema = yup.array(yup.string().oneOf([...timeSlots])).required();

const schema = yup.object().shape({
  schedule: yup
    .object()
    .shape({
      su: weekDaySchema,
      mo: weekDaySchema,
      tu: weekDaySchema,
      we: weekDaySchema,
      th: weekDaySchema,
      fr: weekDaySchema,
      sa: weekDaySchema,
    })
    .required()
    .test(
      'minAvailability',
      'You have to be available at least 10 hours a week',
      (value: UserScheduleDoc) => _.flatten(_.values(value)).length > 40,
    ),
});

const AvailabilityForm: React.FC<Props> = ({ initialValues, onSubmit, onPrevious }) => {
  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <VStack spacing={8} alignItems="stretch" textAlign="left">
            <ConnectedCalendars />

            <ScheduleField name="schedule" />

            <HStack justifyContent="right">
              <Button
                variant="outline"
                onClick={onPrevious}
              >
                Previous
              </Button>

              <Spacer />

              <Button
                type="submit"
                isLoading={isSubmitting}
                spinner={<Spinner />}
                loadingText="Saving..."
              >
                Next
              </Button>
            </HStack>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

export default AvailabilityForm;
