import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { CandidateDoc } from './Candidate';
import { ExpertDoc } from './Expert';
import { InterviewDoc } from './Interview';
import { UserDoc } from './User';

export enum NotificationType {
  CANDIDATE_ACTIVATED_CANDIDATE_ASSIGNEE = 'CANDIDATE_ACTIVATED_CANDIDATE_ASSIGNEE',
  CANDIDATE_ARCHIVED_CANDIDATE_ASSIGNEE = 'CANDIDATE_ARCHIVED_CANDIDATE_ASSIGNEE',
  CANDIDATE_CREATED_CANDIDATE_ASSIGNEE = 'CANDIDATE_CREATED_CANDIDATE_ASSIGNEE',
  CANDIDATE_FINALIZED_CANDIDATE_ASSIGNEE = 'CANDIDATE_FINALIZED_CANDIDATE_ASSIGNEE',
  CANDIDATE_PAUSED_CANDIDATE_ASSIGNEE = 'CANDIDATE_PAUSED_CANDIDATE_ASSIGNEE',
  EXPERT_ACTIVATED_EXPERT_ASSIGNEE = 'EXPERT_ACTIVATED_EXPERT_ASSIGNEE',
  EXPERT_ARCHIVED_EXPERT_ASSIGNEE = 'EXPERT_ARCHIVED_EXPERT_ASSIGNEE',
  EXPERT_CREATED_EXPERT_ASSIGNEE = 'EXPERT_CREATED_EXPERT_ASSIGNEE',
  EXPERT_PAUSED_EXPERT_ASSIGNEE = 'EXPERT_PAUSED_EXPERT_ASSIGNEE',
  INTERVIEW_CANCELED_CANDIDATE_ASSIGNEE = 'INTERVIEW_CANCELED_CANDIDATE_ASSIGNEE',
  INTERVIEW_CANCELED_EXPERT_ASSIGNEE = 'INTERVIEW_CANCELED_EXPERT_ASSIGNEE',
  INTERVIEW_CANCELED_INTERVIEWEE = 'INTERVIEW_CANCELED_INTERVIEWEE',
  INTERVIEW_CANCELED_INTERVIEWER = 'INTERVIEW_CANCELED_INTERVIEWER',
  INTERVIEW_CREATED_CANDIDATE_ASSIGNEE = 'INTERVIEW_CREATED_CANDIDATE_ASSIGNEE',
  INTERVIEW_CREATED_EXPERT_ASSIGNEE = 'INTERVIEW_CREATED_EXPERT_ASSIGNEE',
  INTERVIEW_CREATED_INTERVIEWEE = 'INTERVIEW_CREATED_INTERVIEWEE',
  INTERVIEW_CREATED_INTERVIEWER = 'INTERVIEW_CREATED_INTERVIEWER',
  INTERVIEW_ENDED_CANDIDATE_ASSIGNEE = 'INTERVIEW_ENDED_CANDIDATE_ASSIGNEE',
  INTERVIEW_ENDED_EXPERT_ASSIGNEE = 'INTERVIEW_ENDED_EXPERT_ASSIGNEE',
  INTERVIEW_ENDED_INTERVIEWEE = 'INTERVIEW_ENDED_INTERVIEWEE',
  INTERVIEW_ENDED_INTERVIEWER = 'INTERVIEW_ENDED_INTERVIEWER',
  INTERVIEW_STARTED_CANDIDATE_ASSIGNEE = 'INTERVIEW_STARTED_CANDIDATE_ASSIGNEE',
  INTERVIEW_STARTED_EXPERT_ASSIGNEE = 'INTERVIEW_STARTED_EXPERT_ASSIGNEE',
  INTERVIEW_STARTED_INTERVIEWEE = 'INTERVIEW_STARTED_INTERVIEWEE',
  INTERVIEW_STARTED_INTERVIEWER = 'INTERVIEW_STARTED_INTERVIEWER',
}

export enum NotificationStatus {
  CREATED = 'CREATED',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
}

export type BaseNotification = {
  userRef: DocumentReference<UserDoc>;
  status: NotificationStatus;
  type: NotificationType;
  createdAt: Timestamp;
  acknowledgedAt?: Timestamp;
};

export type ExpertStatusNotification = BaseNotification & {
  type:
  | NotificationType.EXPERT_ACTIVATED_EXPERT_ASSIGNEE
  | NotificationType.EXPERT_ARCHIVED_EXPERT_ASSIGNEE
  | NotificationType.EXPERT_CREATED_EXPERT_ASSIGNEE
  | NotificationType.EXPERT_PAUSED_EXPERT_ASSIGNEE;
  expertRef: DocumentReference<ExpertDoc>;
  expert: ExpertDoc;
};

export type CandidateStatusNotification = BaseNotification & {
  type:
  | NotificationType.CANDIDATE_ACTIVATED_CANDIDATE_ASSIGNEE
  | NotificationType.CANDIDATE_ARCHIVED_CANDIDATE_ASSIGNEE
  | NotificationType.CANDIDATE_CREATED_CANDIDATE_ASSIGNEE
  | NotificationType.CANDIDATE_FINALIZED_CANDIDATE_ASSIGNEE
  | NotificationType.CANDIDATE_PAUSED_CANDIDATE_ASSIGNEE;
  candidateRef: DocumentReference<CandidateDoc>;
  candidate: CandidateDoc;
};

export type InterviewStatusNotification = BaseNotification & {
  type:
  | NotificationType.INTERVIEW_CREATED_INTERVIEWEE
  | NotificationType.INTERVIEW_STARTED_INTERVIEWEE
  | NotificationType.INTERVIEW_ENDED_INTERVIEWEE
  | NotificationType.INTERVIEW_CANCELED_INTERVIEWEE
  | NotificationType.INTERVIEW_CREATED_INTERVIEWER
  | NotificationType.INTERVIEW_STARTED_INTERVIEWER
  | NotificationType.INTERVIEW_ENDED_INTERVIEWER
  | NotificationType.INTERVIEW_CANCELED_INTERVIEWER
  | NotificationType.INTERVIEW_CREATED_CANDIDATE_ASSIGNEE
  | NotificationType.INTERVIEW_STARTED_CANDIDATE_ASSIGNEE
  | NotificationType.INTERVIEW_ENDED_CANDIDATE_ASSIGNEE
  | NotificationType.INTERVIEW_CANCELED_CANDIDATE_ASSIGNEE
  | NotificationType.INTERVIEW_CREATED_EXPERT_ASSIGNEE
  | NotificationType.INTERVIEW_STARTED_EXPERT_ASSIGNEE
  | NotificationType.INTERVIEW_ENDED_EXPERT_ASSIGNEE
  | NotificationType.INTERVIEW_CANCELED_EXPERT_ASSIGNEE;
  interviewRef: DocumentReference<InterviewDoc>;
  interview: InterviewDoc;
};

export type NotificationDoc =
  | CandidateStatusNotification
  | ExpertStatusNotification
  | InterviewStatusNotification;

export const isNotificationDoc = (
  doc?: DocumentData,
): doc is NotificationDoc => true;

export const isNotificationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<NotificationDoc> => ref.parent.id === 'notifications';

function isNotificationSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
NotificationDoc
>;
function isNotificationSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
NotificationDoc
>;
function isNotificationSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
NotificationDoc
> | QueryDocumentSnapshot<
NotificationDoc
> {
  return isNotificationRef(snap.ref);
}

export { isNotificationSnap };

export const getNotificationsCollectionRef = (firestore: Firestore): CollectionReference<NotificationDoc> => collection(firestore, 'notifications') as CollectionReference<NotificationDoc>;
