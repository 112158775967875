import { doc } from 'firebase/firestore';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useFirestore } from 'reactfire';
import InterviewRefProvider from '../../components/InterviewRefContext';
import { getInterviewsCollectionRef } from '../../types/Interview';
import PlaybackScreen from './PlaybackScreen';

const PlaybackPage: React.FC = () => {
  const { update } = useIntercom();
  useEffect(() => {
    update({
      hideDefaultLauncher: true,
    });

    return () => {
      update({
        hideDefaultLauncher: false,
      });
    };
  }, [update]);

  const { interviewId } = useParams<{ interviewId: string; }>();

  const firestore = useFirestore();
  const interviewRef = useMemo(
    () => doc(getInterviewsCollectionRef(firestore), interviewId),
    [firestore, interviewId],
  );

  return (
    <InterviewRefProvider interviewRef={interviewRef}>
      <PlaybackScreen />
    </InterviewRefProvider>
  );
};

export default PlaybackPage;
