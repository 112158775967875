import {
  Center,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import {
  doc,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import useIntervieweeParticipant from '../hooks/useIntervieweeParticipant';
import useInterviewerParticipant from '../hooks/useInterviewerParticipant';
import useInterviewRole, { InterviewRole } from '../hooks/useInterviewRole';
import useParticipants from '../hooks/useParticipants';
import Spinner from '../icons/Spinner';
import { InterviewStatus } from '../types/Interview';
import {
  InterviewScheduleItemStatus,
  getInterviewScheduleItemsCollectionRef,
} from '../types/InterviewScheduleItem';
import {
  InterviewTabStateTab,
  getInterviewTabStatesCollectionRef,
} from '../types/InterviewTabState';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import CallCodeEditor from './CallCodeEditor';
import CallCurrentQuestion from './CallCurrentQuestion';
import CallWhiteboard from './CallWhiteboard';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';
import { useRoom } from './RoomProvider';

const CallContentMain: React.FC = () => {
  const interviewRef = useInterviewRef();

  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const handleTabChange = useCallback(
    async (tabIndex: number) => {
      const tabs = [
        InterviewTabStateTab.QUESTION,
        InterviewTabStateTab.CODE_EDITOR,
        InterviewTabStateTab.WHITEBOARD,
      ];

      await setDoc(
        doc(getInterviewTabStatesCollectionRef(interviewRef)),
        {
          tab: tabs[tabIndex],
          timestamp: serverTimestamp(),
        },
      );
    },
    [interviewRef],
  );

  const { data: interviewTabStatesSnap } = useFirestoreCollection(
    query(
      getInterviewTabStatesCollectionRef(interviewRef),
      orderBy('timestamp', 'desc'),
      limit(1),
    ),
  );

  const currentTabIndex = useMemo<number>(
    () => {
      if (!interviewTabStatesSnap.docs.length) {
        return 0;
      }

      const interviewTabState = interviewTabStatesSnap.docs[0].data();

      switch (interviewTabState.tab) {
        case InterviewTabStateTab.QUESTION: return 0;
        case InterviewTabStateTab.CODE_EDITOR: return 1;
        case InterviewTabStateTab.WHITEBOARD: return 2;
        default: return 0;
      }
    },
    [interviewTabStatesSnap.docs],
  );

  const { room } = useRoom();

  const participants = useParticipants(room);

  const intervieweeParticipant = useIntervieweeParticipant(participants);
  const interviewerParticipant = useInterviewerParticipant(participants);

  const role = useInterviewRole();

  const { data: scheduleItemsSnap } = useFirestoreCollection(
    query(
      getInterviewScheduleItemsCollectionRef(interviewRef),
      orderBy('startsAt', 'desc'),
    ),
  );

  const allEnded = useMemo(
    () => _.every(
      scheduleItemsSnap.docs,
      (scheduleItemSnap) => scheduleItemSnap.data().status === InterviewScheduleItemStatus.ENDED,
    ),
    [scheduleItemsSnap.docs],
  );

  const allCreated = useMemo(
    () => _.every(
      scheduleItemsSnap.docs,
      (scheduleItemSnap) => scheduleItemSnap.data().status === InterviewScheduleItemStatus.CREATED,
    ),
    [scheduleItemsSnap.docs],
  );

  const currentScheduleItemIndex = useMemo(
    () => _.findIndex(
      scheduleItemsSnap.docs,
      (scheduleItemSnap) => {
        const scheduleItem = scheduleItemSnap.data();
        return !!scheduleItem.startedAt && !scheduleItem.endedAt;
      },
    ),
    [scheduleItemsSnap.docs],
  );

  const currentScheduleItemSnap = useMemo(
    () => (currentScheduleItemIndex < 0 ? null : scheduleItemsSnap.docs[currentScheduleItemIndex]),
    [currentScheduleItemIndex, scheduleItemsSnap.docs],
  );

  if (interview.status === InterviewStatus.CREATED) {
    if (role === InterviewRole.INTERVIEWEE && !interviewerParticipant) {
      return (
        <Center h="100%">
          <Heading>
            Waiting for the interviewer to join...
          </Heading>
        </Center>
      );
    }

    if (role === InterviewRole.INTERVIEWER && !intervieweeParticipant) {
      return (
        <Center h="100%">
          <Heading>
            Waiting for the interviewee to join...
          </Heading>
        </Center>
      );
    }

    if (role === InterviewRole.VISITOR && (!intervieweeParticipant || !interviewerParticipant)) {
      return (
        <Center h="100%">
          <Heading>
            Waiting for all participants to join...
          </Heading>
        </Center>
      );
    }

    return (
      <Center h="100%">
        <Heading>
          Welcome!
        </Heading>
      </Center>
    );
  }

  if (interview.status === InterviewStatus.STARTED) {
    if (!currentScheduleItemSnap) {
      if (allCreated) {
        return (
          <Center h="100%">
            <Heading>
              Briefing
            </Heading>
          </Center>
        );
      }

      if (allEnded) {
        return (
          <Center h="100%">
            <Heading>
              Wrapping up
            </Heading>
          </Center>
        );
      }

      return (
        <Center h="100%">
          <Spinner />
        </Center>
      );
    }

    return (
      <Tabs
        isLazy
        display="flex"
        flexDir="column"
        h="100%"
        onChange={handleTabChange}
        index={currentTabIndex}
      >
        <TabList>
          <Tab>
            Question
          </Tab>

          <Tab>
            Code Editor
          </Tab>

          <Tab>
            Whiteboard
          </Tab>
        </TabList>

        <TabPanels flexGrow={1} mt={3}>
          <TabPanel h="100%" p={0}>
            {currentScheduleItemSnap ? (
              <CallCurrentQuestion
                key={currentScheduleItemSnap.id}
                scheduleItemSnap={currentScheduleItemSnap}
                currentQuestion={currentScheduleItemIndex}
              />
            ) : null}
          </TabPanel>

          <TabPanel h="100%" p={0}>
            {currentScheduleItemSnap ? (
              <CallCodeEditor
                key={currentScheduleItemSnap.id}
                scheduleItemSnap={currentScheduleItemSnap}
              />
            ) : null}
          </TabPanel>

          <TabPanel h="100%" p={0}>
            <CallWhiteboard />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }

  return (
    <Center h="100%">
      <Heading>
        Bye!
      </Heading>
    </Center>
  );
};

const CallContentCatchFallback: React.FC = () => null;
const CallContentSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallContent: React.FC = () => (
  <Catch fallback={<CallContentCatchFallback />}>
    <Suspense fallback={<CallContentSuspenseFallback />}>
      <CallContentMain />
    </Suspense>
  </Catch>
);

export default CallContent;
