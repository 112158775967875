import {
  HStack,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { useCallback, useMemo } from 'react';
import { useFunctions } from 'reactfire';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import StorageAvatar from '../../components/StorageAvatar';
import useOrganizationWithdrawMemberInvitation from '../../functions/useOrganizationWithdrawMemberInvitation';
import TrashIcon from '../../icons/TrashIcon';
import { OrganizationMemberInvitationDoc } from '../../types/OrganizationMemberInvitation';

export type Props = {
  invitationSnap: QueryDocumentSnapshot<OrganizationMemberInvitationDoc>;
};

const InvitationItem: React.FC<Props> = ({ invitationSnap }) => {
  const organizationRef = useOrganizationRef();
  const invitation = useMemo(() => invitationSnap.data(), [invitationSnap]);

  const functions = useFunctions();
  const withdrawMemberInvitation = useOrganizationWithdrawMemberInvitation(functions);
  const toast = useToast();
  const handleRemoveMemberInvitationClick = useCallback(
    async () => {
      try {
        await withdrawMemberInvitation({
          organizationId: organizationRef.id,
          memberInvitationId: invitationSnap.id,
        });
      } catch (err) {
        if (err instanceof Error) {
          toast({
            title: 'Failed to withdraw invitation',
            status: 'error',
            description: err.message,
          });
        }
      }
    },
    [invitationSnap.id, organizationRef.id, toast, withdrawMemberInvitation],
  );

  return (
    <HStack>
      <StorageAvatar size="xs" />
      <Text variant="labelMedium" flexGrow={1}>
        {invitation.firstName}
        {' '}
        {invitation.lastName}
        {' '}
        <Text as="span" color="cf.cntSecondary" fontStyle="italic">
          (
          {invitation.status}
          )
        </Text>
      </Text>

      <IconButton
        aria-label="Remove"
        colorScheme="negative"
        icon={<TrashIcon />}
        onClick={handleRemoveMemberInvitationClick}
      />
    </HStack>
  );
};

export default InvitationItem;
