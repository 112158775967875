import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { AvatarDoc } from './Avatar';
import { UserDoc } from './User';

export type OrganizationDoc = {
  name: string;
  email: string;
  logoRef?: DocumentReference<AvatarDoc>;
  createdAt: Timestamp;
  owners: DocumentReference<UserDoc>[];
};

export const isOrganizationDoc = (
  doc: DocumentData,
): doc is OrganizationDoc => true;

export const isOrganizationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<OrganizationDoc> => ref.parent.id === 'organizations';

export const isOrganizationSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<OrganizationDoc> => isOrganizationRef(snap.ref);

export const getOrganizationsCollectionRef = (firestore: Firestore): CollectionReference<OrganizationDoc> => collection(firestore, 'organizations') as CollectionReference<OrganizationDoc>;
