import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
} from 'firebase/firestore';
import { CandidateDoc } from './Candidate';
import { InterviewDoc } from './Interview';
import { OrganizationBalanceDoc, isOrganizationBalanceRef } from './OrganizationBalance';

export enum OrganizationBalanceTransactionType {
  INTERVIEW_CANCELED = 'INTERVIEW_CANCELED',
  INTERVIEW_CREATED = 'INTERVIEW_CREATED',
  REFUND = 'REFUND',
  STRIPE_INVOICE = 'STRIPE_INVOICE',
  TRIAL_CREDIT = 'TRIAL_CREDIT',
}

export type OrganizationBalanceTransactionDoc = {
  type: OrganizationBalanceTransactionType.TRIAL_CREDIT;
  tierCQuantity: number;
  timestamp: Timestamp;
} | {
  type: OrganizationBalanceTransactionType.STRIPE_INVOICE;
  invoiceId: string;
  tierCQuantity: number;
  timestamp: Timestamp;
} | {
  type: OrganizationBalanceTransactionType.INTERVIEW_CREATED;
  interviewRef: DocumentReference<InterviewDoc>;
  candidateRef: DocumentReference<CandidateDoc>;
  tierCQuantity: number;
  timestamp: Timestamp;
} | {
  type: OrganizationBalanceTransactionType.INTERVIEW_CANCELED
  | OrganizationBalanceTransactionType.REFUND;
  interviewRef: DocumentReference<InterviewDoc>;
  candidateRef: DocumentReference<CandidateDoc>;
  tierCQuantity: number;
  timestamp: Timestamp;
};

export const isOrganizationBalanceTransactionDoc = (
  doc?: DocumentData,
): doc is OrganizationBalanceTransactionDoc => true;

export const isOrganizationBalanceTransactionRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<OrganizationBalanceTransactionDoc> => ref.parent.id === 'transactions' && ref.parent.parent !== null && isOrganizationBalanceRef(ref.parent.parent);

export const isOrganizationBalanceTransactionSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
OrganizationBalanceTransactionDoc
> => isOrganizationBalanceTransactionRef(snap.ref);

export const getOrganizationBalanceTransactionsCollectionRef = (organizationBalanceRef: DocumentReference<OrganizationBalanceDoc>): CollectionReference<OrganizationBalanceTransactionDoc> => collection(organizationBalanceRef, 'transactions') as CollectionReference<OrganizationBalanceTransactionDoc>;
