import { Functions, HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

export type RequestData = {
  credentialId: string;
};

export type ResponseData = void;

const useGoogleCalendarRemove = (
  functions: Functions,
): HttpsCallable<RequestData, ResponseData> => useMemo(
  () => httpsCallable<RequestData, ResponseData>(functions, 'google-calendar-remove'),
  [functions],
);

export default useGoogleCalendarRemove;
