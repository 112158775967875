import { Button, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import * as yup from 'yup';
import TextField from '../../components/TextField';
import useAuthSendSignInLinkToEmail from '../../functions/useAuthSendSignInLinkToEmail';
import Spinner from '../../icons/Spinner';

export interface Props {
  onEmailSent: () => unknown;
}

export interface FormFields {
  email: string;
}

const authSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const EmailForm: React.FC<Props> = ({ onEmailSent }: Props) => {
  const functions = useFunctions();
  const { search } = useLocation();

  const url = useMemo(() => {
    const res = new URL(`${window.location.origin}/login`);

    const query = new URLSearchParams(search);
    const next = query.get('next');
    if (next) {
      res.searchParams.set('next', next);
    }

    return res.toString();
  }, [search]);

  const sendSignInLinkToEmail = useAuthSendSignInLinkToEmail(functions);

  const handleEmailFormSubmit = useCallback(async ({ email }: FormFields) => {
    await sendSignInLinkToEmail({
      email,
      url,
    });
    window.localStorage.setItem('emailForSignIn', email);
    onEmailSent();
  }, [sendSignInLinkToEmail, onEmailSent, url]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        email: '',
      }}
      onSubmit={handleEmailFormSubmit}
      validationSchema={authSchema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <VStack alignItems="stretch" spacing={5}>
            <TextField
              name="email"
              type="email"
              label="Email"
              placeholder="Enter your email address"
            />

            <Button
              width="100%"
              type="submit"
              isLoading={isSubmitting}
              spinner={<Spinner />}
              loadingText="Sending a magic link..."
            >
              Get Magic link
            </Button>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

export default EmailForm;
