import { SkeletonCircle } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import Spinner from '../icons/Spinner';
import { CandidateDoc } from '../types/Candidate';
import Catch from './Catch';
import { StorageAvatarStatic } from './StorageAvatar';
import UserAvatar, { ProfileAvatarProps } from './UserAvatar';

export type Props = Omit<ProfileAvatarProps, 'userRef'> & {
  candidateSnap: QueryDocumentSnapshot<CandidateDoc>;
};

const CandidateAvatarMain: React.FC<Props> = ({
  candidateSnap,
  ...profileAvatarProps
}: Props) => {
  const candidate = useMemo(() => candidateSnap.data(), [candidateSnap]);

  if (!candidate.userRef) {
    return (
      <StorageAvatarStatic
        icon={<Spinner />}
        color="cf.status.empty"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...profileAvatarProps}
      />
    );
  }

  return (
    <UserAvatar
      userRef={candidate.userRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...profileAvatarProps}
    />
  );
};

export const CandidateAvatarSuspenseFallback: React.FC = () => (
  <SkeletonCircle boxSize={11} />
);

export const CandidateAvatarCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidateAvatar: React.FC<Props> = (props) => (
  <Catch fallback={<CandidateAvatarCatchFallback />}>
    <Suspense fallback={<CandidateAvatarSuspenseFallback />}>
      <CandidateAvatarMain {...props} />
    </Suspense>
  </Catch>
);

export default CandidateAvatar;
