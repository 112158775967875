import { Button, HStack, VStack } from '@chakra-ui/react';
import { DocumentReference, GeoPoint } from 'firebase/firestore';
import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useFunctions, useUser } from 'reactfire';
import * as yup from 'yup';
import AvatarField from '../../components/AvatarField';
import CheckboxField from '../../components/CheckboxField';
import CityField from '../../components/CityField';
import PhoneNumberField from '../../components/PhoneNumberField';
import TextField from '../../components/TextField';
import useTwilioLookup from '../../functions/useTwilioLookup';
import Spinner from '../../icons/Spinner';
import AuthError from '../../types/AuthError';
import { AvatarDoc } from '../../types/Avatar';

export type Props = {
  initialValues: FormFields;
  onSubmit: (values: FormFields) => Promise<unknown>;
};

export type FormFields = {
  firstName: string;
  lastName: string;
  avatarRef: DocumentReference<AvatarDoc> | null;
  location: GeoPoint | null;
  phoneNumber: string;
  concent: boolean;
};

const ProfileForm: React.FC<Props> = ({ onSubmit, initialValues }) => {
  const { data: user } = useUser();

  if (!user) {
    throw new AuthError();
  }

  const [validateAll, setValidateAll] = useState(false);

  const functions = useFunctions();
  const lookup = useTwilioLookup(functions);

  const schema = useMemo(() => yup.object().shape({
    firstName: yup.string().label('First name').required(),
    lastName: yup.string().label('Last name').required(),
    avatarRef: yup.mixed().label('Photo').required(),
    location: yup.mixed().label('City').nullable().test(
      'required',
      'City is a required field',
      (v: GeoPoint | null, ...rest) => v !== null,
    ),
    phoneNumber: yup.string().test(
      'validPhoneNumber',
      'Should be valid phone number',
      async (phoneNumber?: string) => {
        if (!phoneNumber) {
          return false;
        }

        try {
          await lookup({ phoneNumber });
          return true;
        } catch (err) {
          return false;
        }
      },
    ),
    concent: yup.bool().label('Concent').test(
      'required',
      'You should accept terms of service and privacy policy',
      (v) => v === true,
    ),
  }), [lookup]);

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <VStack spacing={8} alignItems="stretch" textAlign="left">
            <AvatarField name="avatarRef" label="Your photo" />

            <HStack spacing={4} alignItems="start">
              <TextField name="firstName" label="First name" placeholder="John" />
              <TextField name="lastName" label="Last name" placeholder="Doe" />
            </HStack>

            <CityField />

            <PhoneNumberField label="Phone number" name="phoneNumber" />

            <CheckboxField
              label={(
                <>
                  I accept
                  {' '}
                  <Button variant="link" as="a" href="https://clarwis.com/terms" target="_blank">
                    terms of use
                  </Button>
                  {' '}
                  and
                  {' '}
                  <Button variant="link" as="a" href="https://clarwis.com/privacy" target="_blank">
                    privacy policy
                  </Button>
                  {' '}
                  of Clarwis
                </>
                )}
              name="concent"
            />

            <HStack justifyContent="right">
              <Button
                type="submit"
                isLoading={isSubmitting}
                spinner={<Spinner />}
                loadingText="Saving..."
              >
                Next
              </Button>
            </HStack>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

export default ProfileForm;
