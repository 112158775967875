import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import InterviewRefProvider from '../../components/InterviewRefContext';
import { getInterviewsCollectionRef } from '../../types/Interview';
import InterviewDetailsScreen from './InterviewDetailsScreen';

export type Params = {
  interviewId: string;
};

const UserInterviewDetailsPage: React.FC = () => {
  const { interviewId } = useParams<Params>();
  const firestore = useFirestore();
  const interviewRef = useMemo(
    () => doc(getInterviewsCollectionRef(firestore), interviewId),
    [firestore, interviewId],
  );

  return (
    <InterviewRefProvider interviewRef={interviewRef}>
      <InterviewDetailsScreen key={interviewRef.id} />
    </InterviewRefProvider>
  );
};

export default UserInterviewDetailsPage;
